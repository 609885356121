// AirAlert Types
export const SET_AIR_ALERTS: string = 'SET_AIR_ALERTS';

// Alert Types
export const ALERTS_ON: string = 'ALERTS_ON';
export const ALERTS_OFF: string = 'ALERTS_OFF';

// Auth Types
export const AUTH_ERROR: string = 'AUTH_ERROR';
export const LOGIN_FAIL: string = 'LOGIN_FAIL';
export const LOGIN_SUCCESS: string = 'LOGIN_SUCCESS';
export const LOGOUT: string = 'LOGOUT';
export const SET_AVERAGING_PERIODS: string = 'SET_AVERAGING_PERIODS';
export const SET_DATA_CONFIG: string = 'SET_DATA_CONFIG';
export const SET_DISPLAY_CONFIG: string = 'SET_DISPLAY_CONFIG';
export const SET_FEEDBACK: string = 'SET_FEEDBACK';
export const SET_FEEDBACK_IDX: string = 'SET_FEEDBACK_IDX';
export const SET_FEEDBACK_OFF: string = 'SET_FEEDBACK_OFF';
export const SET_FEEDBACK_ON: string = 'SET_FEEDBACK_ON';
export const SET_OVERLAYS: string = 'SET_OVERLAYS';
export const SET_PUBLIC_ADVICE: string = 'SET_PUBLIC_ADVICE';
export const SET_TIME_PERIODS: string = 'SET_TIME_PERIODS';
export const SET_USER_CONFIG: string = 'SET_USER_CONFIG';
export const SET_VERSIONS: string = 'SET_VERSIONS';
export const USERINFO_LOADED: string = 'USERINFO_LOADED';
export const USER_LOADED: string = 'USER_LOADED';
export const SET_MASTER_AUTH_BEARER_TOKEN: string = 'SET_MASTER_AUTH_BEARER_TOKEN';

// MappAir Types
export const CHANGE_OVERLAY_LAYER: string = 'CHANGE_OVERLAY_LAYER';
export const CHANGE_OVERLAY_OPACITY: string = 'CHANGE_OVERLAY_OPACITY';
export const GET_STYLE_GROUPS: string = 'GET_STYLE_GROUPS';
export const MAPS_LOCATION_LOADED: string = 'MAPS_LOCATION_LOADED';
export const SET_ACTIVE_THRESHOLD: string = 'SET_ACTIVE_THRESHOLD';
export const SET_LOCATION: string = 'SET_LOCATION';
export const SET_MAPPAIR_POINT_MODE_ON: string = 'MAPPAIR_POINT_MODE_ON';
export const SET_MAPPAIR_POINT_MODE_OFF: string = 'MAPPAIR_POINT_MODE_OFF';
export const SET_SELECTED_FILTER: string = 'SET_SELECTED_FILTER';
export const SET_TODAY_DATA: string = 'SET_TODAY_DATA';
export const SET_UNIT_LOCATION: string = 'SET_UNIT_LOCATION';
export const SHOW_AQMA_LAYER: string = 'SHOW_AQMA_LAYER';
export const SHOW_MAP_AIR_FILTERS: string = 'SHOW_MAP_AIR_FILTERS';
export const SHOW_OVERLAY_LAYER: string = 'SHOW_OVERLAY_LAYER';
export const SHOW_SATELLITE_LAYER: string = 'SHOW_SATELLITE_LAYER';
export const SHOW_SCHOOLS_LAYER: string = 'SHOW_SCHOOLS_LAYER';
export const SHOW_SMOKE_CONTROL_ZONES: string = 'SHOW_SMOKE_CONTROL_ZONES';
export const SET_MAPPAIR_POINT_VALUE_HISTORY: string =
  'SET_MAPPAIR_POINT_VALUE_HISTORY';
export const SHOW_AA_LAYER: string =
  'SHOW_AA_LAYER';
export const SET_ANNUAL_AVERAGE: string = 'SET_ANNUAL_AVERAGE';

// Nav Types
export const CLOSE_ASIDE: string = 'CLOSE_ASIDE';
export const OPEN_ASIDE: string = 'OPEN_ASIDE';
export const SET_AQ101_OFF: string = 'SET_AQ101_OFF';
export const SET_AQ101_ON: string = 'SET_AQ101_ON';
export const SET_AQ101_SECTION: string = 'SET_AQ101_SECTION';

// Tour Types
export const TOUR_CLOSE_AQ_COLORSCALE_INDEX: string =
  'TOUR_CLOSE_AQ_COLORSCALE_INDEX';
export const TOUR_CLOSE_AQ_DATA_INDEX: string = 'TOUR_CLOSE_AQ_DATA_INDEX';
export const TOUR_CLOSE_AQMA_INDEX: string = 'TOUR_CLOSE_AQMA_INDEX';
export const TOUR_CLOSE_ASIDE: string = 'TOUR_CLOSE_ASIDE';
export const TOUR_CLOSE_ASIDE_FILTER: string = 'TOUR_CLOSE_ASIDE_FITLER';
export const TOUR_FINISHED: string = 'TOUR_FINISHED';
export const TOUR_LAUNCHED: string = 'TOUR_LAUNCHED';
export const TOUR_OPEN_AQ_COLORSCALE_INDEX: string =
  'TOUR_OPEN_AQ_COLORSCALE_INDEX';
export const TOUR_OPEN_AQ_DATA_INDEX: string = 'TOUR_OPEN_AQ_DATA_INDEX';
export const TOUR_OPEN_AQMA_INDEX: string = 'TOUR_OPEN_AQMA_INDEX';
export const TOUR_OPEN_ASIDE: string = 'TOUR_OPEN_ASIDE';
export const TOUR_OPEN_ASIDE_FILTER: string = 'TOUR_OPEN_ASIDE_FILTER';
export const TOUR_OPEN_HELP: string = 'TOUR_OPEN_HELP';
export const TOUR_CLOSE_HELP: string = 'TOUR_CLOSE_HELP';
export const TOUR_OPEN_LOCATION_SEARCH: string = 'TOUR_OPEN_LOCATION_SEARCH';
export const TOUR_CLOSE_LOCATION_SEARCH: string = 'TOUR_CLOSE_LOCATION_SEARCH';
export const TOUR_MAP_CLICK: string = 'TOUR_MAP_CLICK';
export const TOUR_CLOSE_MODEL_DATA_INDEX = 'TOUR_CLOSE_MODEL_DATA_INDEX';
export const TOUR_OPEN_MODEL_DATA_INDEX = 'TOUR_OPEN_MODEL_DATA_INDEX';

// URL Query String Types
export const SET_QUERY_STRING_PARAMS: string = 'SET_QUERY_STRING_PARAMS';

// Zephyr Types
export const CLEAR_ZEPHYR: string = 'CLEAR_ZEPHYR';
export const GET_ZEPHYRS: string = 'GET_ZEPHYRS';
export const ADD_ZEPHYRS: string = 'ADD_ZEPHYRS';
export const GET_ZEPHYR_HISTORICAL_DATA: string = 'GET_ZEPHYR_HISTORICAL_DATA';
export const SET_ZEPHYR: string = 'SET_ZEPHYR';
export const SET_ZEPHYR_IN_EDIT_MODE: string = 'SET_ZEPHYR_IN_EDIT_MODE';
export const SET_ZEPHYR_HISTORICAL_DATA_LOADING: string =
  'SET_ZEPHYR_HISTORICAL_DATA_LOADING';
export const SET_DRAGGED_UNIT: string = 'SET_DRAGGED_UNIT';
export const CLEAR_DRAGGED_UNIT: string = 'CLEAR_DRAGGED_UNIT';
export const GET_AURNS = 'GET_AURNS';
export const GET_ZEPHYR_MET_DATA: string = 'GET_ZEPHYR_MET_DATA';

// Loading types
export const LOADING_INIT_APP_OFF: string = 'LOADING_INIT_APP_OFF';
export const LOADING_INIT_APP_ON: string = 'LOADING_INIT_APP_ON';
export const LOADING_INIT_APP_DATA_OFF: string = 'LOADING_INIT_APP_DATA_OFF';
export const LOADING_INIT_APP_DATA_ON: string = 'LOADING_INIT_APP_DATA_ON';
export const LOADING_INIT_APP_THIRDPARTY_DATA_OFF: string =
  'LOADING_INIT_APP_THIRDPARTY_DATA_OFF';
export const LOADING_INIT_APP_THIRDPARTY_DATA_ON: string =
  'LOADING_INIT_APP_THIRDPARTY_DATA_ON';

// Modal types
export const SET_CONTACT_MODAL_ON: string = 'SET_CONTACT_MODAL_ON';
export const SET_DATA_MODAL_ON: string = 'SET_DATA_MODAL_ON';
export const SET_MAPPAIR_QUOTE_MODAL_ON: string = 'SET_MAPPAIR_QUOTE_MODAL_ON';
export const SET_MAPPAIR_TIMESERIES_MODAL_ON: string =
  'SET_MAPPAIR_TIMESERIES_MODAL_ON';
export const SET_MODALS_OFF: string = 'SET_MODALS_OFF';
export const SET_ZEPHYR_QUOTE_MODAL_ON: string = 'SET_ZEPHYR_QUOTE_MODAL_ON';
export const SET_ANNUAL_AVERAGES: string = 'SET_ANNUAL_AVERAGES';

// set Language
export const SET_LOCALE: string = 'SET_LOCALE';

// Modular features types
export const SET_MODULAR_FEATURES: string = 'SET_MODULAR_FEATURES';

// Data Analytics Types
export const SET_UNIT_HISTORIES: string = 'SET_UNIT_HISTORIES';
export const SET_UNIT_HISTORIES_LOADING: string = 'SET_UNIT_HISTORIES_LOADING';
export const SET_THRESHOLD_LIMITS: string = 'SET_THRESHOLD_LIMITS';
export const SET_ZEPHYR_COVERAGES_LIST: string = 'SET_ZEPHYR_COVERAGES_LIST';
export const SET_IS_DATA_AVAILABILITY_CHART_LOADING: string = 'SET_IS_DATA_AVAILABILITY_CHART_LOADING';
export const SET_DSE_CHECKBOX_STATE: string = 'SET_DSE_CHECKBOX_STATE';
export const INCREASE_ARRIVED_REQUESTS: string = 'INCREASE_ARRIVED_REQUESTS';
export const SET_ARRIVED_REQUESTS_TO_INIT_STATE: string = 'SET_ARRIVED_REQUESTS_TO_INIT_STATE';
export const SET_NULLABLE_UNITS: string = 'SET_NULLABLE_UNITS';
export const SET_PROCEED_WITH_ANALYSIS: string = 'SET_PROCEED_WITH_ANALYSIS';
export const SET_IS_VIEW_LOADED: string = 'SET_IS_VIEW_LOADED';
export const SET_IS_CARTRIDGE_DATA_LOADING: string = 'SET_IS_CARTRIDGE_DATA_LOADING';
export const SET_THRESHOLDS: string = 'SET_THRESHOLDS';