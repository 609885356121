// Package Imports
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Location } from 'history';

// Component Imports
import AsideUnit from './AsideUnit';
import PublicAsideUnit from './PublicAsideUnit';

// Action Imports
import { setZephyr } from '../../actions/zephyrs';
import { setUnitLocation } from '../../actions/locations';
import { setMappairPointModeOff } from '../../actions/layers';

// Util Imports
import analyticsEventFirer from '../../utils/functions/analyticsEventFirer';
import { colourFinder } from '../../utils/functions/colourFinder';

// Const Imports
import { gtmEventIdentifiers } from '../../utils/consts';

// Type Imports
import {
  Overlay,
  ReduxState,
  Stylegroup,
  Zephyr,
  VZephyrsConfig,
  ZephyrTypes,
} from '../../utils/interface';

// Component Interfaces
interface AsideUnitListProps {
  activeOverlay: string;
  allUnits: Zephyr[];
  dataLoading: boolean;
  isPublic: boolean;
  location: Location;
  mappairPointMode: boolean;
  overlays: Overlay[];
  setMappairPointModeOff: Function;
  setUnitLocation: Function;
  selectedZephyr: { zephyr: Zephyr };
  setZephyr: Function;
  stylegroups: Stylegroup[];
  thresholdTab: string;
  units: Zephyr[];
  vZephyrsConfig: VZephyrsConfig;
  setSelectedVZephyrToDelete: Function;
  unSubSelectedVZephyr: Function;
  overlay: any;
  setFilteredZephyrs: Function;
  filteredZephyrs: Zephyr[]
}

// Component
const AsideUnitList = ({
  activeOverlay,
  allUnits,
  dataLoading,
  isPublic,
  location,
  mappairPointMode,
  overlays,
  setMappairPointModeOff,
  setUnitLocation,
  selectedZephyr,
  setZephyr,
  stylegroups,
  thresholdTab,
  units,
  vZephyrsConfig,
  setSelectedVZephyrToDelete,
  unSubSelectedVZephyr,
  overlay,
  setFilteredZephyrs,
  filteredZephyrs,
}: AsideUnitListProps) => {
  // Effects
  useEffect(() => {
    const activeBeacon = document.querySelector('.unit-list .unit-card.active');
    if (activeBeacon) {
      activeBeacon.scrollIntoView();
    }
  }, [selectedZephyr]);

  useEffect(() => {
    if (
      allUnits.length &&
      allUnits[0].averageOfLastHourOfData &&
      selectedZephyr &&
      !selectedZephyr.zephyr.averageOfLastHourOfData
    ) {
      const refreshedSelectedUnitWithLastHourData = allUnits.filter(
        (z) => z.zNumber === selectedZephyr.zephyr.zNumber,
      )[0];
      setZephyr(refreshedSelectedUnitWithLastHourData);
    }
  }, [allUnits]);

  // Functions
  const selectUnit = (unit: Zephyr) => {
    if (!unit.userEndTimeDate || unit.type === ZephyrTypes.virtual) {
      setUnitLocation(unit.longitude, unit.latitude);
    }
    setMappairPointModeOff();
    setZephyr(unit);
    // Analytics
    analyticsEventFirer(gtmEventIdentifiers.unitSelect, location.pathname);
  };

  const setColour = (unit: Zephyr) => {
    let fillColour;
    const opacity = 0.8;
    const noDataOpacity = 1;
    if (overlay) {
      if (unit.type === ZephyrTypes.virtual) {
        const curOverlay = overlays.filter((ol) => ol.name === activeOverlay)[0].name === overlay.name ?
          overlays.filter((ol) => ol.name === activeOverlay)[0].name : overlay.name;
        return colourFinder(
          curOverlay,
          stylegroups,
          thresholdTab,
          opacity,
          overlays,
          unit?.averageOfLastHourOfData?.NO2 || 0,
          'virtual',
        );
      }

      if (unit.userEndTimeDate) {
        fillColour = `rgba(146,148,154,${noDataOpacity})`;
      } else if (!unit.averageOfLastHourOfData) {
        fillColour = `rgba(34,37,57,${noDataOpacity})`;
      } else if (
        unit.averageOfLastHourOfData.AQI === null ||
        !overlays ||
        !activeOverlay
      ) {
        fillColour = `rgba(255, 255, 255, ${noDataOpacity})`;
      } else {
        const curOverlay = overlays.filter((ol) => ol.name === activeOverlay)[0].name === overlay.name ?
          overlays.filter((ol) => ol.name === activeOverlay)[0].name : overlay.name;
        fillColour = colourFinder(
          curOverlay,
          stylegroups,
          thresholdTab,
          opacity,
          overlays,
          unit.averageOfLastHourOfData[curOverlay.speciesIdentifier],
          'zephyr',
        );
      }
    }
    return fillColour;
  };

  return (
    <>
      {units.map((unit, idx, unitList) => (
        <div key={`${unit.zNumber}-${idx}`}>
          {isPublic ? (
            <PublicAsideUnit
              dataLoading={dataLoading}
              mappairPointMode={mappairPointMode}
              prevUnit={idx === 0 ? null : unitList[idx - 1]}
              selectUnit={selectUnit}
              selectedUnit={selectedZephyr?.zephyr?.zNumber}
              setColour={setColour}
              unit={unit}
            />
          ) : (
            <AsideUnit
              dataLoading={dataLoading}
              mappairPointMode={mappairPointMode}
              prevUnit={idx === 0 ? null : unitList[idx - 1]}
              selectUnit={selectUnit}
              selectedUnit={selectedZephyr?.zephyr?.zNumber}
              setColour={setColour}
              unit={unit}
              vZephyrsConfig={vZephyrsConfig}
              setSelectedVZephyrToDelete={setSelectedVZephyrToDelete}
              unSubSelectedVZephyr={unSubSelectedVZephyr}
              selectedUnitIdPod={selectedZephyr?.zephyr?.id_pod}
              setFilteredZephyrs={setFilteredZephyrs}
              filteredZephyrs={filteredZephyrs}
            />
          )}
        </div>
      ))}
    </>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  activeOverlay: state.showAQMALayer.overlay,
  dataLoading: state.getZephyrHistoricalData.loading,
  mappairPointMode: state.showAQMALayer.mappairPointModeOn,
  overlays: state.setOverlays,
  selectedZephyr: state.setZephyr,
  stylegroups: state.getStyleGroups.styleGroups,
  thresholdTab: state.setThresholdTab.tab,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setZephyr,
      setUnitLocation,
      setMappairPointModeOff,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AsideUnitList);
