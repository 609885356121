// Package Imports
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

// Component Imports
import LocationMarker from './LocationMarker';

// Action Imports
import { setZephyr } from '../../actions/zephyrs';

// Util Imports
import { colourFinder } from '../../utils/functions/colourFinder';

// Type Imports
import {
  MappAirSelectedFilter,
  Overlay as OverlayType,
  Stylegroup,
  Zephyr,
  ZephyrTypes,
} from '../../utils/interface';

// Types
interface LocationMarkerListProps {
  curOverlayName: string;
  curZephyr?: Zephyr;
  overlays: OverlayType[];
  setCancelPopup: Function;
  setZephyr: Function;
  stylegroups: Stylegroup[];
  threshold: string;
  unitFilters: MappAirSelectedFilter[];
  unitList: Zephyr[];
  points: any;
}

// Component
const LocationMarkerList = ({
  curOverlayName,
  curZephyr,
  overlays,
  setCancelPopup,
  setZephyr,
  stylegroups,
  threshold,
  unitFilters,
  unitList,
  points,
}: LocationMarkerListProps) => {
  // Functions
  const getMarkerColour = (unit: Zephyr) => {
    let markerColour = 'rgba(192, 202, 212, 1)';
    const curOverlay = overlays.filter((ol) => ol.name === curOverlayName)[0];
    const opacity = 0.8;

    if (overlays && curOverlay && unit && unit.averageOfLastHourOfData) {
      markerColour =
        colourFinder(
          curOverlay,
          stylegroups,
          threshold,
          opacity,
          overlays,
          unit.averageOfLastHourOfData[curOverlay.speciesIdentifier],
          unit.type === ZephyrTypes.virtual ? 'virtual' : 'zephyr',
        ) || markerColour;
    }

    return markerColour;
  };

  const filterApproved = (unitType: number) => {
    // TODO: Move to consts
    const typeMap = ['zephyr', 'aurn'];

    switch (unitType) {
      case 0:
      case 1:
        return (
          unitFilters.filter((uf) => uf.name === typeMap[unitType]).length > 0
        );
      case 100:
        return unitFilters.filter((uf) => uf.name === 'virtual').length > 0;
      default:
        return false;
    }
  };

  const labelFilterApproved = (unitId: number, unitType: number) => {
    const typeMap = ['zephyr', 'aurn'];

    let unitTypeFilter: any[] = [];
    switch (unitType) {
      case 0:
      case 1:
        unitTypeFilter = unitFilters.filter(
          (uf) => uf.name === typeMap[unitType],
        );
        break;
      case 100:
        unitTypeFilter = unitFilters.filter((uf) => uf.name === 'virtual');
        break;
      default:
        unitTypeFilter = [];
        break;
    }

    if (unitTypeFilter.length) {
      return (
        unitTypeFilter[0].showAllLabels ||
        (unitTypeFilter[0].showSelectedLabel && unitActive(unitId))
      );
    }
    return false;
  };

  const selectUnit = (unit: Zephyr) => {
    setCancelPopup(true);
    setZephyr(unit);
  };

  const unitActive = (unitId: number) => {
    if (curZephyr) {
      return curZephyr.id_pod === unitId;
    }
    return false;
  };

  return (
    <div id="markers">
      {unitList.length > 0 ? (
        <>
          {unitList.map((unit, i) => {
            const color = getMarkerColour(unit);
            
            return (
              <LocationMarker
                key={`${unit.id_pod}-${unit.type}`}
                isActive={unitActive(unit.id_pod)}
                isVisible={filterApproved(unit.type)}
                markerColour={getMarkerColour(unit)}
                selectUnit={selectUnit}
                // showLabel={labelFilterApproved(unit.id_pod, unit.type)}
                showLabel={false}
                unit={unit}
              />
            );
          })}
        </>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setZephyr,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(LocationMarkerList);
