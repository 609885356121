// Package Imports
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

// Hooks
import { useLocation } from 'react-router-dom';

// Action Imports
import { openAside, closeAside } from '../../actions/aside';
import { setZephyrQuoteModalOn } from '../../actions/modal';
import {
  increaseArrivedRequests,
  restartArrivedRequests,
  setUnitHistoriesList,
  setUnitHistoriesLoading,
  setNullableUnits,
  proceedWithAnalysis,
  setIsViewLoaded,
} from '../../actions/dataAnalytics';

// Component Imports
import AsidePrompt from '../view_components/AsidePrompt';
import AsidePublicOverlays from '../view_components/AsidePublicOverlays';
import AsideOverlays from '../view_components/AsideOverlays';
import AsideUnitList from '../view_components/AsideUnitList';

// Content Imports
import { AsideContent, virtualZephyrsPollutants } from '../../utils/consts';

// Type Imports
import {
  MappAirSelectedFilter,
  Modal as ModalType,
  ReduxState,
  UnitOverlayTypes,
  Zephyr,
  VZephyrsConfig,
  ZephyrTypes,
  ZephyrAnalytics,
  DisplayConfig,
  Pollutant,
  TimePeriods,
  TimePeriod,
  AveragingPeriod,
  AveragingPeriods,
  UrlQueryStringParams,
  DataConfig,
} from '../../utils/interface';
import { speciesExtentFinder } from '../../utils/functions/speciesExtentFinder';
import UnitCardWrapper from './UnitCardWrapper';
import {
  dateFinder,
  defaultStartEndFinder,
  startEndFinder,
  utcConvertor,
} from '../../utils/functions/dateFinder';
import moment from 'moment';
import { getZephyrData } from '../../actions/zephyrs';
import { compress, decompress } from 'lz-string';

// Component Interfaces
interface AsideProps {
  asideOn: boolean;
  closeAside: Function;
  modal: ModalType;
  isPublic: boolean;
  openAside: Function;
  selectedFilters: MappAirSelectedFilter[];
  setModalsOff: Function;
  setZephyrQuoteModalOn: Function;
  setSelectedVZephyrToDelete: Function;
  unSubSelectedVZephyr: Function;
  zephyrs: Zephyr[];
  vZephyrsConfig: VZephyrsConfig;
  overlay: any;
  displayConfig: DisplayConfig;
  timePeriods: TimePeriods;
  averagingPeriods: AveragingPeriods;
  queryStringParams: UrlQueryStringParams;
  dataConfig: DataConfig;
  getZephyrData: Function;
  setUnitHistoriesList: Function;
  setUnitHistoriesLoading: Function;
  unitHistoriesLoading: boolean;
  unitHistoriesList: any;
  dseOn: boolean;
  bearerToken: string | null;
  increaseArrivedRequests: Function;
  requests: number;
  restartArrivedRequests: Function;
  nullableUnits: number[];
  setNullableUnits: Function;
  toProceed: boolean | null;
  proceedWithAnalysis: Function;
  viewLoaded: boolean;
  setIsViewLoaded: Function;
  isCartridgeDataLoading: boolean | null;
}

export interface AnalyticsOptionsProperties {
  id: number;
  text: string;
  isSelected: boolean;
}

export interface AnalyticsOptions {
  devices: AnalyticsOptionsProperties;
  pollutants: AnalyticsOptionsProperties;
  timeAverage: AnalyticsOptionsProperties;
  timePeriod: AnalyticsOptionsProperties;
}

interface InitState {
  filteredZephyrs: Zephyr[];
  isOverlayMenuVisible: boolean;
  subMenus: {
    aurn: boolean;
    zephyr: boolean;
  };
  options?: AnalyticsOptions;
}

const initState: InitState = {
  filteredZephyrs: [],
  isOverlayMenuVisible: false,
  subMenus: {
    aurn: false,
    zephyr: false,
  },
  // TODO
  // need to remove, unused in dataAnalytics
  // location.pathname.includes('analysis')- remove all these components
  options: {
    devices: {
      id: 1,
      text: 'Devices',
      isSelected: true,
    },
    pollutants: {
      id: 2,
      text: 'Pollutants',
      isSelected: true,
    },
    timeAverage: {
      id: 3,
      text: 'Time Average',
      isSelected: true,
    },
    timePeriod: {
      id: 4,
      text: 'Time Period',
      isSelected: true,
    },
  },
};

export { initState as InitStateFilters };

// View Component
const Aside = ({
  asideOn,
  closeAside,
  isPublic,
  modal,
  openAside,
  selectedFilters,
  setModalsOff,
  setZephyrQuoteModalOn,
  setSelectedVZephyrToDelete,
  unSubSelectedVZephyr,
  zephyrs,
  vZephyrsConfig,
  overlay,
  displayConfig,
  timePeriods,
  averagingPeriods,
  queryStringParams,
  dataConfig,
  getZephyrData,
  setUnitHistoriesList,
  setUnitHistoriesLoading,
  unitHistoriesLoading,
  unitHistoriesList,
  dseOn,
  bearerToken,
  increaseArrivedRequests,
  requests,
  restartArrivedRequests,
  nullableUnits,
  setNullableUnits,
  toProceed,
  proceedWithAnalysis,
  viewLoaded,
  setIsViewLoaded,
  isCartridgeDataLoading,
}: AsideProps) => {
  const { t: translate } = useTranslation();

  const [isOverlayMenuVisible, setIsOverlayMenuVisible] = useState(
    initState.isOverlayMenuVisible,
  );
  const [subMenus, setSubMenus] = useState(initState.subMenus);

  const [filteredZephyrs, setFilteredZephyrs] = useState(
    initState.filteredZephyrs,
  );

  const [searchString, setSearchString] = useState<string>('');

  const [customDate, setCustomDate] = useState({
    start: dateFinder(new Date(), 1, true),
    end: new Date(),
  });

  // Consts
  const location = useLocation();

  // Effects
  useEffect(() => {
    if (zephyrs && zephyrs.length)
      setFilteredZephyrs([...getUpdatedFilteredZephyrsList()]);
  }, [zephyrs]);

  // Functions

  const getUpdatedFilteredZephyrsList = () => {
    let zephyrsCopy: Zephyr[] = [];
    if (!location.pathname.includes('analysis')) {
      zephyrsCopy = [...zephyrs].map((zephyr: Zephyr) => {
        return zephyr;
      });
    } else {
      zephyrsCopy = [...zephyrs].map((zephyr: Zephyr) => {
        if (filteredZephyrs.length) {
          const filteredZephyr = filteredZephyrs.find(
            (z: Zephyr) => z.zNumber === zephyr.zNumber,
          );
          if (filteredZephyr?.isSelected) zephyr.isSelected = true;
          else zephyr.isSelected = false;
        } else zephyr.isSelected = false;
        return zephyr;
      });
    }
    return zephyrsCopy;
  };

  const handleUnitSearch = (search: string) => {
    if (search === '') {
      setFilteredZephyrs(zephyrs.slice());
    } else {
      const newFilteredZephyrs = filterUnits(zephyrs, search);
      setFilteredZephyrs(newFilteredZephyrs);
    }
    setSearchString(search);
  };

  const filterUnits = (units: Zephyr[], search: string) => {
    return units
      .slice()
      .filter((z: Zephyr) => z.name)
      .filter(
        (z: Zephyr) =>
          z.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          z.zNumber.toString().indexOf(search) > -1,
      );
  };

  const showAllUnits = () => {
    setFilteredZephyrs(zephyrs.slice());
  };

  const selectedUnitListCheck = (overlay: UnitOverlayTypes) => {
    const isAvailable = selectedFilters.filter((sF) => sF.name === overlay);
    const check = !!isAvailable.length;
    return check;
  };

  const selectedUnitList = () => {
    const selectedUnitList = filteredZephyrs.filter(
      (unit) =>
        (unit.type === ZephyrTypes.aurns && selectedUnitListCheck('zephyr')) ||
        (unit.type === ZephyrTypes.zephyr && selectedUnitListCheck('aurn')) ||
        (unit.type === ZephyrTypes.virtual && selectedUnitListCheck('virtual')),
    );

    return selectedUnitList;
  };

  if (location.pathname.includes('fleetManagement')) return null;

  return (
    <>
      <aside
        id="a-primary"
        data-user-tour="step-1"
        className={`tourProductInventory remove-mobile ${
          asideOn ? '' : 'collapsed'
        } ${location.pathname.includes('data') ? 'hide-overlay' : ''}`}
      >
        <div
          className={`product-inventory d-flex align-items-center`}
          onClick={(e) => (asideOn ? closeAside() : openAside())}
        >
          <button type="button" className="w-100">
            {!isPublic
              ? translate('AsideHeader')
              : translate('PublicAsideHeader')}
          </button>
          {!isPublic ? (
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setIsOverlayMenuVisible(!isOverlayMenuVisible);
              }}
              className="kebab mr-4 px-4"
            />
          ) : null}
          <button className={`chevron ${asideOn ? 'closed' : ''}`} />
        </div>
        <>
          {isPublic ? (
            <AsidePublicOverlays
              asideOn={asideOn}
              location={location}
              selectedUnitListCheck={selectedUnitListCheck}
              zephyrs={zephyrs}
              isOverlayMenuVisible={isOverlayMenuVisible}
              setIsOverlayMenuVisible={setIsOverlayMenuVisible}
            />
          ) : (
            <AsideOverlays
              allUnits={zephyrs}
              asideOn={asideOn}
              location={location}
              selectedUnitListCheck={selectedUnitListCheck}
              showAllUnits={showAllUnits}
              zephyrs={zephyrs}
              isOverlayMenuVisible={isOverlayMenuVisible}
              setIsOverlayMenuVisible={setIsOverlayMenuVisible}
            />
          )}
          <div
            className={`a-content ${isPublic ? 'a-content-public' : ''} ${
              location.pathname.includes('data') ? 'data' : ''
            } ${asideOn ? '' : 'collapsed'}`}
          >
            {!location.pathname.includes('analysis') && (
              <>
                {filteredZephyrs &&
                zephyrs.length &&
                zephyrs.length > 3 &&
                (selectedUnitListCheck('zephyr') ||
                  selectedUnitListCheck('aurn')) ? (
                  <input
                    type="text"
                    name="search"
                    placeholder={
                      isPublic
                        ? 'Search air quality sensors'
                        : selectedUnitListCheck('aurn')
                        ? 'Search Devices'
                        : 'Search Zephyrs'
                    }
                    onChange={(e) => handleUnitSearch(e.target.value)}
                    className="a-dark-input"
                  />
                ) : (
                  <></>
                )}
                <ul className="unit-list asideUnit">
                  {filteredZephyrs && (
                    <AsideUnitList
                      allUnits={zephyrs}
                      isPublic={isPublic}
                      location={location}
                      units={selectedUnitList()}
                      vZephyrsConfig={vZephyrsConfig}
                      setSelectedVZephyrToDelete={setSelectedVZephyrToDelete}
                      unSubSelectedVZephyr={unSubSelectedVZephyr}
                      setFilteredZephyrs={setFilteredZephyrs}
                      filteredZephyrs={filteredZephyrs}
                      overlay={overlay}
                    />
                  )}
                </ul>
              </>
            )}
          </div>
          {!isPublic &&
          asideOn &&
          !location.pathname.includes('alerts') &&
          !location.pathname.includes('analysis') ? (
            <AsidePrompt
              modal={modal}
              setModalsOff={setModalsOff}
              setZephyrQuoteModalOn={setZephyrQuoteModalOn}
            />
          ) : (
            <></>
          )}
        </>
      </aside>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  asideOn: state.aside.on,
  isPublic: state.auth.userInfo.isPublic,
  selectedFilters: state.mappAirFilters.selectedFilters,
  zephyrs: state.getZephyrs.zephyrs,
  curZephyr: state.setZephyr.zephyr,
  displayConfig: state.setDisplayConfig,
  timePeriods: state.setTimePeriods,
  averagingPeriods: state.setAveragingPeriods,
  queryStringParams: state.queryStringParams,
  dataConfig: state.dataConfig,
  unitHistoriesLoading: state.unitHistoriesOptions.isUnitHistoriesLoading,
  unitHistoriesList: state.unitHistoriesOptions.unitHistories,
  dseOn: state.unitHistoriesOptions.dseOn,
  bearerToken: state.auth.bearerToken,
  requests: state.unitHistoriesOptions.requests,
  nullableUnits: state.unitHistoriesOptions.nullableUnits,
  toProceed: state.unitHistoriesOptions.toProceed,
  viewLoaded: state.unitHistoriesOptions.viewLoaded,
  isCartridgeDataLoading: state.unitHistoriesOptions.isCartridgeDataLoading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      openAside,
      closeAside,
      setZephyrQuoteModalOn,
      getZephyrData,
      setUnitHistoriesList,
      setUnitHistoriesLoading,
      increaseArrivedRequests,
      restartArrivedRequests,
      setNullableUnits,
      proceedWithAnalysis,
      setIsViewLoaded,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Aside);
