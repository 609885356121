// Package Imports
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

// OL Imports
import {
  containsXY,
  applyTransform,
  createEmpty,
  getBottomLeft,
  getBottomRight,
  getTopLeft,
  getTopRight,
  getArea,
  getCenter,
  getSize,
  getCorner,
  getWidth,
  getHeight,
  extend,
} from 'ol/extent';
// @ts-ignore
import Crop from 'ol-ext/filter/Crop';
import Feature from 'ol/Feature';
import { fromLonLat, toLonLat } from 'ol/proj';
import { Group as LayerGroup } from 'ol/layer';
import ImageLayer from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';
import Map from 'ol/Map';
import MultiPolygon from 'ol/geom/MultiPolygon';
import OSM from 'ol/source/OSM';
import Overlay from 'ol/Overlay';
import TileLayer from 'ol/layer/Tile';
import { toStringHDMS } from 'ol/coordinate';
import View from 'ol/View';
import WMTS, { optionsFromCapabilities } from 'ol/source/WMTS';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';
import XYZ from 'ol/source/XYZ';

import { DragPan, MouseWheelZoom, defaults } from 'ol/interaction';
import { platformModifierKeyOnly } from 'ol/events/condition';

// OL Types Imports
import BaseLayer from 'ol/layer/Base';
import Collection from 'ol/Collection';
import ImageTile from 'ol/ImageTile';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import Tile from 'ol/Tile';

// Component Imports
import TileGrid from 'ol/tilegrid/TileGrid';
import axios from 'axios';
import AQIIndex from '../view_components/AQIIndex';
import LatestMeasurementsPublic from '../view_components/LatestMeasurementsPublic';
import LocationControls from '../view_components/LocationControls';
import LocationLicensing from '../view_components/LocationLicensing';
import LocationMarkerList from '../view_components/LocationMarkerList';
import LocationPopover from '../view_components/LocationPopover';
import AddZephyrModal from '../view_components/AddZephyrModal';
import Spinner from '../shared_components/Spinner';
import Modal from '../shared_components/Modal';

// Action Imports
import {
  checkLocation,
  mapsLocationLoaded,
  setLocation,
} from '../../actions/locations';

import { setAnnualAverages } from '../../actions/annualAverage';
import { setMapAirPointValueHistory } from '../../actions/mapAirPointValueHistory';
import { setAnnualAverage } from '../../actions/layers';

// Util Imports
import { getAuth } from '../../utils/functions/getAuth';
import utilsRequest from '../../utils/request';
import { dateFinder } from '../../utils/functions/dateFinder';
import { colourFinder } from '../../utils/functions/colourFinder';
import { wideScreenFinder } from '../../utils/functions/wideScreenFinder';
import { tallScreenFinder } from '../../utils/functions/tallScreenFinder';
import { mappairSpeciesFinder } from '../../utils/functions/tabFinder';
import { pointDataPackager } from '../../utils/functions/saDataPackager';

// Const Imports
import {
  APIPaths,
  crs,
  highDefinitionPointsGroup,
  config,
  keys,
  numberYrsHistory,
  AnnualAvgDataInitialState,
  MapAirPointValueHistoryInitialState,
  dimensions,
} from '../../utils/consts';

// Type Imports
import {
  AnnualAvgData,
  DisplayConfig,
  Loading,
  LocationCoordinates,
  MappAirSelectedFilter,
  Overlay as OverlayType,
  PointValues,
  ReduxState,
  ShowAQMALayer,
  Stylegroup,
  TilingGroup,
  TilingLayer,
  UserInfo,
  Zephyr,
  VZephyrsConfig,
  MapAirPointValueHistoryData,
  LonLatXY,
  tileDimensions,
  // ZephyrTypes,
} from '../../utils/interface';
import Banner from '../shared_components/Banner';
import { mappAirFilters } from '../../actions/mappAirFilters';
import { i18n } from '../../translations/i18n';
import ReactGridLayout from '../view_components/ReactGridLayout/ReactGridLayout';

// Component Interfaces
interface InitState {
  cancelPopup: boolean;
  map: Map | null;
  mapLoading: boolean;
  points: PointValues | null;
  pointsLoading: boolean;
  zephyrModalOpen: boolean;
  curTimeSliderOption: number | null;
  wmsDateTime: Date | null;
}

interface LocationProps {
  asideOn: boolean;
  curOverlayName: string;
  curZephyr: Zephyr;
  displayConfig: DisplayConfig;
  loading: Loading;
  // Need to fix this for the initial coords Type "Coordinate"
  // location: LocationCoordinates;
  location: any;
  mapsLocationLoaded: Function;
  mappairOptions: ShowAQMALayer;
  overlays: OverlayType[];
  setLocation: Function;
  startingZoom: number;
  stylegroups: Stylegroup[];
  threshold: string;
  tourClick: boolean;
  unitFilters: MappAirSelectedFilter[];
  unitList: Zephyr[];
  userInfo: UserInfo;
  annualAvgData: AnnualAvgData;
  setAnnualAverages: Function;
  mapAirPointValueHistory: MapAirPointValueHistoryData;
  setMapAirPointValueHistory: Function;
  getUserVirtualZephyrs: Function;
  vZephyrsConfig: VZephyrsConfig;
  isBannerShowing: boolean;
  isModalOpened: boolean;
  setIsModalOpened: Function;
  bannerValue: string[];
  startDate: Date;
  endDate: Date;
  setAnnualAverage: Function;
  annualAverage: number | null;
  curOpacity: any;
  bearerToken: string | null;
  annualAveragesOn: boolean;
}

const mappairOlArr: any = [];
const annualAveragesOlArr: any = [];

// Component
const Location = ({
  asideOn,
  curOverlayName,
  curZephyr,
  displayConfig,
  loading,
  location,
  mapsLocationLoaded,
  mappairOptions,
  overlays,
  setLocation,
  startingZoom,
  stylegroups,
  threshold,
  tourClick,
  unitFilters,
  unitList,
  userInfo,
  annualAvgData,
  setAnnualAverages,
  mapAirPointValueHistory,
  setMapAirPointValueHistory,
  getUserVirtualZephyrs,
  vZephyrsConfig,
  isBannerShowing,
  isModalOpened,
  setIsModalOpened,
  bannerValue,
  startDate,
  endDate,
  setAnnualAverage,
  annualAverage,
  curOpacity,
  bearerToken,
  annualAveragesOn,
}: LocationProps) => {
  const initState: InitState = {
    cancelPopup: false,
    map: null,
    mapLoading: false,
    points: null,
    pointsLoading: false,
    zephyrModalOpen: false,
    curTimeSliderOption: 1,
    wmsDateTime: null,
  };

  // State
  const [cancelPopup, setCancelPopup] = useState(initState.cancelPopup);
  const [map, setMap] = useState(initState.map);
  const [mapLoading, setMapLoading] = useState(initState.mapLoading);
  const [points, setPoints] = useState(initState.points);
  const [pointsLoading, setPointsLoading] = useState(initState.pointsLoading);
  const [zephyrModalOpen, setZephyrModalOpen] = useState(
    initState.zephyrModalOpen,
  );
  const [curTimeSliderOption, setCurTimeSliderOption] = useState(
    initState.curTimeSliderOption,
  );
  const [wmsDateTime, setWMSDateTime] = useState(initState.wmsDateTime);

  // Refs
  const mapElement = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<Map | null>(null);
  mapRef.current = map;

  const thresholdRef = useRef<string | null>(null);
  thresholdRef.current = threshold;

  const mappairOptionsRef = useRef<ShowAQMALayer | null>(null);
  mappairOptionsRef.current = mappairOptions;

  const cancelPopupRef = useRef<boolean | null>(null);
  cancelPopupRef.current = cancelPopup;

  // Effects

  useEffect(() => {
    const curDate = new Date();
    if (
      !sessionStorage.getItem('locationRefreshed') &&
      curDate >= startDate &&
      curDate <= endDate
    )
      sessionStorage.setItem('locationRefreshed', 'false');
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      if (
        sessionStorage.getItem('locationRefreshed') &&
        sessionStorage.getItem('locationRefreshed') === 'false'
      )
        sessionStorage.setItem('locationRefreshed', 'true');
    });
    return () => {
      window.removeEventListener('beforeunload', () => {
        if (
          sessionStorage.getItem('locationRefreshed') &&
          sessionStorage.getItem('locationRefreshed') === 'false'
        )
          sessionStorage.setItem('locationRefreshed', 'true');
      });
    };
  }, []);

  useEffect(() => {
    const mapExtent: any = userInfo.isPublic
      ? [
        ...fromLonLat([userInfo.minLng, userInfo.minLat]),
        ...fromLonLat([userInfo.maxLng, userInfo.maxLat]),
      ]
      : [...fromLonLat([-180, -90]), ...fromLonLat([180, 90])];

    const popoverRef = 'popup';
    const popoverElement = document.getElementById(popoverRef) as HTMLElement;
    const popover = new Overlay({
      id: 'popup-container',
      element: popoverElement,
      stopEvent: true,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });

    const initMap = new Map({
      target: mapElement.current || '',
      interactions:
        !wideScreenFinder(800) || !tallScreenFinder(500)
          ? defaults({
            dragPan: false,
            mouseWheelZoom: false,
            pinchRotate: false,
          }).extend([
            new DragPan({
              condition(event) {
                return (
                  this.getPointerCount() === 2 ||
                  platformModifierKeyOnly(event)
                );
              },
            }),
            new MouseWheelZoom({
              condition: platformModifierKeyOnly,
            }),
          ])
          : undefined,
      layers: [
        new TileLayer({
          preload: Infinity,
          source: new OSM({
            wrapX: false,
          }),
          zIndex: 0,
        }),
      ],
      overlays: [popover],
      view: new View({
        center: fromLonLat(location),
        zoom: startingZoom || 10,
        extent: mapExtent,
      }),
    });

    initMap.on('singleclick', handleMapClick);

    setMap(initMap);
  }, []);

  const generateTileLayerByOverlayType = (ol: OverlayType): TileLayer => {
    const { name, species, keyword } = ol;
    const species_string = species;
    const osm = new OSM();
    const tileGrid = osm.getTileGrid();
    const auth = getAuth();
    osm.setTileLoadFunction((imageTile, imageSrc) => {
      dynamicTileLoader(
        imageTile,
        imageSrc,
        tileGrid,
        species_string,
        keyword,
        name,
        auth,
      );
    });
    const newMappairLayer = new TileLayer({
      source: osm,
      opacity: mappairOptions.opacity / 100,
      visible: mappairOptions.showOverlayLayer && curOverlayName === ol.name,
      zIndex: 1,
    });
    const sourceLayer = newMappairLayer.getSource();
    sourceLayer.on('change', () => {
      const curOpacity = mappairOptionsRef.current
        ? mappairOptionsRef.current.opacity / 100
        : mappairOptions.opacity / 100;
      newMappairLayer.setOpacity(curOpacity);
    });
    newMappairLayer.setProperties({ name: ol.name });
    return newMappairLayer;
  };

  const generateAATiles = (ol: OverlayType): TileLayer => {
    const { name, species, keyword } = ol;
    const osm = new OSM();
    const tileGrid = osm.getTileGrid();
    const auth = getAuth();
    osm.setTileLoadFunction((imageTile, imageSrc) => {
      dynamicAnnualAveragesTileLoder(
        imageTile,
        imageSrc,
        species,
        keyword,
        tileGrid,
        auth,
        annualAverage,
        name,
      );
    });
    const aaLayer = new TileLayer({
      source: osm,
      visible: mappairOptions.showAALayer && curOverlayName === ol.name,
      zIndex: 0,
      opacity: mappairOptions.opacity / 100,
    });
    return aaLayer;
  };

  const imageWMSLoader = (ol: OverlayType): ImageLayer => {
    const newMappairLayer = new ImageLayer({
      source: new ImageWMS({
        url: `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getMappairOverlayImage}`,
        serverType: 'geoserver',
        ratio: 1,
        params: {
          auth: getAuth(),
          overlay: ol.name,
        },
        imageLoadFunction: (image, src) => overlayLoader(image, src),
      }),
      opacity: mappairOptions.opacity / 100,
      visible: mappairOptions.showOverlayLayer && curOverlayName === ol.name,
      zIndex: 1,
    });
    const imageLayerSource = newMappairLayer.getSource();
    imageLayerSource.on('imageloadstart', () => setMapLoading(true));
    imageLayerSource.on('imageloadend', () => {
      setMapLoading(false);
      const curOpacity = mappairOptionsRef.current
        ? mappairOptionsRef.current.opacity / 100
        : mappairOptions.opacity / 100;
      newMappairLayer.setOpacity(curOpacity);
    });
    newMappairLayer.setProperties({ name: ol.name });
    return newMappairLayer;
  };

  const refreshTilesState = (olArr: any[]) => {
    if (olArr.length > 0 && map) {
      olArr.forEach((ol: TileLayer | ImageLayer) => {
        ol.setZIndex(0);
        map.removeLayer(ol);
      });
    }
  };

  const separateTilings = async (map: Map | null) => {
    if (
      (curTimeSliderOption !== 0 && curTimeSliderOption !== 2) ||
      wmsDateTime
    ) {
      refreshTilesState(mappairOlArr);
      overlays
        .filter((olf) => olf.isMappairOverlay || olf.isSAOverlay)
        .forEach((ol) => {
          const newMappairLayer = !ol.isSAOverlay
            ? generateTileLayerByOverlayType(ol)
            : imageWMSLoader(ol);
          newMappairLayer.setZIndex(0);
          const addedLayerOnMap = map?.addLayer(newMappairLayer);
          mappairOlArr.push(newMappairLayer);
        });
    }
  };

  const separatePPTilings = async (map: Map | null) => {
    if (map && userInfo.isPublic && userInfo.mappair) {
      const user = localStorage.getItem('user');
      if (user) {
        refreshTilesState(mappairOlArr);
        const parser = new WMTSCapabilities();
        const caps: Document = await utilsRequest.getCapabilities();
        const result = parser.read(caps);
        overlays
          .filter((olf) => olf.isMappairOverlay || olf.isSAOverlay)
          .forEach((ol) => {
            if (!ol.isSAOverlay) {
              const newMappairLayer = generatePPTileLayerByOverlayType(
                ol,
                result,
              );
              newMappairLayer.setZIndex(0);
              const addedLayerOnMap = map?.addLayer(newMappairLayer);
              mappairOlArr.push(newMappairLayer);
            }
          });
      }
    }
  };

  const generatePPTileLayerByOverlayType = (ol: OverlayType, result: any) => {
    const { keyword, name: overlay, species, layername } = ol;
    const options = optionsFromCapabilities(result, {
      layer: layername,
    });
    if (options && options.urls) {
      options.urls[0] = `${config.mappairAPI}imageTiled?`;
    }
    const { minLat, maxLat, minLng, maxLng } = userInfo;
    const layerExtent = [
      ...fromLonLat([minLng, minLat]),
      ...fromLonLat([maxLng, maxLat]),
    ];
    const newMappairLayer = new TileLayer({
      source: new WMTS({
        ...options,
        transition: 0,
        tileLoadFunction: (imageTile, src) =>
          PPTileLoader(imageTile, src, species, keyword, overlay, getAuth()),
      }),
      extent: layerExtent as any,
      opacity: mappairOptions.opacity / 100,
      visible: mappairOptions.showOverlayLayer && curOverlayName === overlay,
      zIndex: 1,
    });
    const sourceLayer = newMappairLayer.getSource();
    sourceLayer.on('change', () => {
      const curOpacity = mappairOptionsRef.current
        ? mappairOptionsRef.current.opacity / 100
        : mappairOptions.opacity / 100;
      newMappairLayer.setOpacity(curOpacity);
    });
    newMappairLayer.setProperties({ name: overlay });
    return newMappairLayer;
  };

  const generateAAOverlay = () => {
    const overlayName = curOverlayName.includes('NO2')
      ? 'UK NO2'
      : curOverlayName.includes('PM25')
        ? 'UK PM25'
        : 'UK AQI';
    refreshTilesState(annualAveragesOlArr);
    overlays
      .filter((olf) => olf.isMappairOverlay || olf.isSAOverlay)
      .forEach((ol) => {
        if (!ol.isSAOverlay && ol.name === overlayName) {
          const aaLayer = generateAATiles(ol);
          aaLayer.setProperties({ name: 'aaLayer' });
          aaLayer.setZIndex(0);
          const addedLayerOnMap = map?.addLayer(aaLayer);
          annualAveragesOlArr.push(aaLayer);
        }
      });
  };

  useEffect(() => {
    if (overlays.length && map && mappairOptionsRef.current) {
      // Case for standard WMS layers
      (async () => {
        if (!userInfo.isPublic) await separateTilings(map);
      })();
    }
  }, [map, overlays, curTimeSliderOption, wmsDateTime]);

  useEffect(() => {
    if ((overlays.length && map && mappairOptionsRef.current, curOverlayName)) {
      (async () => {
        // in order to avoid calling separatePPTilings everytime we change current overlay, we can create a state object, holding names of all overlays -> {'UK NO2': false} and change to true if separatePPTilings gets accessed
        if (userInfo.isPublic && overlays[0].name === curOverlayName) {
          await separatePPTilings(map);
        }
      })();
    }
  }, [map, overlays, curOverlayName]);

  useEffect(() => {
    if (overlays.length && map && mappairOptionsRef.current) {
      const satLayer = new TileLayer({
        source: new XYZ({
          url: `${APIPaths.mapboxSatellite}?access_token=${keys.Mapbox}`,
        }),
        visible: mappairOptions.showSatelliteLayer,
        zIndex: 0,
        opacity: 1,
      });
      satLayer.setProperties({ name: 'satLayer' });
      map.addLayer(satLayer);

      // Set AQMA layer (on top)
      const aqmaLayer = new ImageLayer({
        source: new ImageWMS({
          url: `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getMappairAQMAImage}`,
          serverType: 'geoserver',
          ratio: 1,
          params: {
            time: new Date().toISOString(),
            auth: getAuth(),
          },
        }),
        opacity: 0.7,
        visible: mappairOptions.showAQMALayer,
        zIndex: 2,
      });
      aqmaLayer.setProperties({ name: 'aqmaLayer' });
      map.addLayer(aqmaLayer);

      const schoolsLayer = new ImageLayer({
        source: new ImageWMS({
          url: `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getMappairSchoolsImage}`,
          serverType: 'geoserver',
          ratio: 1,
          params: {
            auth: getAuth(),
            time: new Date().toISOString(),
          },
          imageLoadFunction: (image, src) => schoolsOverlayLoader(image, src),
        }),
        opacity: 0.7,
        visible: mappairOptions.showSchoolsLayer,
        zIndex: 3,
      });
      schoolsLayer.setProperties({ name: 'schoolsLayer' });
      map.addLayer(schoolsLayer);

      const smokeControlZones = new ImageLayer({
        source: new ImageWMS({
          url: `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getMappairSchoolsImage}`,
          serverType: 'geoserver',
          ratio: 1,
          params: {
            auth: getAuth(),
            time: new Date().toISOString(),
          },
          imageLoadFunction: (image, src) => smokeControlZonesOverlayLoader(image, src),
        }),
        opacity: 0.7,
        visible: mappairOptions.showSmokeControlZones,
        zIndex: 3,
      });
      smokeControlZones.setProperties({ name: 'smokeControlZonesLayer' });
      map.addLayer(smokeControlZones);
    }
  }, [map, overlays]);

  useEffect(() => {
    if (
      overlays.length &&
      map &&
      mappairOptionsRef.current &&
      mappairOptions.showAALayer &&
      annualAveragesOn
    ) {
      if (!userInfo.isPublic) {
        generateAAOverlay();
      }
    }
  }, [map, overlays, annualAverage, curOverlayName, annualAveragesOn]);

  useEffect(() => {
    if (map && unitList.length) {
      // #region find vZephyr to delete
      // // crashing in markerList component

      // const dataIds = unitList.map((marker) => String(marker.id_pod));

      // const mapIds = map
      //   ?.getOverlays()
      //   .getArray()
      //   .filter((marker) => marker.get('name') === 'marker')
      //   .map((marker) => marker.getId());

      // const mapUnitList = map.getOverlays().getArray();
      // const vZephyrsIds = unitList
      //   .filter((vz) => vz.type === ZephyrTypes.virtual && vz.id_pod)
      //   .map((vz) => String(vz.id_pod));

      // // find vZephyr from map which are not in data
      // const vZephyrsOverlaysToRemoveFromMap = mapUnitList.filter(
      //   (mapUnit: any) =>
      //     // eslint-disable-next-line no-underscore-dangle
      //     mapUnit.values_.type === ZephyrTypes.virtual &&
      //     !vZephyrsIds.includes(mapUnit?.getId()),
      // );

      // remove overlay - deleted vZephyr from Map if not exist in data
      // if (vZephyrsOverlaysToRemoveFromMap.length) {
      //   vZephyrsOverlaysToRemoveFromMap.forEach((overlay) => {
      //     // crashing in markerList component- none of these solotions below are working
      //     map.removeOverlay(overlay);
      //     overlay.setPosition(undefined);
      //     overlay.unset('id');

      //     const target = overlay.getElement();
      //     if (target) target.style.display = 'none';

      //     if (target) {
      //       const parent = target?.parentElement;
      //       if (parent) parent.removeChild(target);
      //     }
      //     const target = document.getElementById('marker') as HTMLElement;
      //     if (target) {
      //       const parent = target?.parentElement;
      //       if (parent) parent.removeChild(target);
      //     }
      //   });
      //   return;
      // }
      // #endregion

      unitList.forEach((unit) => {
        if (unit) {
          const unitId = `${unit.id_pod}-${unit.type}`;
          const unitPosition = fromLonLat([
            parseFloat(unit.longitude),
            parseFloat(unit.latitude),
          ]);
          const overlayExists = map
            .getOverlays()
            .getArray()
            .filter((ol: Overlay) => ol.getId() === unitId);
          const unitElement = document.getElementById(unitId) as HTMLElement;
          if (!overlayExists.length) {
            const newUnitOverlay = new Overlay({
              id: unitId,
              element: unitElement,
              position: unitPosition,
              stopEvent: false,
            });
            newUnitOverlay.setProperties({
              type: unit.type,
              name: 'markerLayer',
              id: unitId,
            });
            map.addOverlay(newUnitOverlay);
          }
        }
      });
    }
  }, [map, unitFilters, unitList]);

  useEffect(() => {
    if (!mappairOptions.showOverlayLayer || !mappairOptions.showAALayer) {
      setPoints(null);
      setAnnualAverages(AnnualAvgDataInitialState);
      setMapAirPointValueHistory(MapAirPointValueHistoryInitialState);
    }
  }, [mappairOptions.showOverlayLayer, mappairOptions.showAALayer]);

  useEffect(() => {
    if (map) {
      if (points && points.coordinates) {
        setPoints({
          ...points,
          colour: '',
          hdRef: null,
          namedLocation: null,
          saValue: null,
          secondaryValue: null,
          value: null,
          valueHistory: null,
        });
      }
      const mapLayers = map.getLayers().getArray();
      mapLayers.forEach((ml: BaseLayer) => {
        const layerProps = ml.getProperties();
        if ('name' in layerProps) {
          const layerName = layerProps.name;
          const visible =
            (curOverlayName === layerName && mappairOptions.showOverlayLayer) ||
            (mappairOptions.showAQMALayer && layerName === 'aqmaLayer') ||
            (mappairOptions.showSatelliteLayer && layerName === 'satLayer') ||
            (mappairOptions.showSchoolsLayer && layerName === 'schoolsLayer') ||
            (mappairOptions.showSmokeControlZones && layerName === 'smokeControlZonesLayer') ||
            (mappairOptions.showAALayer && layerName === 'aaLayer');
          ml.setVisible(visible);
        }
      });
    }
  }, [
    map,
    curOverlayName,
    mappairOptions.overlay,
    mappairOptions.showOverlayLayer,
  ]);

  useEffect(() => {
    if (map) {
      const mapLayers = map.getLayers().getArray();
      mapLayers.forEach((ml: BaseLayer) => {
        const layerProps = ml.getProperties();
        if ('name' in layerProps) {
          const layerName = layerProps.name;
          if (
            layerName === 'aqmaLayer' ||
            layerName === 'satLayer' ||
            layerName === 'schoolsLayer' ||
            layerName === 'smokeControlZonesLayer' ||
            layerName === 'aaLayer'
          ) {
            const visible =
              (mappairOptions.showAQMALayer && layerName === 'aqmaLayer') ||
              (mappairOptions.showSchoolsLayer && layerName === 'schoolsLayer') ||
              (mappairOptions.showSmokeControlZones && layerName === 'smokeControlZonesLayer') ||
              (mappairOptions.showAALayer && layerName === 'aaLayer') ||
              (mappairOptions.showSatelliteLayer && layerName === 'satLayer');
            ml.setVisible(visible);
          }
        }
      });
    }
  }, [
    map,
    mappairOptions.showAQMALayer,
    mappairOptions.showSatelliteLayer,
    mappairOptions.showSchoolsLayer,
    mappairOptions.showSmokeControlZones,
    mappairOptions.showAALayer,
  ]);

  useEffect(() => {
    if (map) {
      const mapLayers = map.getLayers().getArray();
      mapLayers.forEach((ml: BaseLayer) => {
        const layerProps = ml.getProperties();
        if ('name' in layerProps) {
          const layerName = layerProps.name;
          if (
            layerName !== 'aqmaLayer' &&
            layerName !== 'satLayer' &&
            layerName !== 'schoolsLayer' &&
            layerName !== 'smokeControlZonesLayer'
          ) {
            const updatedOpacity = mappairOptions.opacity / 100;
            ml.setOpacity(updatedOpacity);
          }
        }
      });
    }
  }, [map, mappairOptions.opacity]);

  useEffect(() => {
    if (map) {
      if (points && points.value) {
        const colour = determineColour(points.value);
        setPoints({ ...points, colour });
      }
      const mapLayers = map.getLayers().getArray();
      mapLayers.forEach((ml: any) => {
        if (ml instanceof LayerGroup) {
          // Todo: Sure this won't work as expected for tiling
          // const subMapLayers = ml.getLayers()
          // subMapLayers.forEach((mnl: Layer) => mnl.getSource().refresh());
        } else if (ml.getSource()) {
          const layerProps = ml.getProperties();
          if ('name' in layerProps) {
            const layerName = layerProps.name;
            if (
              layerName !== 'aqmaLayer' &&
              layerName !== 'satLayer' &&
              layerName !== 'schoolsLayer' &&
              layerName !== 'smokeControlZonesLayer'
            ) {
              ml.getSource().refresh();
            }
          }
        }
      });
    }
  }, [threshold]);

  useEffect(() => {
    if (!map) return;
    const updatedCenter = fromLonLat(location);
    const view = map.getView();
    view.animate({
      center: updatedCenter,
      zoom: Array.isArray(curZephyr) ? undefined : 15,
      duration: 2000,
    });
  }, [map, location]);

  // used for mapair model data
  const getMapAirModelData = async (map: Map, pointsParam: PointValues) => {
    const lat = pointsParam.coordinates[0];
    const lon = pointsParam.coordinates[1];
    // Get value in request
    const curOverlay = overlays.filter((ol) => ol.name === curOverlayName)[0];
    const curMappairOverlay =
      curOverlay?.species === 'aqi' ? 'aqi_uk' : curOverlay?.species;
    const startDate = dateFinder(new Date(), 30, true).toISOString();
    const endDate = dateFinder(new Date(), 3, false).toISOString();
    const dates = { startDT: startDate, endDT: endDate };
    const speciesRef = pointsParam.hdRef || curMappairOverlay;
    const valueHistory =
      (await utilsRequest.getTodayData(
        `${curOverlay?.isSAOverlay ? '' : `${speciesRef},`}windspeed,winddir`,
        [lon, lat],
        8,
        bearerToken,
        dates,
      )) || [];
    const saValue = null;
    // if (
    //  // Horrible hardcoding
    //  curOverlay?.name === 'UK PM25' ||
    //  curOverlay?.isSAOverlay
    // ) {
    //  const mapSize = map.getSize();
    //  if (mapSize) {
    //    saValue = await utilsRequest.getSAAtPoint(
    //      pointsParam.coordinates as any,
    //      map.getView().calculateExtent(mapSize),
    //      mapSize[0],
    //      mapSize[1],
    //      lon,
    //      lat,
    //    );
    //  }
    // }

    return { saValue, valueHistory };
  };

  // used for popover
  useEffect(() => {
    let ignore = false;
    if (
      map &&
      points &&
      points.value === null &&
      (mappairOptions.showOverlayLayer || mappairOptions.showAALayer)
    ) {
      const handleMapClickRequest = async () => {
        const lat = points.coordinates[0];
        const lon = points.coordinates[1];
        const user = localStorage.getItem('user');
        // Get value in request
        const curOverlay = overlays.filter(
          (ol) => ol.name === curOverlayName,
        )[0];
        const curMappairOverlay =
          curOverlay?.species === 'aqi' ? 'aqi_uk' : curOverlay?.species;
        // TODO: All of this should be handled by backend
        let hdValue = null;
        let hdRef = null;
        let pointValue = null;
        let secondaryPointValue = null;
        let namedLocation = null;
        const availableHighDefLayers = highDefinitionPointsGroup.filter(
          (hdm) =>
            hdm.users.includes(user as string) &&
            hdm.layers.filter((hdmo) => hdmo.curOverlay === curOverlay?.name)
              .length,
        );
        if (availableHighDefLayers.length) {
          const highDefOverlayConfig = availableHighDefLayers[0].layers.filter(
            (ahdl) =>
              ahdl.curOverlay === curOverlay?.name &&
              lat >= ahdl.extent[0][0] &&
              lat <= ahdl.extent[1][0] &&
              lon >= ahdl.extent[0][1] &&
              lon <= ahdl.extent[1][1],
          );
          if (highDefOverlayConfig.length) {
            const highDefConfig = highDefOverlayConfig[0];
            const hdPointvalue = await utilsRequest.getTodayData(
              highDefConfig.highDefRef,
              [lon, lat],
              3,
              bearerToken,
            );
            hdValue = hdPointvalue
              ? hdPointvalue.results[0].values_coordinates[0]
                .values_timestamps[0].value
              : -999;

            if (!pointValue) {
              pointValue = hdPointvalue;
              if (hdValue >= 0) {
                hdRef = highDefConfig.highDefRef;
              }
            }
          }
        }

        if (!hdValue) {
          const mapSize = map.getSize();
          const { keyword } = curOverlay;
          if (curOverlay?.isSAOverlay && mapSize) {
            pointValue = await utilsRequest.getValueAtPoint(
              points.pixels as any,
              curOverlayName,
              map.getView().calculateExtent(mapSize) as any,
              mapSize[0],
              mapSize[1],
              wmsDateTime
                ? wmsDateTime.toISOString()
                : new Date().toISOString(),
              crs,
              bearerToken,
            );
          } else {
            pointValue = await utilsRequest.getTodayData(
              curMappairOverlay,
              [lon, lat],
              3,
              bearerToken,
              { startDT: wmsDateTime || '', endDT: wmsDateTime || '' },
              mappairOptions.showAALayer ? `ukaa${annualAverage}` : keyword,
            );
            const date = wmsDateTime ? new Date(wmsDateTime) : null;
            updatePointTime(date, pointValue);
          }
        }

        const value =
          hdValue ||
          (pointValue && pointValue.value !== 'NA'
            ? curOverlay?.isSAOverlay
              ? pointValue.value.toFixed(4)
              : Math.round(
                pointValue.results[0].values_coordinates[0]
                  .values_timestamps[0].value,
              )
            : -999);

        if (userInfo.isPublic) {
          const namedLocationValue = await utilsRequest.getLocation([lon, lat]);
          namedLocation =
            namedLocationValue.results && namedLocationValue.results.length
              ? namedLocationValue.results
              : null;

          // get the low-def aqi value for advice/behaviour
          if (
            userInfo.todaySpecies === 'aqi_uk' &&
            curOverlay?.species !== 'aqi'
          ) {
            secondaryPointValue = await utilsRequest.getTodayData(
              'aqi_uk',
              [lon, lat],
              3,
              bearerToken,
            );
          } else {
            secondaryPointValue = pointValue;
          }
        }
        const secondaryValue = secondaryPointValue
          ? Math.round(
            secondaryPointValue.results[0].values_coordinates[0]
              .values_timestamps[0].value,
          )
          : -999;

        // add HTML NO2 Advice
        // const no2Values =
        //  pointValue.results[0]?.values_coordinates[0]?.values_timestamps;

        const colour = determineColour(value);
        if (!ignore) {
          const popover = map
            .getOverlays()
            .getArray()
            .filter((ol: any) => ol.getId() === 'popup-container')[0];
          popover.setPosition(points.position);

          const saValue = null;
          const valueHistory = null;

          setPoints({
            ...points,
            colour,
            hdRef,
            namedLocation,
            secondaryValue,
            value,
            saValue: saValue || null,
            valueHistory: valueHistory || pointValue,
          });
        }
      };
      handleMapClickRequest();
    }
    // Cancel stale requests
    return () => {
      ignore = true;
    };
  }, [points]);

  const updatePointTime = (date: Date | null, pointValue: any) => {
    if (date) {
      const properTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      const { meta, results } = pointValue;
      const properTimeStampsDate = meta?.timestamps[0].split(' ')[0];
      const finalDate = [properTimeStampsDate, properTime].join(' ');
      meta.timestamps = [finalDate];
      results[0].values_coordinates[0].values_timestamps[0].timestamp = finalDate;
    }
  };

  useEffect(() => {
    if (
      map &&
      points &&
      points.value === null &&
      (mappairOptions.showOverlayLayer || mappairOptions.showAALayer)
    ) {
      const handleMapClickGetMapAirPointValueHistory = async () => {
        if (!userInfo.isPublic && points !== null && !cancelPopup) {
          setMapAirPointValueHistory({
            ...mapAirPointValueHistory,
            status: 'loading',
          });

          const historyData = await getMapAirModelData(map, points);

          if (historyData.valueHistory === null) {
            setMapAirPointValueHistory({
              ...historyData,
              status: 'failed',
            });
          } else {
            setMapAirPointValueHistory({
              ...historyData,
              status: 'succeeded',
            });
          }
        }
      };

      handleMapClickGetMapAirPointValueHistory();
    }

    return () => {
      // setMapAirPointValueHistory(MapAirPointValueHistoryInitialState);
    };
  }, [points]);

  // used for AnnualAvg
  useEffect(() => {
    if (
      map &&
      points &&
      points.value === null &&
      (mappairOptions.showOverlayLayer || mappairOptions.showAALayer)
    ) {
      const handleMapClickGetAnnualAvg = async () => {
        const endDateObj = new Date(new Date().getFullYear(), 0, 1);
        endDateObj.setFullYear(endDateObj.getFullYear() - 1);
        const enddt = endDateObj.toISOString().slice(0, -5);

        const startDateObj = new Date(new Date().getFullYear(), 0, 1);
        startDateObj.setFullYear(endDateObj.getFullYear() - numberYrsHistory);
        const startdt = startDateObj.toISOString().slice(0, -5);

        const annualAvgRawData = await utilsRequest.getAnnualAvgAtCoords(
          points.coordinates[1],
          points.coordinates[0],
          startdt,
          enddt,
          '1y',
          bearerToken,
          'no2,pm2p5',
          'aa',
        );

        let selectedOverlay = 'no2';
        if (curOverlayName.includes('PM25')) {
          selectedOverlay = 'pm2p5';
        }

        if (annualAvgRawData.results) {
          let speciesWithData = annualAvgRawData.results.find(
            (res: any) =>
              res.species === selectedOverlay ||
              (res.species === selectedOverlay &&
                res.values_coordinates[0].values_timestamps[0].find(
                  (val: any) => val.value >= 0,
                )),
          );

          const {
            latlon,
            values_timestamps,
          } = speciesWithData.values_coordinates[0];
          speciesWithData = {
            species: speciesWithData.species,
            units: values_timestamps[0].units,
            latlon,
            values_timestamps,
          };
          const curSpecies = mappairSpeciesFinder(
            curOverlayName,
            displayConfig,
            overlays,
          );

          const annualAvgChartData = pointDataPackager(
            annualAvgRawData,
            curSpecies,
            true,
          );

          setAnnualAverages({
            list: speciesWithData || null,
            raw: annualAvgRawData || null,
            chart: annualAvgChartData || { labels: [], datasets: [] },
          });
        } else {
          setAnnualAverages(AnnualAvgDataInitialState);
        }
      };

      handleMapClickGetAnnualAvg();
    }

    return () => {
      // cannot clean up due to data tab needs this data
      // setAnnualAverages(AnnualAvgDataInitialState);
    };
  }, [points, numberYrsHistory]);

  useEffect(() => {
    if (map && tourClick) {
      const coordinate = map.getView().getCenter();
      const pixel = map.getPixelFromCoordinate(coordinate as any);
      const evt = {
        type: 'singleclick',
        coordinate,
        pixel,
      };
      map.dispatchEvent(evt as any);
    }
  }, [map, tourClick]);

  // Functions
  // https://service.earthsense.co.uk/mappair/api/image?species=no2&keyword=uk&xmin=-0.58&ymin=51.76&xmax=0.67&ymax=52.88

  const removeOverlay = (overlayID: string) => {
    const popover = (mapRef.current as Map)
      .getOverlays()
      .getArray()
      .filter((ol: any) => ol.getId() === overlayID)[0];
    popover.setPosition(undefined);
  };

  const closePopup = () => {
    removeOverlay('popup-container');
    setCancelPopup(true);
  };

  const determineColour = (value: number) => {
    const defaultColour = 'rgba(255, 255, 255, 1)';
    const colour =
      value >= 0
        ? colourFinder(
          overlays.filter((ol) => ol.name === curOverlayName)[0],
          stylegroups,
          threshold,
          1,
          overlays,
          value,
          'mappair',
        )
        : defaultColour;

    return colour || defaultColour;
  };

  const getLayerCuts = (tiledGroup: TilingGroup) => {
    const mapToMultiPolygon = (coordinateSets: any) => {
      const multiPolygonCoordinates: any[] = [];
      coordinateSets.forEach((cs: any) => {
        const [minLon, minLat] = cs[0];
        const [maxLon, maxLat] = cs[1];
        const polygonCoordinate = [
          fromLonLat([minLon, minLat]),
          fromLonLat([maxLon, minLat]),
          fromLonLat([maxLon, maxLat]),
          fromLonLat([minLon, maxLat]),
          fromLonLat([minLon, minLat]),
        ];
        multiPolygonCoordinates.push(polygonCoordinate);
      });
      return [multiPolygonCoordinates];
    };

    const layerCuts: any = {};
    const mappedLevels: any = {};
    tiledGroup.layers.forEach((l: TilingLayer) => {
      if (mappedLevels[l.level]) {
        mappedLevels[l.level].push(l);
      } else {
        mappedLevels[l.level] = [l];
      }
    });
    for (const level in mappedLevels) {
      // Only handle for one layer above for now
      const nextLevel = parseInt(level) + 1;
      if (mappedLevels[nextLevel]) {
        const featuresWithExtent = mappedLevels[nextLevel].filter(
          (lf: any) => lf.extent,
        );
        if (featuresWithExtent.length) {
          const featuresToExclude = featuresWithExtent.map(
            (fwe: any) => fwe.extent,
          );
          const multiPolygonCoordinates = mapToMultiPolygon(featuresToExclude);
          mappedLevels[level].forEach((l: any) => {
            layerCuts[l.name] = multiPolygonCoordinates;
          });
        }
      }
    }
    return layerCuts;
  };

  const handleLocationSearch = async (locationSearch: string) => {
    if (userInfo.isPublic) {
      const { minLat, minLng, maxLat, maxLng } = userInfo;
      const extent: any = [
        ...fromLonLat([minLng, minLat]),
        ...fromLonLat([maxLng, maxLat]),
      ];
      const searchBias = `${minLat}, ${minLng}|${maxLat}, ${maxLng}`;
      const newLocation = await checkLocation(locationSearch, searchBias);
      const [lng, lat] = fromLonLat([newLocation.lng, newLocation.lat]);
      const inBounds = containsXY(extent, lng, lat);
      if (inBounds) {
        setLocation(locationSearch, searchBias);
      } else {
        return false;
      }
    } else {
      setLocation(locationSearch, 'null');
    }
    return true;
  };

  const handleMapClick = (evt: MapBrowserEvent) => {
    removeOverlay('popup-container');
    if (
      mappairOptionsRef.current &&
      (mappairOptionsRef.current.showOverlayLayer ||
        mappairOptionsRef.current.showAALayer) &&
      !cancelPopupRef.current
    ) {
      const coordinates = toLonLat(evt.coordinate);
      const hdms = toStringHDMS(coordinates);
      setPoints({
        coordinates: { 0: coordinates[0], 1: coordinates[1] },
        colour: '',
        hdms,
        hdRef: null,
        namedLocation: null,
        pixels: evt.pixel,
        position: evt.coordinate,
        saValue: null,
        secondaryValue: null,
        value: null,
        valueHistory: null,
      });
    }
    if (cancelPopupRef.current) {
      setPoints(null);
      setCancelPopup(false);
    }
  };

  const tileLoader = (
    imageTile: any,
    src: string,
    shroplayer: string,
    auth: string,
    overlay: string,
  ) => {
    imageTile.getImage().src = `${src}&stylegroup=${thresholdRef.current
      }&shroplayer=${shroplayer}&auth=${auth}&overlay=${overlay}&time=${new Date().toISOString()}`;
  };

  const PPTileLoader = async (
    imageTile: any,
    src: string,
    species: string,
    keyword: string,
    overlay: string,
    auth: string,
  ) => {
    imageTile.getImage().src = `${src}&keyword=${keyword}&stylegroup=${thresholdRef.current
      }&species=${species}&auth=${auth}&overlay=${overlay}&time=${new Date().toISOString()}`;
  };

  const dynamicTileLoader = async (
    imageTile: any,
    src: string,
    tileGrid: TileGrid,
    species: string,
    keyword: string,
    name: string,
    auth: string,
  ) => {
    const { tileCoord } = imageTile;
    const tileCoordExtent = tileGrid.getTileCoordExtent(tileCoord);
    const minMaxXY = tileCoordToLonLat(tileCoordExtent);
    const { xMin, yMin, xMax, yMax } = minMaxXY;
    const { width, height } = dimensions;
    const response = await axios({
      method: 'get',
      url: `${config.mappairAPI
        }image?species=${species}&keyword=${keyword}&xmin=${xMin}&ymin=${yMin}&xmax=${xMax}&ymax=${yMax}&time=${wmsDateTime || new Date().toISOString()
        }&width=${width}&height=${height}&overlay=${name}&stylegroup=${thresholdRef.current
        }`,
      data: {},
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    imageTile.getImage().src = window.URL.createObjectURL(response.data);
  };

  const dynamicAnnualAveragesTileLoder = (
    imageTile: any,
    src: string,
    species: string,
    keyword: string,
    tileGrid: TileGrid,
    auth: string,
    annualAverage: number | null,
    name: string,
  ) => {
    const dynamicSpecies =
      curOverlayName === 'Zephyr NO2'
        ? 'no2'
        : curOverlayName === 'Zephyr PM25'
          ? 'pm2p5'
          : 'no2';
    const { tileCoord } = imageTile;
    const tileCoordExtent = tileGrid.getTileCoordExtent(tileCoord);
    const minMaxXY = tileCoordToLonLat(tileCoordExtent);
    const { xMin, yMin, xMax, yMax } = minMaxXY;
    const { width, height } = dimensions;
    imageTile.getImage().src = `${config.mappairAPI}image?species=${dynamicSpecies}&keyword=ukaa${annualAverage}&xmin=${xMin}&ymin=${yMin}&xmax=${xMax}&ymax=${yMax}&width=${width}&height=${height}&overlay=${name}&stylegroup=${thresholdRef.current}&auth=${auth}`;
  };

  const tileCoordToLonLat = (tileCoordExtent: any): LonLatXY => {
    const minMaxArr = [
      ...toLonLat([tileCoordExtent[0], tileCoordExtent[1]]),
      ...toLonLat([tileCoordExtent[2], tileCoordExtent[3]]),
    ];
    const xMin = minMaxArr[0];
    const yMin = minMaxArr[1];
    const xMax = minMaxArr[2];
    const yMax = minMaxArr[3];

    return {
      xMin,
      yMin,
      xMax,
      yMax,
    };
  };

  const overlayLoader = (image: any, src: string) => {
    image.getImage().src = `${src}&stylegroup=${thresholdRef.current
      }&time=${new Date().toISOString()}`;
  };

  const schoolsOverlayLoader = (image: any, src: string) => {
    if (map) {
      const size = map.getSize();
      const bbox = map.getView().calculateExtent(size);
      const schoolsKeyword = 'schools';
      image.getImage().src = `${src}&CRS=EPSG:3857&WIDTH=${size![0]}&HEIGHT=${size![1]
        }&BBOX=${bbox[0]},${bbox[1]},${bbox[2]},${bbox[3]
        }&keyword=${schoolsKeyword}&transparent=${true}`;
    }
  };

  const smokeControlZonesOverlayLoader = (image: any, src: string) => {
    if (map) {
      const size = map.getSize();
      const bbox = map.getView().calculateExtent(size);
      const smokeControlZonesKeyword = 'sczs';
      image.getImage().src = `${src}&CRS=EPSG:3857&WIDTH=${size![0]}&HEIGHT=${size![1]
        }&BBOX=${bbox[0]},${bbox[1]},${bbox[2]},${bbox[3]
        }&keyword=${smokeControlZonesKeyword}&transparent=${true}`;
    }
  };

  const zoom = (increase: boolean) => {
    if (map) {
      const mapView = map.getView();
      let currentZoomLevel = mapView.getZoom();
      if (currentZoomLevel) {
        increase ? (currentZoomLevel += 1) : (currentZoomLevel -= 1);
        mapView.setZoom(currentZoomLevel);
      }
    }
  };

  return (
    <div className="map-container" data-user-tour="step-7">
      <Spinner additionalClass="see-through" on={mapLoading} />
      <div ref={mapElement} id="map" className={`${userInfo.isPublic && 'map-public'}`} />
      <LocationMarkerList
        curOverlayName={curOverlayName}
        curZephyr={curZephyr}
        overlays={overlays}
        setCancelPopup={setCancelPopup}
        stylegroups={stylegroups}
        threshold={threshold}
        unitFilters={unitFilters}
        unitList={unitList}
        points={points}
      />
      <LocationPopover
        closePopup={closePopup}
        displayConfig={displayConfig}
        mappairOptions={mappairOptions}
        overlay={overlays.filter((ol) => ol.name === curOverlayName)[0]}
        points={points}
        userInfo={userInfo}
        setZephyrModalOpen={setZephyrModalOpen}
        vZephyrsConfig={vZephyrsConfig}
      />
      <LocationLicensing
        asideOn={asideOn}
        curOverlayName={curOverlayName}
        mappairOptions={mappairOptions}
        overlays={overlays}
        userInfo={userInfo}
      />
      <LocationControls
        asideOn={asideOn}
        handleLocationSearch={handleLocationSearch}
        showLocationSearch
        userInfo={userInfo}
        zoom={zoom}
        curTimeSliderOption={curTimeSliderOption}
        setCurTimeSliderOption={setCurTimeSliderOption}
        wmsDateTime={wmsDateTime}
        setWMSDateTime={setWMSDateTime}
        curOverlayName={curOverlayName}
      />
      <AQIIndex
        curOverlayName={curOverlayName}
        displayConfig={displayConfig}
        loading={loading}
        mappairOptions={mappairOptions}
        overlays={overlays}
        points={points}
        threshold={threshold}
        stylegroups={stylegroups}
        userInfo={userInfo}
        annualAvgData={annualAvgData}
      />
      <LatestMeasurementsPublic
        asideOn={asideOn}
        cancelPopup={cancelPopup}
        curOverlayName={curOverlayName}
        curZephyr={curZephyr}
        displayConfig={displayConfig}
        mappairOptions={mappairOptions}
        overlays={overlays}
        points={points}
        userInfo={userInfo}
      />
      <Modal
        on={zephyrModalOpen}
        modalComponent={
          <AddZephyrModal
            setZephyrModalOpen={setZephyrModalOpen}
            closePopup={closePopup}
            points={points}
            unitList={unitList}
            getUserVirtualZephyrs={getUserVirtualZephyrs}
            vZephyrsConfig={vZephyrsConfig}
          />
        }
      />
      <Modal
        on={
          isModalOpened &&
          sessionStorage.getItem('locationRefreshed') === 'false'
        }
        modalComponent={
          <Banner
            setIsModalOpened={setIsModalOpened}
            bannerValue={bannerValue}
          />
        }
      />
    </div>
  );
};

// Redux
const mapStateToProps = (state: ReduxState, ownProps: any) => ({
  asideOn: state.aside.on,
  curOverlayName: state.showAQMALayer.overlay,
  curZephyr: state.setZephyr.zephyr,
  displayConfig: state.setDisplayConfig,
  loading: state.loading,
  location: state.setLocation.location,
  mappairOptions: state.showAQMALayer,
  overlays: state.setOverlays,
  startingZoom: state.auth.userInfo.startingZoom,
  stylegroups: state.getStyleGroups.styleGroups,
  threshold: state.setThresholdTab.tab,
  tourClick: state.tour.clickMap,
  unitFilters: state.mappAirFilters.selectedFilters,
  unitList: state.getZephyrs.zephyrs,
  userInfo: state.auth.userInfo,
  annualAvgData: state.setAnnualAverages,
  mapAirPointValueHistory: state.setMapAirPointValueHistory,
  isBannerShowing: ownProps.isBannerShowing,
  isModalOpened: ownProps.isModalOpened,
  setIsModalOpened: ownProps.setIsModalOpened,
  bannerValue: ownProps.bannerValue,
  startDate: ownProps.startDate,
  endDate: ownProps.endDate,
  annualAverage: state.showAQMALayer.annualAverage,
  curOpacity: state.showAQMALayer.opacity,
  bearerToken: state.auth.bearerToken,
  annualAveragesOn: state.showAQMALayer.showAALayer,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      mapsLocationLoaded,
      setLocation,
      setAnnualAverages,
      setMapAirPointValueHistory,
      setAnnualAverage,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Location);
