// Package Imports
import React from 'react';

// Type Imports
import { Zephyr, ZephyrTypes } from '../../utils/interface';
import { Circle, Square, Triangle } from './Markers';
import styled from 'styled-components';

// Types
interface LocationMarkerProps {
  isActive: boolean;
  isVisible: boolean;
  markerColour: string;
  selectUnit: Function;
  showLabel: boolean;
  unit: Zephyr;
}
interface MarkerProps {
  isActive: boolean;
  markerColour: string;
  showLabel: boolean;
  unit: Zephyr;
}

// issue with react fragment, needed to use a normal html element as wrapper(div)

const MarkerType = ({
  unit,
  markerColour,
  isActive,
}: MarkerProps) => {
  if (unit.type === ZephyrTypes.virtual) {
    return <Square color={markerColour} isActive={isActive} />;
  }
  if (unit.type === ZephyrTypes.aurns) {
    return <Circle color={markerColour} isActive={isActive} />;
  }
  if (unit.type === ZephyrTypes.zephyr) {
    return <Triangle color={markerColour} isActive={isActive} />;
  }

  return (
    <div>
      <div
        className={`marker type-${unit.type} ${isActive ? 'active' : ''}`}
        style={{ background: markerColour }}
      >
        {unit.type === 1 ? (
          <div
            className="triangle-container"
            style={{ borderBottomColor: markerColour }}
          />
        ) : null}
      </div>
    </div>
  );
};

const Tooltip = styled.div`
  display: none;
  position: absolute;
  bottom: -46px;
  border-radius: 8px;
  background-color: white;
  padding: 6px;
  font-size: 14px;
  border: 1px solid lightgray;
  width: max-content;
`;

const LocationMarkerWrapper = styled.div`
  position: relative;
  
  &:hover ${Tooltip} {
    display: block;
  }
`;

const LocationMarker = ({
  isActive,
  isVisible,
  markerColour,
  selectUnit,
  showLabel,
  unit,
}: LocationMarkerProps) => (
  <div>
    <LocationMarkerWrapper
      className={`marker-container ${isVisible ? 'marker-visible' : 'marker-invisible'
        }`}
      id={`${unit.id_pod}-${unit.type}`}
      onClick={() => selectUnit(unit)}
    >
      <MarkerType
        unit={unit}
        markerColour={markerColour}
        showLabel={showLabel}
        isActive={isActive}
      />

      <Tooltip style={{
        display: isActive ? 'block' : undefined
      }}
      >
        <p className="m-0">{unit.name ?? "-"}</p>
      </Tooltip>
    </LocationMarkerWrapper>
  </div>
);

export default LocationMarker;
