// Package Imports
import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useHistory, useLocation, withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { i18n } from '../../translations/i18n';
// Component Imports
import AirQuality101 from '../view_components/AirQuality101';
import Aside, { InitStateFilters } from './Aside';
import Dropdown from './Dropdown';
import Feedback from '../view_components/Feedback';
import HeaderContactModal from '../view_components/HeaderContactModal';
import HeaderTutorialModal from '../view_components/HeaderTutorialModal';
import HeaderVersionHistoryModal from '../view_components/HeaderVersionHistoryModal';
import MappairQuoteModal from '../view_components/MappairQuoteModal';
import Modal from './Modal';
import Slider from './Slider';
import Spinner from './Spinner';
import Tooltip from './Tooltip';
import RemoveZephyrModal from '../view_components/RemoveZephyrModal';

// Action Imports
import { logout, setVersions } from '../../actions/auth';
import { changeOverlayLayer, changeOverlayOpacity } from '../../actions/layers';
import { startTour } from '../../actions/tour';
import { setThresholdTab } from '../../actions/stylegroups';
import { setAQ101Off, setAQ101On } from '../../actions/aq101';
import {
  setContactModalOn,
  setMappairQuoteModalOn,
  setModalsOff,
} from '../../actions/modal';
import setLocale from '../../actions/setLocale';

// Util Imports
import { dateFinder, todayHeaderFormatter } from '../../utils/functions/dateFinder';
import analyticsEventFirer from '../../utils/functions/analyticsEventFirer';
import { wideScreenFinder } from '../../utils/functions/wideScreenFinder';
import { tallScreenFinder } from '../../utils/functions/tallScreenFinder';
import utilsRequest from '../../utils/request';

// Const Imports
import {
  defaultContactForm,
  defaultMappairForm,
  gtmEventIdentifiers,
  helpResourceLink,
  privacyPolicyLink,
  routePaths,
  defaultVZephyrsConfig,
  translationOptions,
  staticPollutantsList,
} from '../../utils/consts';

// Asset Imports
import esLogoMain from '../../assets/images/logo-white-text.png';
import esLogoBeta from '../../assets/images/logo-white-text-beta.png';

// Type Imports
import {
  AQ101,
  ContactForm,
  Loading,
  Modal as ModalType,
  Overlay,
  ReduxState,
  Stylegroup,
  UserInfo,
  Zephyr,
  VZephyrsConfig,
  translationOption,
  ShowAQMALayer,
  TimePeriod,
  AveragingPeriod,
  Pollutant,
} from '../../utils/interface';
import { ModularFeatures } from '../../reducers/modularFeatures';
import AnalyticsViewModal from '../view_components/AnalyticsViewModal';
import { setUnitHistoriesList } from '../../actions/dataAnalytics';
import AnalyticsFeedbackForm from '../view_components/AnalyticsFeedbackForm';
import FilterBar1 from '../view_components/FilterBar/FilterBar1';

let esLogo = esLogoMain;
if (process.env.REACT_APP_SERVER === 'beta') esLogo = esLogoBeta;

// Component Interfaces
interface InitState {
  accountMenuOpen: boolean;
  contactFormOpen: boolean;
  contactFormOptions: ContactForm;
  helpMenuOpen: boolean;
  mappairQuoteFormOpen: boolean;
  tutorialOpen: boolean;
  versionHistoryOpen: boolean;
  vZephyrsConfig: VZephyrsConfig;
  selectedVZephyrToDelete: Zephyr | null;
  isSaveViewOpened: boolean;
  isLoadViewOpened: boolean;
  feedbackModalOn: boolean;
}

interface HeaderProps {
  activeStylegroup: string;
  aq101: AQ101;
  changeOverlayLayer: Function;
  changeOverlayOpacity: Function;
  loading: Loading;
  logout: Function;
  mappAirFilters: boolean;
  modal: ModalType;
  opacity: number;
  overlay: string;
  overlays: Overlay[];
  setAQ101Off: Function;
  setAQ101On: Function;
  setContactModalOn: Function;
  setMappairQuoteModalOn: Function;
  setModalsOff: Function;
  setThresholdTab: Function;
  setZephyr: Zephyr;
  curZephyr: Zephyr;
  startTour: Function;
  stylegroups: Stylegroup[];
  tourOpen: boolean;
  tourOpenHelp: boolean;
  userInfo: UserInfo;
  zephyrList: Zephyr[];
  locale: String;
  setLocale: Function;
  setVersions: Function;
  vZephyrsConfig: VZephyrsConfig;
  annualAveragesOn: boolean;
  modularFeatures: ModularFeatures;
  bearerToken: string | null;
  mappairOptions: ShowAQMALayer;
  unitHistories: any;
  unitHistoriesLoading: boolean;
  setUnitHistoriesList: Function;
}

// Component
const Header = ({
  activeStylegroup,
  aq101,
  curZephyr,
  changeOverlayLayer,
  changeOverlayOpacity,
  logout,
  loading,
  mappAirFilters,
  modal,
  opacity,
  overlay,
  overlays,
  setAQ101Off,
  setAQ101On,
  setContactModalOn,
  setMappairQuoteModalOn,
  setModalsOff,
  setThresholdTab,
  setZephyr,
  startTour,
  stylegroups,
  tourOpen,
  tourOpenHelp,
  userInfo,
  zephyrList,
  locale,
  setLocale,
  setVersions,
  vZephyrsConfig,
  annualAveragesOn,
  modularFeatures,
  bearerToken,
  mappairOptions,
  unitHistories,
  unitHistoriesLoading,
  setUnitHistoriesList,
}: HeaderProps) => {
  const { t: translate } = useTranslation();

  // State
  const initState: InitState = {
    accountMenuOpen: false,
    contactFormOpen: false,
    contactFormOptions: defaultContactForm,
    helpMenuOpen: false,
    mappairQuoteFormOpen: false,
    tutorialOpen: false,
    versionHistoryOpen: false,
    vZephyrsConfig: defaultVZephyrsConfig,
    selectedVZephyrToDelete: null,
    isSaveViewOpened: false,
    isLoadViewOpened: false,
    feedbackModalOn: false,
  };

  const [accountMenuOpen, setAccountMenuOpen] = useState(
    initState.accountMenuOpen,
  );
  const [contactFormOptions, setContactFormOptions] = useState(
    initState.contactFormOptions,
  );
  const [helpMenuOpen, setHelpMenuOpen] = useState(initState.helpMenuOpen);
  const [tutorialOpen, setTutorialOpen] = useState(initState.tutorialOpen);
  const [versionHistoryOpen, setVersionHistoryOpen] = useState(
    initState.versionHistoryOpen,
  );
  const [selectedVZephyrToDelete, setSelectedVZephyrToDelete] = useState(
    initState.selectedVZephyrToDelete,
  );
  const [currentPollutant, setCurrentPollutant] = useState('');
  const [alertDropdownOpened, setAlertsDropdownOpened] = useState(false);
  const [isSaveViewOpened, setIsSaveViewOpened] = useState(
    initState.isSaveViewOpened,
  );
  const [isLoadViewOpened, setIsLoadViewOpened] = useState(
    initState.isLoadViewOpened,
  );
  const [feedbackModalOn, setFeedbackModalOn] = useState(
    initState.feedbackModalOn,
  );
  const [
    selectedTimePeriod,
    setSelectedTimePeriod,
  ] = useState<TimePeriod | null>(null);
  const [zephyrsPollutantData, setZephyrsPollutantData] = useState<any>({
    filteredZephyrs: InitStateFilters.filteredZephyrs,
    pollutantData: staticPollutantsList,
  });
  const [
    selectedTimeAveraging,
    setSelectedTimeAveraging,
  ] = useState<AveragingPeriod | null>(null);
  const [isViewNameValidated, setIsViewNameValidated] = useState<boolean>(false);
  const [views, setViews] = useState<any>([]);
  const [viewLoaded, setViewLoaded] = useState<boolean>(false);
  const [customDate, setCustomDate] = useState({
    start: dateFinder(new Date(), 1, true),
    end: new Date(),
  });
  const [startDate, setStartDate] = useState(dateFinder(new Date(), 1, true));
  const [endDate, setEndDate] = useState(new Date());
  const [initialRenderSet, setInitialRenderSet] = useState(false);
  const hasRunInitalRenderEffect = useRef(false);

  useEffect(() => {
    if (overlay) {
      const prevOverlay = overlays.filter((ol) => ol.name === overlay)[0];
      const newOverlay = overlays.filter(
        (ol) =>
          ol.name !== prevOverlay.name &&
          ol.speciesIdentifier === prevOverlay.speciesIdentifier &&
          ol.accessGranted &&
          (mappAirFilters
            ? ol.isMappairOverlay || ol.isSAOverlay
            : !ol.isMappairOverlay && !ol.isSAOverlay),
      );
      if (newOverlay.length) {
        handleChangeOverlay(newOverlay[0].name);
      } else {
        const newChangedOverlay = overlays.filter(
          (ol) =>
            (mappAirFilters
              ? ol.isMappairOverlay
              : !ol.isMappairOverlay && !ol.isSAOverlay) && ol.accessGranted,
        );
        if (newChangedOverlay && newChangedOverlay.length) {
          handleChangeOverlay(newChangedOverlay[0].name);
        }
      }
    }
  }, [mappAirFilters]);

  // Consts
  const [history, location] = [useHistory(), useLocation()];

  // Functions
  const getCurrentSpecies = () => {
    if (overlay) {
      const filteredOverlays = currentPollutant
        ? overlays.filter((ol) => ol.speciesIdentifier === currentPollutant)
        : null;
      const curSpecies =
        filteredOverlays && filteredOverlays.find((ol) => ol.name === overlay)
          ? filteredOverlays.filter((ol) => ol.name === overlay)[0]
            .speciesIdentifier
          : overlays.filter((ol) => ol.name === overlay)[0].speciesIdentifier;
      return curSpecies;
    }
    return '';
  };

  const getCurrentSpeciesObj = () => {
    if (overlay) {
      const filteredOverlays = currentPollutant
        ? overlays.filter((ol) => ol.speciesIdentifier === currentPollutant)
        : null;
      const curSpecies =
        filteredOverlays && filteredOverlays.find((ol) => ol.name === overlay)
          ? filteredOverlays.filter((ol) => ol.name === overlay)[0]
          : overlays.filter((ol) => ol.name === overlay)[0];
      return curSpecies;
    }
    return null;
  };

  const getSourcesDropdown = () => {
    let sourcesDropdown: Overlay[] = [];
    if (overlay && mappAirFilters) {
      const curSpecies = getCurrentSpecies();
      const curSpeciesWithSA = overlays.filter(
        (ol) => ol.speciesIdentifier === curSpecies && ol.isSAOverlay,
      );
      sourcesDropdown = curSpeciesWithSA;
    }
    return sourcesDropdown;
  };

  const getSourceSelection = () => {
    const saSelectionCheck = overlays.filter(
      (ol) => ol.name === overlay && ol.isSAOverlay,
    );
    if (saSelectionCheck.length) {
      return saSelectionCheck[0].name;
    }
    return 'none';
  };

  const handleChangeOverlay = async (updatedOverlay: string) => {
    const newThreshold = stylegroups.filter(
      (sg) => sg.name === activeStylegroup,
    )[0].thresholds;
    if (!newThreshold[updatedOverlay]) {
      if (updatedOverlay) await handleMissingAnnualColourscale(updatedOverlay);
    }
    await changeOverlayLayer(updatedOverlay);
  };

  const handleContactDialog = (on: boolean, initContactFormOptions?: any) => {
    if (!on) {
      setContactFormOptions(defaultContactForm);
    } else if (initContactFormOptions) {
      setContactFormOptions({
        ...defaultContactForm,
        formData: {
          ...defaultContactForm.formData,
          ...initContactFormOptions,
        },
      });
    }
    if (on) {
      setContactModalOn();
    } else {
      setModalsOff();
    }
  };

  const handleSpeciesDropdown = async (
    id: string,
    updatedSpeciesIdentifier: string,
  ) => {
    const newOverlay = overlays.filter(
      (ol) =>
        ol.speciesIdentifier === updatedSpeciesIdentifier &&
        (mappAirFilters
          ? ol.isMappairOverlay
          : !ol.isMappairOverlay && !ol.isSAOverlay),
    )[0];
    await handleChangeOverlay(newOverlay.name);
    // Analytics
    analyticsEventFirer(
      gtmEventIdentifiers.pollutantSelect,
      newOverlay.speciesIdentifier,
    );
  };

  const handleSourcesDropdown = async (id: string, updatedOverlay: string) => {
    const curSpecies = getCurrentSpecies();
    const sourcesOff = updatedOverlay === 'none';
    const newOverlay = !sourcesOff
      ? updatedOverlay
      : overlays.filter(
        (ol) => ol.speciesIdentifier === curSpecies && ol.isMappairOverlay,
      )[0].name;
    await handleChangeOverlay(newOverlay);
    // Analytics
    const labelEnrichment = sourcesOff ? 'Off' : newOverlay;
    analyticsEventFirer(gtmEventIdentifiers.sourceSelect, labelEnrichment);
  };

  const unSubSelectedVZephyr = async () => {
    if (!selectedVZephyrToDelete) {
      setSelectedVZephyrToDelete(null);
      return;
    }
    const res = await utilsRequest.unsubFromVZephyr(
      selectedVZephyrToDelete.id_pod,
      bearerToken,
    );
    if (res) {
      // const newZephyrsList = unitList.filter(
      //   (vZephyr) => vZephyr.id_pod !== unit.id_pod,
      // );
      // setZephyrs(newZephyrsList);
      // Above code is breaking map ui, so will have to manipulate DOM for now

      const target = document.getElementById(
        String(selectedVZephyrToDelete.id_pod),
      );
      if (target) {
        const parent = target?.parentElement;
        if (parent) parent.removeChild(target);
      }

      const unitCard = document.querySelector(
        `.unit-card-${selectedVZephyrToDelete.zNumber}`,
      );
      if (unitCard) {
        const parent = unitCard?.parentElement;
        if (parent) parent.removeChild(unitCard);
      }
    }
    setSelectedVZephyrToDelete(null);
  };

  const handleChangeLanguage = (selectedOption: any) => {
    const valueSelected = selectedOption.value;
    setLocale(selectedOption);
    i18n.changeLanguage(valueSelected);
    // history.push(`/?lng=${valueSelected}`);
  };

  const getSetVersions = async () => {
    // get/set versions
    if (!localStorage.getItem('Versions')) {
      const versions = await utilsRequest.getVersions(bearerToken);
      localStorage.setItem('Versions', JSON.stringify(versions)); // use redux state
      setVersions(versions);
    }
  };

  const latestSelectedPollutant = () => {
    const newOverlay = overlays.filter(
      (ol) =>
        ol.speciesIdentifier === currentPollutant &&
        (mappAirFilters
          ? ol.isMappairOverlay
          : !ol.isMappairOverlay && !ol.isSAOverlay),
    )[0];
    return newOverlay;
  };

  const toggleSaveAndLoadViewModals = (view: string) => {
    switch (view) {
      case 'save':
        setIsSaveViewOpened(true);
        break;
      case 'load':
        setIsLoadViewOpened(true);
        break;
    }
  };

  const resetViewsStateFromLocalStorage = () => {
    // this function needs to be reusable as we use it in Header.tsx as well
    if (
      JSON.parse(localStorage.getItem('unitHistoryViews') as any) &&
      JSON.parse(localStorage.getItem('unitHistoryViews') as any).length > 0
    ) {
      const views = JSON.parse(localStorage.getItem('unitHistoryViews') as any);
      views.forEach((view: any) => {
        if (view.isLoaded && view.isSelected) {
          view.isLoaded = false;
          view.isSelected = false;
        }
      });
      localStorage.setItem('unitHistoryViews', JSON.stringify([...views]));
    }
  };

  const handleMissingAnnualColourscale = async (updatedOverlay: string) => {
    const annualActiveGroupString = 'AA national';
    const isAnnualColourscaleEnabled = stylegroups
      .filter((sg) => sg.name === annualActiveGroupString)
      .find((sg) => updatedOverlay in sg.thresholds);
    if (
      mappairOptions.showOverlayLayer &&
      !isAnnualColourscaleEnabled &&
      activeStylegroup === annualActiveGroupString
    ) {
      const hourColourscale = stylegroups.find(
        (s: any) => s.displayedName === 'Earthsense (Hourly)',
      );
      if (hourColourscale && hourColourscale.thresholds[updatedOverlay]) {
        await setThresholdTab(hourColourscale.name);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await getSetVersions();
    })();
  }, []);

  useEffect(() => {
    // Get all views
    (async () => {
      try {
        const userViews = await utilsRequest.getUserViews(bearerToken);
        for await (const view of userViews) {
          view.isSelected = false;
        }
        setViews([...userViews]);
      } catch (e) {
        setViews([]);
      }
    })();
  }, []);

  useEffect(() => {
    // run initial analysis, using first 1/2 zephyrs
    if (!hasRunInitalRenderEffect.current && Array.isArray(unitHistories) && zephyrList.length > 0) {
      hasRunInitalRenderEffect.current = true;
      const newUnitList = [...zephyrList];
      newUnitList[0].isSelected = true;
      if (zephyrList.length > 1) {
        newUnitList[1].isSelected = true;
      }
      setZephyrsPollutantData((prevState: any) => {
        prevState.filteredZephyrs = newUnitList;
        prevState.pollutantData.forEach((pollutant: Pollutant) => {
          if (
            pollutant.dataIdentifier !== 'tempC' &&
            pollutant.dataIdentifier !== 'ambTempC' &&
            pollutant.dataIdentifier !== 'ambHumidity' &&
            pollutant.dataIdentifier !== 'ambPressure'
          )
            pollutant.isChecked = true;
        });
        return prevState;
      });
      setSelectedTimePeriod({ labelHTML: '24 Hours', timeOption: '24' });
      setSelectedTimeAveraging({ labelHTML: '1 Hour', averagingOption: 'Hourly average on the hour' });
      setInitialRenderSet(true);
    }
  }, [zephyrList]);
  
  const dispatch = useDispatch();
  
  const hideTourMenuOnPages = location.pathname.includes('data') || location.pathname.includes('fleetManagement');
  const showTourMenu = zephyrList.length > 0 && !hideTourMenuOnPages;

  return (
    <>
      {localStorage.getItem('token') ? (
        <div className="menu-hierarchy">
          {/* Main header: logo, help, account icon */}
          <header id="h-primary">
            <div className="top-header">
              <div className="header-hero">
                {userInfo && userInfo.title ? (
                  <>
                    <a href={userInfo.authorityLink} target="_blank">
                      <img
                        id="main-logo"
                        src={`data:image/png;base64,${userInfo.logo}`}
                        alt={`${userInfo.title} logo`}
                      />
                      <div className="public-title">
                        <div>{userInfo.title}</div>
                        <div className="public-subtitle">
                          {userInfo.subtitle}
                        </div>
                      </div>
                    </a>
                  </>
                ) : (
                  <div className="header-hero-no-logo">
                    <a href="https://www.earthsense.co.uk/" target="_blank">
                      <img src={esLogo} />
                    </a>
                  </div>
                )}
              </div>
              <div className="not-logo justify-content-end justify-content-md-between">
                <div
                  className={`tab-nav d-none ${
                    !userInfo.isPublic ? 'd-md-flex' : ''
                  }${userInfo.isPublic ? ' tab-nav-public' : ''}`}
                >
                  <nav className="tabs">
                    <ul>
                      <li>
                        <NavLink
                          to={routePaths.app}
                          activeClassName="active"
                          exact
                        >
                          {userInfo.isPublic
                            ? translate('LocationHeaderPublic')
                            : translate('LocationHeader')}
                        </NavLink>
                      </li>
                      <div data-user-tour="step-8" style={{ display: 'inline-block' }}>
                        {modularFeatures.dataAnalytics[
                          userInfo.isPublic ? 'isPublic' : 'isPrivate'
                        ] ? (
                          <li>
                            <NavLink
                              to={routePaths.dataAnalytics}
                              activeClassName="active"
                            >
                              {translate('DataAnalyticsHeader')}
                            </NavLink>
                          </li>
                        ) : (
                          <></>
                        )}
                        {/* {!userInfo.isPublic ? (
                      <li>
                        {setZephyr.serialNumber ? (
                          <NavLink
                            to={routePaths.analytics}
                            activeClassName="active"
                            className="tourDataTab"
                          >
                            {translate('AnalyticsHeader')}
                          </NavLink>
                        ) : (
                          <span className="data-disabled">
                            {translate('AnalyticsHeader')}
                          </span>
                        )}
                      </li>
                    ) : (
                      <></>
                    )} */}
                        {!userInfo.isPublic ? (
                          <>
                            {localStorage.getItem('user') === 'BP_Alerting' ||
                            localStorage.getItem('user') ===
                              'BP_Alerting_Alternative' ? (
                              <li
                                onClick={() => {
                                  setAlertsDropdownOpened(
                                    (prevState) => !prevState,
                                  );
                                }}
                                style={{ position: 'relative' }}
                              >
                                <NavLink
                                  to={location.pathname}
                                  activeClassName={
                                    location.pathname.includes('alerts') ||
                                    location.pathname.includes('smsAlerts')
                                      ? 'active'
                                      : ''
                                  }
                                >
                                  {translate('AlertsHeader')}
                                </NavLink>
                                {alertDropdownOpened && (
                                  <div
                                    style={{
                                      position: 'absolute',
                                      bottom: -85,
                                      width: 195,
                                      padding: 10,
                                      borderRadius: 5,
                                      backgroundColor: '#2a2c42',
                                    }}
                                  >
                                    <NavLink
                                      to={routePaths.alerts}
                                      activeClassName="active"
                                      style={{ display: 'inline-block' }}
                                    >
                                      {translate('AlertsHeader')}
                                    </NavLink>
                                    <br />
                                    <NavLink
                                      to={routePaths.smsAlerts}
                                      activeClassName="active"
                                      style={{ display: 'inline-block' }}
                                    >
                                      Group SMS Alerts
                                    </NavLink>
                                  </div>
                                )}
                              </li>
                            ) : (
                              <li>
                                <NavLink
                                  to={routePaths.alerts}
                                  activeClassName="active"
                                >
                                  {translate('AlertsHeader')}
                                </NavLink>
                              </li>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>

                      <li>
                        {!Array.isArray(setZephyr) && setZephyr.serialNumber ? (
                          <NavLink
                            to={routePaths.data}
                            activeClassName="active"
                            className="tourDataTab"
                          >
                            {translate('DataHeader')}
                            {!userInfo.isPublic && (
                              <var>
                                <sup>Leaving Soon</sup>
                              </var>
                            )}
                          </NavLink>
                        ) : (
                          <span className="data-disabled">
                            {translate('DataHeader')}
                          </span>
                        )}
                      </li>

                      {modularFeatures.fleetManagement[
                        userInfo.isPublic ? 'isPublic' : 'isPrivate'
                      ] ? (
                        <li>
                          <NavLink
                            to={routePaths.fleetManagement}
                            activeClassName="active"
                          >
                            {translate('FleetManagementHeader')}
                            <var>
                              <sup>BETA</sup>
                            </var>
                          </NavLink>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </nav>
                </div>

                {/* loader */}
                {loading.zephyrLastHour || loading.aurnLastHour ? (
                  <div className="header-in-app-loading-status">
                    <div className="header-in-app-loading-message">
                      {loading.zephyrLastHour
                        ? translate('LoadingZephyrHeader')
                        : translate('LoadingAURNHeader')}
                    </div>
                    <Spinner
                      additionalClass="header-spinner see-through"
                      on={loading.zephyrLastHour || loading.aurnLastHour}
                    />
                  </div>
                ) : (
                  <></>
                )}

                {/* right menu */}
                {!userInfo.isPublic ? (
                  <nav className="header-nav">
                    <ul>
                      <li>
                        <div
                          className="filter-dropdown"
                          onClick={() => {
                            setHelpMenuOpen(!helpMenuOpen);
                            setAccountMenuOpen(false);
                          }}
                        >
                          <button
                            className="header-nav-item tourHelp"
                            data-user-tour="step-9"
                            data-analysis-tour="step-9"
                          >
                            {translate('HelpHeader')}
                          </button>
                          <nav
                            className={
                              (!tourOpen && helpMenuOpen) ||
                              (tourOpen && tourOpenHelp)
                                ? 'active'
                                : 'inactive'
                            }
                            onMouseLeave={() => setHelpMenuOpen(false)}
                          >
                            <ul>
                              <li>
                                <a
                                  onClick={() => {
                                    setVersionHistoryOpen(true);
                                  }}
                                >
                                  {translate('VersionLinkTitle')}
                                </a>
                              </li>
                              <li>
                                <a
                                  href={helpResourceLink.url}
                                  target={helpResourceLink.target}
                                  className="tourResources"
                                  onClick={() =>
                                    // Analytics
                                    analyticsEventFirer(
                                      gtmEventIdentifiers.resourcesLink,
                                    )
                                  }
                                >
                                  {translate('ResourcesLinkTitle')}
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setTutorialOpen(true);
                                    // Analytics
                                    analyticsEventFirer(
                                      gtmEventIdentifiers.tutorialModal,
                                    );
                                  }}
                                >
                                  {translate('TutorialLinkTitle')}
                                </a>
                              </li>
                              {showTourMenu ? (
                                <li>
                                  <a
                                    onClick={() => {
                                      if (
                                        location.pathname.includes('analysis')
                                      ) {
                                        dispatch({ type: 'analysis_tour_toggle' });
                                        return;
                                      }
                                        if (
                                          location.pathname.includes('alerts')
                                        ) {
                                          dispatch({ type: 'alerts_tour_toggle' });
                                          return;
                                        }
                                      // Analytics
                                      analyticsEventFirer(
                                        gtmEventIdentifiers.tour,
                                      );
                                      startTour();
                                    }}
                                  >
                                    {translate('TourLinkTitle')}
                                  </a>
                                </li>
                              ) : (
                                null
                              )}
                              <li>
                                <a
                                  href="/"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setContactModalOn();
                                    // Analytics
                                    analyticsEventFirer(
                                      gtmEventIdentifiers.contactModal,
                                    );
                                  }}
                                >
                                  {translate('ContactLinkTitle')}
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </li>
                      <li>
                        <div
                          className="filter-dropdown"
                          onClick={() => {
                            setAccountMenuOpen(!accountMenuOpen);
                            setHelpMenuOpen(false);
                          }}
                        >
                          {/* <img
                            src={iconProfile}
                            alt="Toggle menu"
                            className="tourAccount"
                          /> */}
                          <div className="tourAccount user-initial">
                            {localStorage.getItem('user') &&
                            localStorage.getItem('user')!.length > 0
                              ? localStorage.getItem('user')![0].toUpperCase()
                              : ''}
                          </div>
                          <nav
                            className={accountMenuOpen ? 'active' : 'inactive'}
                            onMouseLeave={() => setAccountMenuOpen(false)}
                          >
                            <ul>
                              <li>
                                <a
                                  href={privacyPolicyLink.url}
                                  target={privacyPolicyLink.target}
                                  className="tourResources"
                                  onClick={() =>
                                    // Analytics
                                    analyticsEventFirer(
                                      gtmEventIdentifiers.privacyPolicyLink,
                                    )
                                  }
                                >
                                  {translate('PrivacyLinkTitle')}
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (localStorage.getItem('Versions'))
                                      localStorage.removeItem('Versions');
                                    if (localStorage.getItem('alerts'))
                                      localStorage.removeItem('alerts');
                                    if (
                                      sessionStorage.getItem(
                                        'locationRefreshed',
                                      )
                                    )
                                      sessionStorage.removeItem(
                                        'locationRefreshed',
                                      );
                                    history.push(routePaths.app);
                                    logout();
                                  }}
                                >
                                  {translate('LogoutLinkTitle')}
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </li>
                      {/* <li>{LocaleSelect(handleChangeLanguage, locale)}</li> */}
                    </ul>
                  </nav>
                ) : (
                  <nav className="header-nav">
                    <ul>
                      {userInfo.isPublic && userInfo.today ? (
                        <li className="only-mobile">
                          <NavLink
                            to={routePaths.today}
                            activeClassName="active mobile-active"
                            exact
                          >
                            {translate('TodayTitle')}
                          </NavLink>
                        </li>
                      ) : (
                        <></>
                      )}
                      <li
                        className={`${
                          !(userInfo.isPublic && userInfo.today)
                            ? ''
                            : 'remove-mobile'
                        }`}
                      >
                        <a
                          onClick={() => {
                            if (aq101.on) {
                              setAQ101Off();
                            } else {
                              setAQ101On();
                            }
                          }}
                        >
                          {translate('AirQuality101Title')}
                        </a>
                      </li>
                      <li className="remove-mobile">
                        <a href={userInfo.projectLink} target="_blank">
                          {translate('CouncilProjectTitle')}
                        </a>
                        {/* <a href={userInfo.projectLink} target="_blank">
                          {translate('welcome')}
                        </a> */}
                      </li>
                      <li className="only-mobile">
                        <NavLink
                          to={routePaths.app}
                          activeClassName="active mobile-active"
                          exact
                        >
                          {userInfo.isPublic
                            ? translate('LocationHeaderPublic')
                            : translate('LocationHeader')}
                        </NavLink>
                      </li>
                      {/* {userInfo.isPublic && (
                        <li className="only-mobile">
                          <NavLink
                            to={routePaths.data}
                            activeClassName="active mobile-active"
                            exact
                          >
                            {translate('DataHeader')}
                          </NavLink>
                        </li>
                      )} */}

                      {/* <li>{LocaleSelect(handleChangeLanguage, locale)}</li> */}
                    </ul>
                  </nav>
                )}
              </div>
            </div>
          </header>

          {location.pathname.includes('analysis') && (
            <FilterBar1
              toggleSaveAndLoadViewModals={toggleSaveAndLoadViewModals}
              zephyrsPollutantData={zephyrsPollutantData}
              setZephyrsPollutantData={setZephyrsPollutantData}
              selectedTimePeriod={selectedTimePeriod}
              setSelectedTimePeriod={setSelectedTimePeriod}
              selectedTimeAveraging={selectedTimeAveraging}
              setSelectedTimeAveraging={setSelectedTimeAveraging}
              isViewLoaded={viewLoaded}
              setViewLoaded={setViewLoaded}
              customDate={customDate}
              setCustomDate={setCustomDate}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              initialRenderSet={initialRenderSet}
              setInitialRenderSet={setInitialRenderSet}
            />
          )}

          {/* Subheader plus aside (location/data/time) */}
          {!location.pathname.includes('analysis') && (
            <div className="sub-header-container remove-mobile">
              <Aside
                modal={modal}
                setModalsOff={setModalsOff}
                vZephyrsConfig={vZephyrsConfig}
                setSelectedVZephyrToDelete={setSelectedVZephyrToDelete}
                unSubSelectedVZephyr={unSubSelectedVZephyr}
                overlay={latestSelectedPollutant()}
              />
            </div>
          )}

          {/* Mappair/species header */}
          {(!location.pathname.includes('analysis') &&
            location.pathname.includes('data')) ||
          location.pathname.includes('alerts') ||
          location.pathname.includes('today') ||
          location.pathname.includes('analytics') ||
          !overlays.filter((ol) =>
            mappAirFilters
              ? ol.isMappairOverlay
              : !ol.isMappairOverlay && !ol.isSAOverlay
              ? zephyrList && zephyrList.length
              : !ol.isMappairOverlay,
          ).length ? (
            <div
              className={
                location.pathname.includes('data') ||
                location.pathname.includes('alerts') ||
                location.pathname.includes('today') ||
                location.pathname.includes('analytics')
                  ? ''
                  : 'species-filters-placeholder'
              }
            >
              {location.pathname === '/' &&
              !loading.aurnLastHour &&
              !loading.initApp &&
              !loading.zephyrLastHour &&
              overlays.length === 0 ? (
                <h3 className="no-overlays-text">No overlays available</h3>
              ) : null}
            </div>
          ) : (
            <div
              className={
                location.pathname.includes('fleetManagement')
                  ? 'mappAirFilters-fleet-management'
                  : location.pathname.includes('analysis')
                  ? 'mappAirFilters-data-analytics'
                  : 'mappAirFilters'
              }
            >
              {!location.pathname.includes('fleetManagement') &&
              !location.pathname.includes('analysis') ? (
                <ul
                  className="species-list tourSpeciesHeader"
                  data-user-tour="step-4"
                >
                  <li>
                    <strong>{translate('SpeciesSubHeader')}</strong>
                    {overlays.filter((ol) =>
                      mappAirFilters
                        ? ol.isMappairOverlay
                        : !ol.isMappairOverlay && !ol.isSAOverlay,
                    ).length > 1 ? (
                      <Dropdown
                        currentPollutant={currentPollutant}
                        setCurrentPollutant={setCurrentPollutant}
                        additionalClass="custom-dropdown-species-container"
                        currentSelectionValue={getCurrentSpecies()}
                        handleSelection={handleSpeciesDropdown}
                        id="species-selection"
                        placeholderSelectionLabel="Select"
                        selections={overlays
                          .filter((ol) =>
                            mappAirFilters
                              ? ol.isMappairOverlay
                              : !ol.isMappairOverlay && !ol.isSAOverlay,
                          )
                          .map((indOverlay) => ({
                            value: indOverlay.speciesIdentifier,
                            label: indOverlay.nameHTML,
                          }))}
                        annualAveragesOn={annualAveragesOn}
                        dynamicStyles={true}
                      />
                    ) : (
                      <div className="species-list-dropdown-replacement">
                        {parse(
                          getCurrentSpeciesObj()
                            ? getCurrentSpeciesObj()!.nameHTML
                            : '',
                        )}
                      </div>
                    )}
                  </li>
                  {userInfo.isPublic &&
                  overlays.filter((ol) =>
                    mappAirFilters
                      ? ol.isMappairOverlay
                      : !ol.isMappairOverlay && !ol.isSAOverlay,
                  ).length < 2 &&
                  (!wideScreenFinder(800) || !tallScreenFinder(500)) ? (
                    <li className="public-legend">
                      <ul>
                        <li>
                          <div className="marker-container">
                            <div className="marker type-0" />
                          </div>
                          AQ Monitor
                        </li>
                        <li>
                          <div className="marker-container">
                            <div className="marker type-1">
                              <div className="triangle-container" />
                            </div>
                          </div>
                          DEFRA AQ Monitor
                        </li>
                      </ul>
                    </li>
                  ) : (
                    <></>
                  )}
                  {getSourcesDropdown().length && !userInfo.isPublic ? (
                    <li>
                      <strong>{translate('SourcesSubHeader')}</strong>
                      <Tooltip
                        containerClassName="sources-dropdown-tooltip-container"
                        content={`${
                          getSourceSelection() === 'none'
                            ? `${translate('SourceEmissionTooltipIntro')} ${
                                getCurrentSpeciesObj()
                                  ? getCurrentSpeciesObj()!.nameHTML
                                  : translate(
                                      'SourceEmissionTooltipMissingSpecies',
                                    )
                              }${translate('SourceEmissionTooltipEnd')}`
                            : `${translate(
                                'SourceEmissionTooltipSelectedIntro',
                              )} ${
                                getCurrentSpeciesObj()
                                  ? getCurrentSpeciesObj()!.nameHTML
                                  : translate(
                                      'SourceEmissionTooltipMissingSpecies',
                                    )
                              } ${translate(
                                'SourceEmissionTooltipSelectedEnd',
                              )}`
                        }`}
                        tooltipMarkerContent={translate('TooltipMarkerContent')}
                      />
                      <Dropdown
                        currentPollutant={currentPollutant}
                        setCurrentPollutant={setCurrentPollutant}
                        additionalClass="custom-dropdown-sources-container"
                        currentSelectionValue={getSourceSelection()}
                        handleSelection={handleSourcesDropdown}
                        id="source-selection"
                        placeholderSelectionLabel="Select"
                        selections={[
                          { value: 'none', label: 'All sources' },
                          ...getSourcesDropdown().map((gsd) => ({
                            value: gsd.name,
                            label: gsd.nameHTML,
                          })),
                        ]}
                      />
                    </li>
                  ) : (
                    <></>
                  )}
                  {!userInfo.isPublic ? (
                    <li className="opacity-slider remove-mobile">
                      <Slider
                        on={
                          (mappAirFilters && overlay !== '') || annualAveragesOn
                        }
                        value={opacity}
                        actionCreator={changeOverlayOpacity}
                        id="opacity"
                      />
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              ) : (
                <></>
              )}

              {!location.pathname.includes('analysis') && (
                <time
                  dateTime="2019-10-20"
                  className={
                    !location.pathname.includes('fleetManagement') &&
                    !location.pathname.includes('analysis')
                      ? 'd-none d-lg-flex'
                      : 'd-none d-lg-flex centered-datetime'
                  }
                >
                  {todayHeaderFormatter()}
                </time>
              )}
              {!userInfo.isPublic ? (
                <>
                  {!location.pathname.includes('analysis') && (
                    <button
                      onClick={() => setMappairQuoteModalOn()}
                      className="prompt-button wide-prompt-button"
                      type="button"
                    >
                      {translate('MappairUpgradePrompt')}
                    </button>
                  )}

                  {/* {location.pathname.includes('analysis') && (
                    <button
                      onClick={() =>
                        setFeedbackModalOn((prevState: boolean) => !prevState)
                      }
                      className="prompt-button wide-prompt-button"
                      type="button"
                    >
                      {translate('FeedbackPrompt')}
                    </button>
                  )} */}
                </>
              ) : (
                <></>
              )}
            </div>
          )}
          <Modal
            on={modal.contact}
            modalComponent={
              <HeaderContactModal
                contactFormOptions={contactFormOptions}
                handleContactDialog={handleContactDialog}
              />
            }
          />
          <Modal
            on={versionHistoryOpen}
            modalComponent={
              <HeaderVersionHistoryModal
                handleVersionDialog={setVersionHistoryOpen}
              />
            }
          />

          <Modal
            on={tutorialOpen}
            modalComponent={
              <HeaderTutorialModal handleTutorialDialog={setTutorialOpen} />
            }
          />

          <Modal
            on={modal.mappairQuote}
            modalComponent={
              <MappairQuoteModal
                contactFormOptions={defaultMappairForm}
                setModalsOff={setModalsOff}
              />
            }
          />

          <Modal
            on={aq101.on}
            modalComponent={
              <AirQuality101
                handleAQ101Modal={() => {
                  if (aq101.on) {
                    setAQ101Off();
                  } else {
                    setAQ101On();
                  }
                }}
              />
            }
          />

          <Modal
            on={!!selectedVZephyrToDelete}
            modalComponent={
              <RemoveZephyrModal
                selectedVZephyrToDelete={selectedVZephyrToDelete}
                setSelectedVZephyrToDelete={setSelectedVZephyrToDelete}
                unSubSelectedVZephyr={unSubSelectedVZephyr}
              />
            }
          />

          <Modal
            on={isSaveViewOpened}
            modalComponent={
              <AnalyticsViewModal
                setIsSaveViewOpened={setIsSaveViewOpened}
                zephyrsPollutantData={zephyrsPollutantData}
                setZephyrsPollutantData={setZephyrsPollutantData}
                selectedTimePeriod={selectedTimePeriod}
                setSelectedTimePeriod={setSelectedTimePeriod}
                selectedTimeAveraging={selectedTimeAveraging}
                setSelectedTimeAveraging={setSelectedTimeAveraging}
                isViewNameValidated={isViewNameValidated}
                setIsViewNameValidated={setIsViewNameValidated}
                views={views}
                setViews={setViews}
                customDate={customDate}
                setCustomDate={setCustomDate}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
            }
          />

          <Modal
            on={isLoadViewOpened}
            modalComponent={
              <AnalyticsViewModal
                isLoadViewOpened={isLoadViewOpened}
                setIsLoadViewOpened={setIsLoadViewOpened}
                zephyrsPollutantData={zephyrsPollutantData}
                setZephyrsPollutantData={setZephyrsPollutantData}
                selectedTimePeriod={selectedTimePeriod}
                setSelectedTimePeriod={setSelectedTimePeriod}
                selectedTimeAveraging={selectedTimeAveraging}
                setSelectedTimeAveraging={setSelectedTimeAveraging}
                isViewNameValidated={isViewNameValidated}
                setIsViewNameValidated={setIsViewNameValidated}
                views={views}
                setViews={setViews}
                setViewLoaded={setViewLoaded}
                customDate={customDate}
                setCustomDate={setCustomDate}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
            }
          />

          <Modal
            on={feedbackModalOn}
            modalComponent={
              <AnalyticsFeedbackForm setFeedbackModalOn={setFeedbackModalOn} />
            }
          />

          <Feedback userInfo={userInfo} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state: ReduxState, ownProps: any) => ({
  activeStylegroup: state.setThresholdTab.tab,
  aq101: state.aq101,
  loading: state.loading,
  mappAirFilters: state.mappAirFilters.showMappAirFilters,
  modal: state.modal,
  opacity: state.showAQMALayer.opacity,
  overlay: state.showAQMALayer.overlay,
  overlays: state.setOverlays,
  stylegroups: state.getStyleGroups.styleGroups,
  setZephyr: state.setZephyr.zephyr,
  curZephyr: state.setZephyr.zephyr,
  tourOpen: state.tour.openTour,
  tourOpenHelp: state.tour.openHelp,
  userInfo: state.auth.userInfo,
  zephyrList: state.getZephyrs.zephyrs,
  locale: state.setLocale,
  vZephyrsConfig: ownProps.vZephyrsConfig,
  annualAveragesOn: state.showAQMALayer.showAALayer,
  modularFeatures: state.modularFeatures,
  bearerToken: state.auth.bearerToken,
  mappairOptions: state.showAQMALayer,
  unitHistories: state.unitHistoriesOptions.unitHistories,
  unitHistoriesLoading: state.unitHistoriesOptions.isUnitHistoriesLoading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      changeOverlayLayer,
      changeOverlayOpacity,
      logout,
      setAQ101Off,
      setAQ101On,
      setContactModalOn,
      setMappairQuoteModalOn,
      setModalsOff,
      setThresholdTab,
      startTour,
      setLocale,
      setVersions,
      setUnitHistoriesList,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

function LocaleSelect({
  handleChangeLanguage,
  locale,
}: {
  handleChangeLanguage: (selectedOption: any) => void;
  locale: String
}) {
  return (
    <Select
      // menuIsOpen //use to debug and style menu
      isSearchable={false}
      className="react-select__container ninja"
      classNamePrefix="react-select"
      options={translationOptions}
      onChange={handleChangeLanguage}
      value={translationOptions.find((opts) => opts.value === locale)}
      formatOptionLabel={(opt: translationOption) => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            className={`react-select__icon  ${opt.icon}`}
          >
            <span className="react-select__icon-label">{opt.label}</span>
          </div>
        );
      }}
    />
  );
}
