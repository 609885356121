// Package Imports
import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module';

// Type Imports
import {
  APIAirAlertPost,
  APIAirAlertParameter,
  DataConfig,
  LocationCoordinates,
  PolarPlotData,
  ZephyrLocationShort,
  APIAirAlertTarget,
  VirtualZephyr,
  VirtualZephyrData,
  Pollutant,
  Zephyr,
  AveragingChain,
} from './interface';

import i18n from 'i18next';

// Path Imports
import {
  APIPaths,
  averagingChains,
  averagingChainsWithoutUnaveraged,
  config,
  keys,
  permissionCookieSettings,
} from './consts';

// Utils
import { getAuth } from './functions/getAuth';

// MappAir API Requests
const checkValidToken = async (bearerToken: string | null) => {
  try {
    const response = await axios.get(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getMappairAPIVersion}`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    if (response.data.version) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

const getAveragingPeriods = async (bearerToken: string | null) => {
  try {
    const response = await axios.get(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getAveragingPeriods}`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return {};
  }
};

const getCapabilities = async () => {
  try {
    const response = await axios.get(`${config.mappairAPI}wmtsfeed?`, {
      headers: {
        'Accept-Language': i18n.language,
      },
    });
    return response.data;
  } catch (e) {
    return {};
  }
};

const getDataConfig = async (bearerToken: string | null) => {
  try {
    const response = await axios.get(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getDataConfig}`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return {};
  }
};

const getDisplayConfig = async (bearerToken: string | null) => {
  try {
    const response = await axios.get(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getDisplayConfig}`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return {};
  }
};

const getFetchToken = async (
  email: string,
  password: string,
  bearerToken: string | null,
) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('username', email);
  urlencoded.append('password', password);
  urlencoded.append('grant_type', 'password');

  const data = {
    grant_type: 'password',
    username: email,
    password,
  };

  try {
    const response = await axios.post(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getToken}`,
      data,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data.access_token;
  } catch (e) {
    console.error(e);
  }
};

const getLatestMetDataForUser = async (
  data: ZephyrLocationShort[],
  bearerToken?: string | null,
) => {
  try {
    const response = await axios.post(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getLatestMetDataForUser}`,
      data,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

const getOverlays = async (bearerToken: string | null, isPublic: boolean) => {
  try {
    const response = await axios.get(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getMappairOverlays}?PPUser=${isPublic}`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

const getPolarPlots = async (
  data: PolarPlotData,
  bearerToken: string | null,
) => {
  try {
    const response = await axios.post(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getPolarPlots}`,
      data,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

const getPublicAdvice = async (bearerToken: string | null) => {
  try {
    const response = await axios.get(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getPublicAdvice}`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

const getAnnualAvgAtCoords = async (
  lat: any,
  lng: any,
  startdt: string,
  enddt: string,
  step: string,
  bearerToken: string | null,
  species?: string,
  keyword?: string,
) => {
  try {
    const url = `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getMappairPoint}`;

    const queryParams = new URLSearchParams();
    // queryParams.set('auth', auth);
    queryParams.set('lat', lat.toString());
    queryParams.set('lng', lng.toString());
    queryParams.set('dt_start', startdt);
    queryParams.set('dt_end', enddt);
    queryParams.set('step', step);
    if (species) queryParams.set('species', species);
    if (keyword) queryParams.set('keyword', keyword);
    // might need in future
    // const requestConfig = {
    //   headers: {
    //     Authorization: `Basic ${getAuth()}`,
    //     'Accept-Language': i18n.language,
    //   },
    // };

    const response = await axios.get(`${url}/?${queryParams.toString()}`, {
      headers: {
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return response.data;
  } catch (e) {
    console.error(e);
    return {};
  }
};

const getSAAtPoint = async (
  pixels: number[],
  bounds: any,
  width: number,
  height: number,
  lat: number,
  lng: number,
  bearerToken: string | null,
) => {
  const hardcodedProduct = 'Mappair_100m_UK:NRT_PM2p5_FC';
  try {
    const response = await axios(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getMappairOverlaySAValues
      }?product=${hardcodedProduct}&x=${Math.round(pixels[0])}&y=${Math.round(
        pixels[1],
      )}&bbox=${bounds}&width=${width}&height=${height}&lat=${lat}&lng=${lng}`,
      {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch {
    return { value: 'NA' };
  }
};

const getStylegroups = async (bearerToken: string | null) => {
  try {
    const response = await axios.get(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getMappairStylegroups}`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

const getTimePeriods = async (bearerToken: string | null) => {
  try {
    const response = await axios.get(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getTimePeriods}`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return {};
  }
};

const getUserFeedbackQuestions = async (bearerToken: string | null) => {
  try {
    const response = await axios.get(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getUserFeedbackQuestions}`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return {};
  }
};

const getTodayData = async (
  species: string,
  latLon: LocationCoordinates,
  step: number,
  bearerToken: string | null,
  dates?: {
    startDT?: string | any;
    endDT?: string | any;
  },
  keyword?: string,
) => {
  try {
    const response = await axios.get(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getTodayData
      }?lat=${latLon[0]}&lng=${latLon[1]}&species=${species}&includeText=true${dates && dates.startDT ? `&dt_start=${dates.startDT}` : ''
      }${dates && dates.endDT ? `&dt_end=${dates.endDT}` : ''}${keyword ? `&keyword=${keyword}` : ''
      }&step=${step}`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

const getUserAgreements = async (bearerToken: string | null) => {
  try {
    const response = await axios.get(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getUserAgreements}`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

const getUserConfig = async (bearerToken: string | null) => {
  try {
    const response = await axios.get(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getUserConfig}`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return false;
  }
};

const getUserInfo = async (bearerToken: string | null) => {
  try {
    const response = await axios.get(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getUserInfo}`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return {};
  }
};

const getDataAtCoords = async (
  lat: any,
  lng: any,
  startdt: string,
  enddt: string,
  bearerToken: string | null,
  species?: string,
  includeText?: boolean,
  step?: number,
) => {
  try {
    const url = `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getMappairPoint}`;
    const queryParams = new URLSearchParams();
    // queryParams.set('auth', auth);
    queryParams.set('lat', lat.toString());
    queryParams.set('lng', lng.toString());
    queryParams.set('dt_start', startdt);
    queryParams.set('dt_end', enddt);
    if (species) queryParams.set('species', species);
    if (step) queryParams.set('step', step.toString());
    if (includeText) queryParams.set('includeText', includeText.toString());
    const requestConfig = {
      headers: {
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${bearerToken}`,
      },
    };
    const response = await axios.get(
      `${url}/?${queryParams.toString()}`,
      requestConfig,
    );
    if (response.status != 200) throw new Error();
    return response.data;
  } catch (e) {
    return { value: 'NA' };
  }
};

const getValueAtPoint = async (
  pixels: number[],
  overlay: string,
  bounds: number,
  width: number,
  height: number,
  time: string,
  crs: string,
  bearerToken: string | null,
) => {
  try {
    const response = await axios(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getMappairOverlayValue
      }?overlay=${overlay}&x=${Math.round(pixels[0])}&y=${Math.round(
        pixels[1],
      )}&bbox=${bounds}&width=${width}&height=${height}&time=${time}&crs=${crs}`,
      {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return { value: 'NA' };
  }
};

const getVersions = async (bearerToken: string | null) => {
  try {
    const response = await axios.get(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.getMyAirVersions}`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

const postUserFeedback = async (
  mufId: number,
  score: number,
  comments: string,
  email: string,
  bearerToken: string | null,
) => {
  try {
    const response = await axios.post(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.postUserFeedback}?mufId=${mufId}&score=${score}&comments=${comments}&email=${email}`,
      {},
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return false;
  }
};

const setShowTour = async (showTour: boolean, bearerToken: string | null) => {
  try {
    const auth = getAuth();
    const response = await axios.post(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.setUserTour}?auth=${auth}&showTour=${showTour}`,
      {},
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return false;
  }
};

const signUserAgreement = async (muaId: number, bearerToken: string | null) => {
  try {
    const response = await axios.post(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.signUserAgreement}?muaId=${muaId}`,
      {},
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return false;
  }
};

const submitContactForm = async (
  formData: {
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    enquiry: string;
    details: string;
    comments: string;
  },
  bearerToken: string | null,
) => {
  const {
    firstName,
    lastName,
    email,
    company,
    enquiry,
    details,
    comments,
  } = formData;
  try {
    const response = await axios.post(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.postContactFormEmail}?email=${email}&firstName=${firstName}&lastName=${lastName}&company=${company}&enquiry=${enquiry}&details=${details}&comments=${comments}`,
      formData,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return false;
  }
};

const updateUserLatestVersionViewed = async (
  versionId: number,
  bearerToken: string | null,
) => {
  try {
    const response = await axios.post(
      `${config.mappairAPI}${APIPaths.pathMappair}${APIPaths.updateUserLatestVersionViewed}?versionId=${versionId}`,
      {},
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return false;
  }
};

// Unit WiFi Requests
export const apiRenameUnitWifiNetwork = async (
  // the method for triggering this endpoint is in AsideUnit component but it's not attached to any HTML element
  zNumber: string,
  wifiData: { networkName: string; networkPassword: string },
  bearerToken: string | null,
) => {
  try {
    const axiosConfig: AxiosRequestConfig = {
      method: 'post',
      url: `${config.zephyrAPI}wifisettings/${zNumber}`,
      headers: {
        // userkey: auth,
        username: localStorage.getItem('user') || '',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearerToken}`,
      },
      data: {
        SSID: wifiData.networkName,
        Password: wifiData.networkPassword,
      },
    };

    const response = await axios(axiosConfig);

    return [null, response.data];
  } catch (e) {
    console.error(e);
    console.error('failed to rename user wifi credentials');
    return [e, null];
  }
};

export const apiGetWifiCredentials = async (
  zNumber: string,
  bearerToken: string | null,
) => {
  // the method for triggering this endpoint is in AsideUnit component but it's not attached to any HTML element
  try {
    const response = await axios.get(
      `${config.zephyrAPI}wifisettings/${zNumber}`,
      {
        headers: {
          'Content-Type': 'application/json',
          username: localStorage.getItem('user') || '',
          Authorization: `Bearer ${bearerToken}`,
          // userkey: auth,
        },
      },
    );

    return [null, response.data];
  } catch (e) {
    console.error('failed to get user wifi credentials');
    return [e, null];
  }
};

// Zephyr API Requests
const createZephyrAlias = async (
  zNumber: number,
  alias: string,
  bearerToken: string | null,
) => {
  try {
    const response = await axios.post(
      `${config.zephyrAPI
      }${APIPaths.createZephyrAlias.toLowerCase()}/${zNumber}/${alias}`,
      {},
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return false;
  }
};

const getAurns = async (bearerToken: string | null) => {
  try {
    const response = await axios.get(
      `${config.zephyrAPI}${APIPaths.getAurns.toLowerCase()}`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

const getCachedZephyrHistoricalData = async (
  // Deprecated
  zNumber: number,
  start: string,
  end: string,
  dataConfig: DataConfig,
  cache: number,
  bearerToken: string | null,
  acOverride?: number,
) => {
  try {
    const averagingChain = acOverride || dataConfig.averagingChain;
    const response = await axios.get(
      `${config.zephyrAPI
      }${APIPaths.cachedDataForViewBySlotsAveraged.toLowerCase()}/${zNumber}/${start}/${end}/AB/${dataConfig.ctv
      }/${cache}/${averagingChain}/JSON/api`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return false;
  }
};

const getCartridge = async (
  cartridgeSerial: string,
  bearerToken: string | null,
) => {
  try {
    const response = await axios.get(
      `${config.zephyrAPI
      }${APIPaths.getCartridge.toLowerCase()}/${cartridgeSerial}`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return null;
  }
};

const getUserZephyrsWithHistory = async (bearerToken: string | null) => {
  // Deprecated
  try {
    const response = await axios.get(
      `${config.zephyrAPI}${APIPaths.zephyrsForUserWithHistory.toLowerCase()}`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

const getUserZephyrsWithHistoryAndAQ = async (
  // Deprecated
  ctv: string,
  getZephyrs: number,
  getAURNs: number,
  bearerToken: string | null,
) => {
  try {
    const response = await axios.get(
      `${config.zephyrAPI
      }${APIPaths.zephyrsForUserWithHistoryAndAQ.toLowerCase()}/${ctv}/${getZephyrs}/${getAURNs}`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

// #region virtualZephyrs
const getVZephyrs = async (bearerToken: string | null, location?: string) => {
  try {
    const url = `${config.vZephyrAPI}GetVzephyrs`;
    const queryValues = [];
    if (location) queryValues.push(['location', location]);

    const queryParams = new URLSearchParams(queryValues).toString();

    const requestConfig = {
      headers: {
        // Authorization: `Basic ${auth}`,
        Authorization: `Bearer ${bearerToken}`,
        'Accept-Language': i18n.language,
      },
    };
    const response = await axios.get(`${url}`, requestConfig);
    // const response = await axios.get(`${url}/?${queryParams}`, requestConfig);
    return response.data;
  } catch (e) {
    console.error(
      'Failed to retrieve Virtual Zephyrs. Please notify support@earthsense.co.uk if this is not expected.',
    );
    return [];
  }
};

const averagingOptionMapping: { [option: string]: number } = {
	"Hourly average on the hour": 1,
	"8 hour average at midnight and 8am and 4pm": 8,
	"Daily average at midnight": 24,
};

const getVZephyrData = async (
  bearerToken: string | null,
  vzephyrid: number,
  dataConfig: DataConfig,
  startdt: string,
  enddt?: string,
  averagingOption?: string,
) => {
  try {
    const url = `${config.vZephyrAPI}getdata`;
    const { averagingChain } = dataConfig;
    // const queryParams = new URLSearchParams();
    // queryParams.set('auth', auth);
    // queryParams.set('vzephyr_id', vzephyrid.toString());
    // queryParams.set('start_dt', startdt);
    // if (enddt) queryParams.set('end_dt', enddt);
    // // queryParams.set('id_user_target', '2769');
    // queryParams.set('averaging', averagingChain.toString());
    const requestConfig = {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Accept-Language': i18n.language,
      },
    };
    
    const averaging = averagingOption ? averagingOptionMapping[averagingOption] : undefined;

    const response = await axios.get( url,{
      params: {
        vzephyr_id: vzephyrid.toString(),
        start_dt: startdt,
        end_dt: enddt,
        averaging,
      },
      ...requestConfig,
    }
    );
    return response.data;
  } catch (e) {
    console.error(
      'Failed to retrieve Virtual Zephyr data. Please notify support@earthsense.co.uk if this is not expected.',
    );
    return [];
  }
};

const getProxyVZephyrData = async (id_pod: number, bearerToken: string | null, startdt: string, enddt?: string, averagingOption?: string) => {
const averaging = averagingOption ? averagingOptionMapping[averagingOption] : undefined;
if (!averaging) {
  return
}

  try {
    const response = await axios.get(
      `${config.vZephyrAPI}getdata`,
      {
        headers: {
          'Accept-Language': i18n.language,
          'Authorization': `Bearer ${bearerToken}`
        },
        params: {
          pod_id: id_pod.toString(),
          id_user_target: 2996,
          start_dt: startdt,
          end_dt: enddt,
          averaging,
        }
      },
    );
    return response.data;
  } catch (e) {
    console.error('Failed to retrieve Virtual Zephyr data. Please notify');
    return [];
  }
};

const addVZephyr = async (
  name: string,
  description: string,
  latitude: number,
  longitude: number,
  bearerToken: string | null,
) => {
  try {
    const url = `${config.vZephyrAPI}AddVzephyr`;

    const queryParams = new URLSearchParams();
    // queryParams.set('auth', auth);
    queryParams.set('name', name);
    if (description) queryParams.set('description', description);
    queryParams.set('latitude', latitude.toString());
    queryParams.set('longitude', longitude.toString());

    const requestConfig = {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Accept-Language': i18n.language,
      },
    };
    const response = await axios.post(
      `${url}/?${queryParams.toString()}`,
      {},
      requestConfig,
    );
    return response;
  } catch (e) {
    console.error(
      'Failed to add Virtual Zephyr. Please notify support@earthsense.co.uk if this is not expected.',
    );
    return [];
  }
};

const editVZephyr = async (
  vzephyrid: number,
  name: string,
  bearerToken: string | null,
  description?: string,
) => {
  try {
    const url = `${config.vZephyrAPI}EditVzephyr`;

    const queryParams = new URLSearchParams();
    // queryParams.set('auth', auth);
    queryParams.set('vzephyr_id', vzephyrid.toString());
    queryParams.set('name', name);
    if (description) queryParams.set('description', description);

    const requestConfig = {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Accept-Language': i18n.language,
      },
    };
    const response = await axios.patch(
      `${url}/?${queryParams.toString()}`,
      {},
      requestConfig,
    );

    return response;
  } catch (e) {
    console.error(
      'Failed to edit Virtual Zephyr. Please notify support@earthsense.co.uk if this is not expected.',
    );
    return [];
  }
};

const unsubFromVZephyr = async (
  vzephyrid: number,
  bearerToken: string | null,
) => {
  try {
    const url = `${config.vZephyrAPI}unsubvzephyr`;

    const queryParams = new URLSearchParams();
    // queryParams.set('auth', auth);
    queryParams.set('vzephyr_id', vzephyrid.toString());

    const requestConfig = {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Accept-Language': i18n.language,
      },
    };
    const response = await axios.patch(
      `${url}/?${queryParams.toString()}`,
      {},
      requestConfig,
    );

    return response;
  } catch (e) {
    console.error(
      'Failed to unsub Virtual Zephyr. Please notify support@earthsense.co.uk if this is not expected.',
    );
    return [];
  }
};

const getUserVZephyrConfig = async (bearerToken: string | null) => {
  try {
    const requestConfig = {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Accept-Language': i18n.language,
      },
    };
    const response = await axios.get(
      `${config.vZephyrAPI}getuserconfig`,
      requestConfig,
    );
    return response.data;
  } catch (e) {
    console.error(
      'Failed to get vZephyr user config. Please notify support@earthsense.co.uk if this is not expected.',
    );
    return [];
  }
};
// #endregion

const getZephyrs = async (bearerToken: string | null) => {
  try {

    const response = await axios.get(
      `${config.zephyrAPI}${APIPaths.getZephyrs.toLowerCase()}`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );

    return response.data;
  } catch (e) {
    return [];
  }
};

const getZephyrMeasurementData = async (
  zephyr: Zephyr,
  start: string,
  end: string,
  dataConfig: DataConfig,
  bearerToken: string | null,
  isPathLocation: boolean,
  DSE?: boolean,
  selectedPollutants?: Pollutant[],
  isDA?: boolean,
  averagingOption?: string
) => {
  try {
    const { zNumber: znumber, type: zephyrType, HasMetStation } = zephyr;
    // Passing particulates individually as param doesn't work, need to pass them as 'Particulates'
    const pollutionArr = selectedPollutants?.map((pollutant) => {
      return pollutant.dataIdentifier.includes('particulate') ? pollutant.dataIdentifier.split('particulate')[1] : pollutant.dataIdentifier;
    });
    const hasPollutionArrParticulates = pollutionArr?.find((pollutant: string) => pollutant.includes('PM'));
    const params = selectedPollutants && pollutionArr ? hasPollutionArrParticulates ? `metCore,${pollutionArr.join(',')},Particulates` : `metCore,${pollutionArr.join(',')}` : 'metCore,MyAirLocation';
    const auth = getAuth();
    const DSEOFF = `${config.zephyrAPI}v2/measurementdata/${znumber}/${start}/${end}/AB/${isPathLocation ? 1 : isDA ? averagingChains[averagingOption as string] : dataConfig.averagingChain}/${HasMetStation ? 'metCore,' : ''}MyAirLocation/${dataConfig.ctv}`;
    const DSEON = `${config.zephyrAPI}${APIPaths.GetMeasurmentDataDSE
      }/${znumber}/${start}/${end}/AB/${13}/tempC,humidity,Enhanced,CO2,TVOC/${dataConfig.ctv
      }/C`;
    const headers = {
      // 'access-control-request-headers': 'usertoken',
      // usertoken: auth,
      'access-control-request-headers': 'Authorization',
      'Content-Type': 'application/json',
      'Accept-Language': i18n.language,
      Authorization: `Bearer ${bearerToken}`,
    };

    const response = await axios.get(DSE ? DSEON : DSEOFF, { headers });
    response.data['statusCode'] = {
      responseCode: response.status,
      text: response.statusText,
      description: null,
    };
    response.data['zType'] = zephyrType;
    return response.data
  } catch (e) {
    const { type: zephyrType } = zephyr;
    if (typeof (e as any).response.data === 'string') {
      const errorCopy = { ...(e as any).response };
      errorCopy.data = {};
      errorCopy.data['statusCode'] = {
        responseCode: errorCopy.status,
        text: 'Something went wrong on the server',
        description: 'Something went wrong on the server',
      };
      errorCopy.data['zType'] = zephyrType;
      return errorCopy.data;
    }
    const isDescriptionValid =
      'data' in (e as any) &&
      'description' in (e as any).data &&
      (e as any).data.description;
    (e as any).response.data['statusCode'] = {
      responseCode: (e as any).response.status,
      text: (e as any).response.statusText,
      description: isDescriptionValid ? (e as any).data.description : null,
    };
    (e as any).response.data['zType'] = zephyrType;
    return (e as any).response.data;
  }
};

const getZephyrMetData = async (
  znumber: number,
  start: string,
  end: string,
  dataConfig: DataConfig,
  contentType = 'application/json',
  bearerToken: string | null,
) => {
  try {
    const headers = {
      // 'access-control-request-headers': 'usertoken',
      // usertoken: auth,
      'access-control-request-headers': 'Authorization',
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'csv',
      'Accept-Language': i18n.language,
    };
    const response = await axios.get(
      `${config.zephyrAPI
      }${APIPaths.GetMeasurementData.toLowerCase()}/${znumber}/${start}/${end}/AB/0/metCore/${dataConfig.ctv
      }`,
      { headers },
    );
    return response.data.data;
  } catch (e) {
    return [];
  }
};

const getZephyrHistoricalData = async (
  // Deprecated
  zNumber: number,
  start: string,
  end: string,
  dataConfig: DataConfig,
  bearerToken: string | null,
) => {
  try {
    const response = await axios.get(
      `${config.zephyrAPI
      }${APIPaths.dataForViewBySlotsAveraged.toLowerCase()}/${zNumber}/${start}/${end}/AB/${dataConfig.ctv
      }/${dataConfig.averagingChain}/JSON/api`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return false;
  }
};

// Downloads
const downloadCalibrationCertificate = (zNumber: number) => {
  try {
    const auth = getAuth();
    (window as any).location = `${config.zephyrAPI
      }${APIPaths.downloadCalibrationCertificate.toLowerCase()}/${auth}/${zNumber}`;
  } catch (e) { }
};

const downloadDataCSV = (
  // Deprecated
  zNumber: number,
  startDT: string,
  endDT: string,
  slot: string,
  timeAveraging: string,
) => {
  try {
    const auth = getAuth();
    (window as any).location = `${config.zephyrAPI
      }${APIPaths.csvDataDownload.toLowerCase()}/${auth}/${zNumber}/${startDT}/${endDT}/${slot}/def/6/CSV/download`;
  } catch (e) { }
};

const downloadDataKML = async (
  znumber: number,
  start: string,
  end: string,
  slot: string,
  species: string,
  dataConfig: DataConfig,
  bearerToken: string | null,
) => {
  try {
    const headers = {
      // 'access-control-request-headers': 'usertoken, content-type',
      'access-control-request-headers': 'Authorization, content-type',
      Authorization: `Bearer ${bearerToken}`,
      'content-type': 'text/kml',
    };
    const data = {
      KmlRequest: '',
    };
    const response = await axios
      .get(
        `${config.zephyrAPI
        }${APIPaths.GetMeasurementData.toLowerCase()}/${znumber}/${start}/${end}/${slot}/0/${species},latitude,longitude/${dataConfig.ctv
        }`,
        { headers, data },
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${znumber}_${species}.kml`);
        document.body.appendChild(link);
        link.click();
      });
    return response;
  } catch (e) {
    return [];
  }
};

// Alerts API Requests
const deleteAirAlert = async (alertId: number) => {
  const auth = getAuth();
  const requestConfig = {
    headers: {
      usertoken: auth,
    },
  };
  try {
    const response = await axios.delete(
      `${config.alertsAPI}${APIPaths.airAlertsApi}${APIPaths.deleteAirAlert}/${alertId}`,
      requestConfig,
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

const updateAlertParameter = async (parameters: APIAirAlertParameter[]) => {
  const auth = getAuth();
  const requestConfig = {
    headers: {
      usertoken: auth,
    },
  };
  try {
    const response = await axios.post(
      `${config.alertsAPI}${APIPaths.airAlertsApi}${APIPaths.updateAlertParameters}`,
      parameters,
      requestConfig,
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

const updateAlertTargets = async (targets: APIAirAlertTarget) => {
  const auth = getAuth();
  const requestConfig = {
    headers: {
      usertoken: auth,
    },
  };
  try {
    const response = await axios.post(
      `${config.alertsAPI}${APIPaths.airAlertsApi}${APIPaths.updateAlertTargets}`,
      targets,
      requestConfig,
    );
    return response.data;
  } catch (e) {
    return [];
  }
};
const AddAlertTarget = async (target: APIAirAlertTarget) => {
  const auth = getAuth();
  const requestConfig = {
    headers: {
      usertoken: auth,
    },
  };
  try {
    const response = await axios.put(
      `${config.alertsAPI}${APIPaths.airAlertsApi}${APIPaths.addAlertTarget}`,
      target,
      requestConfig,
    );
    return response.data;
  } catch (e) {
    return [];
  }
};
const removeAlertTarget = async (target: APIAirAlertTarget) => {
  const auth = getAuth();
  const requestConfig = {
    headers: {
      usertoken: auth,
    },
  };
  try {
    const response = await axios.post(
      `${config.alertsAPI}${APIPaths.airAlertsApi}${APIPaths.removeAlertTarget}/${target.alertId}`,
      target,
      requestConfig,
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

const getAirAlertTypes = async () => {
  const auth = getAuth();
  const requestConfig = {
    headers: {
      usertoken: auth,
      'Accept-Language': i18n.language,
    },
  };
  try {
    const response = await axios.get(
      `${config.alertsAPI}${APIPaths.airAlertsApi}${APIPaths.getAirAlertTypes}`,
      requestConfig,
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

const getAirAlerts = async () => {
  const auth = getAuth();
  const requestConfig = {
    headers: {
      usertoken: auth,
      'Accept-Language': i18n.language,
    },
  };
  try {
    const response = await axios.get(
      `${config.alertsAPI}${APIPaths.airAlertsApi}${APIPaths.getAirAlerts}`,
      requestConfig,
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

const postAirAlert = async (alert: APIAirAlertPost) => {
  const auth = getAuth();
  const requestConfig = {
    headers: {
      usertoken: auth,
    },
  };
  try {
    const alerts = { ...alert };
    const { Alerts: { JsonInput } } = alerts;
    const filteredZNumbers = JsonInput[0].Znumbers.filter((zNumber: number | null) => zNumber !== null);
    alerts.Alerts.JsonInput[0].Znumbers = filteredZNumbers;
    const response = await axios.put(
      `${config.alertsAPI}${APIPaths.airAlertsApi}${APIPaths.postAirAlerts}`,
      alerts,
      requestConfig,
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

const updateAirAlert = async (alert: APIAirAlertPost) => {
  const auth = getAuth();
  const requestConfig = {
    headers: {
      usertoken: auth,
    },
  };
  try {
    const response = await axios.post(
      `${config.alertsAPI}${APIPaths.airAlertsApi}${APIPaths.updateAirAlert}`,
      alert.Alerts,
      requestConfig,
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

// External API Requests
const getLocation = async (latLon: LocationCoordinates) => {
  try {
    const response = await axios.get(
      `${APIPaths.getLocationGoogle}?latlng=${latLon}&key=${keys.GoogleMaps}`,
      {
        headers: {
          'Accept-Language': i18n.language,
        },
      },
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

const getLocationFromPlaceId = async (placeId: string) => {
  try {
    const response = await axios.get(
      `${APIPaths.getLocationGoogle}?place_id=${placeId}&key=${keys.GoogleMaps}`,
      {
        headers: {
          'Accept-Language': i18n.language,
        },
      },
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

const setLocation = async (location: string, searchBias: string) => {
  try {
    const response = await axios.get(
      `${APIPaths.getLocationGoogle}?address=${location}&key=${keys.GoogleMaps}&bounds=${searchBias}`,
      {
        headers: {
          'Accept-Language': i18n.language,
        },
      },
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

export const getLocationSuggestions = async (
  search: string,
  searchBias: number[],
  suggestionsCallback: any,
) => {
  try {
    const autoCompleteService = new google.maps.places.AutocompleteService();
    const biasBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(searchBias[0], searchBias[1]),
      new google.maps.LatLng(searchBias[2], searchBias[3]),
    );
    autoCompleteService.getPlacePredictions(
      { input: search, bounds: biasBounds },
      suggestionsCallback,
    );
  } catch (e) {
    return [];
  }
};

// Browser API Requests
const fireTrackingEvent = (
  action: string,
  category: string,
  label: string,
  value: number,
) => {
  const optOutCookie = Cookies.get(permissionCookieSettings.name);
  // dataLayer won't exist for non-qualified analytics users
  if (TagManager.dataLayer && optOutCookie !== 'true') {
    const event = 'userEvent';
    const eventProps = { action, category, value, label };
    // Push to dataLayer
    const tagManagerArgs = {
      dataLayer: {
        event,
        eventProps,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }
};

// Master Auth Token

const getMasterAuthToken = async (username?: string, password?: string) => {
  const auth = btoa(
    `${username ? username : localStorage.getItem('user')}:${password ? password : localStorage.getItem('key')
    }`,
  );
  try {
    const response = await axios.get(
      `${config.masterAuthAPI}authuser?auth=${auth}`,
      {
        headers: {
          'Accept-Language': i18n.language,
        },
      },
    );
    return response.data.token;
  } catch (e) {
    return false;
  }
};

// MyAir API

// https://service-dev.earthsense.co.uk/myair/api/dev/

const getUserViews = async (bearerToken: string | null) => {
  try {
    const response = await axios.get(`${config.myAirApi}analyticsview`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

const saveView = async (data: any, bearerToken: string | null) => {
  try {
    const response = await axios.post(
      `${config.myAirApi}analyticsview`,
      data,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

const deleteView = async (viewId: number, bearerToken: string | null) => {
  try {
    const response = await axios.delete(
      `${config.myAirApi}analyticsview/${viewId}`,
      {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

export default {
  addVZephyr,
  getVZephyrs,
  getVZephyrData,
  editVZephyr,
  getUserVZephyrConfig,
  unsubFromVZephyr,
  checkValidToken,
  apiGetWifiCredentials,
  apiRenameUnitWifiNetwork,
  createZephyrAlias,
  deleteAirAlert,
  downloadCalibrationCertificate,
  downloadDataCSV,
  downloadDataKML,
  fireTrackingEvent,
  getAirAlertTypes,
  getAirAlerts,
  getAurns,
  getAveragingPeriods,
  getCachedZephyrHistoricalData,
  getCapabilities,
  getCartridge,
  getDataConfig,
  getDisplayConfig,
  getFetchToken,
  getLatestMetDataForUser,
  getLocation,
  getLocationFromPlaceId,
  getLocationSuggestions,
  getOverlays,
  getPublicAdvice,
  getPolarPlots,
  getSAAtPoint,
  getAnnualAvgAtCoords,
  getStylegroups,
  getTimePeriods,
  getTodayData,
  getUserAgreements,
  getUserConfig,
  getUserInfo,
  getUserFeedbackQuestions,
  getZephyrMeasurementData,
  getUserZephyrsWithHistory,
  getUserZephyrsWithHistoryAndAQ,
  getValueAtPoint,
  getDataAtCoords,
  getVersions,
  getZephyrMetData,
  getZephyrs,
  getZephyrHistoricalData,
  postAirAlert,
  updateAlertParameter,
  updateAlertTargets,
  postUserFeedback,
  signUserAgreement,
  submitContactForm,
  setLocation,
  setShowTour,
  updateAirAlert,
  AddAlertTarget,
  removeAlertTarget,
  updateUserLatestVersionViewed,
  getMasterAuthToken,
  getProxyVZephyrData,
  getUserViews,
  saveView,
  deleteView,
};
