import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import DownloadIcon from '../../../assets/images/download.svg';
import { generateCSVName } from '../../../utils/functions/dataAnalytics';
import { ReduxState, Zephyr } from '../../../utils/interface';
import { debounce } from '../ReactGridLayout/ReactGridLayout';
import saveAs from 'file-saver';
import ComparisonTooltip from '../DataAnalyticsChartCtas/ComparisonTooltip';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setChartViewMode, ChartViewMode } from '../../../actions/chartViewMode';
import { styled } from '@mui/material/styles';


export const FilterBar2 = ({
  selectedWeatherZephyr,
  getSelectedZephyrs,
  selectedView,
  // setSelectedView,
  getCsvData,
  unitHistories,
  networkViewUnitList,
  comparisonViewZephyrs,
  handleFilterBarUpdates,
  getComparisonViewCSV
}: any) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [zephyrsDataCounts, setZephyrsDataCounts] = useState({
    zephyrsCount: 0,
    virtualZephyrsCount: 0,
    aurnsCount: 0,
  });
  const [isWarningTooltipOpened, setIsWarningTooltipOpened] = useState(false);
  const [errorZephyr, setErrorZephyr] = useState<number | null>(null);

  const selectedZephyrs =
    ['deviceView', 'comparisonView'].includes(selectedView) ? getSelectedZephyrs() : networkViewUnitList;

  const debouncedCalculatePrevNextVisibility = useCallback(
    debounce(() => {
      if (!carouselRef.current) return;
      calculatePrevNextVisibility();
    }, 250),
    [],
  );

  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;
    setIsAtEnd(
      carousel.scrollWidth - carousel.offsetWidth <= carousel.scrollLeft,
    );

    window.addEventListener('resize', debouncedCalculatePrevNextVisibility);
    window.addEventListener('scroll', debouncedCalculatePrevNextVisibility);

    return () => {
      window.removeEventListener(
        'resize',
        debouncedCalculatePrevNextVisibility,
      );
      window.removeEventListener(
        'scroll',
        debouncedCalculatePrevNextVisibility,
      );
    };
  }, [carouselRef?.current, debouncedCalculatePrevNextVisibility]);

  useEffect(() => {
    if (selectedView === 'networkView' && networkViewUnitList.length) {
      const counts = {
        zephyrsCount: 0,
        virtualZephyrsCount: 0,
        aurnsCount: 0,
      };

      networkViewUnitList.forEach((item: Zephyr) => {
        switch (item.type) {
          case 0:
            counts.zephyrsCount += 1;
            break;
          case 1:
            counts.aurnsCount += 1;
            break;
          case 100:
            counts.virtualZephyrsCount += 1;
            break;
        }
      });

      setZephyrsDataCounts(counts);
    }
  }, [networkViewUnitList, selectedView]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    const carousel = carouselRef.current as HTMLDivElement | null | any;
    if (!carousel) return;
    setIsDragging(true);
    setStartX(e.pageX - carousel.offsetLeft);
    setScrollLeft(carousel.scrollLeft);
    calculatePrevNextVisibility();
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
    calculatePrevNextVisibility();
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    calculatePrevNextVisibility();
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    const carousel = carouselRef.current;
    if (!carousel) return;
    e.preventDefault();
    const x = e.pageX - carousel.offsetLeft;
    const walk = (x - startX) * 1; // Adjust the scroll speed

    requestAnimationFrame(() => {
      const carousel = carouselRef.current;
      if (!carousel) return;
      carousel.scrollLeft = scrollLeft - walk;
    });
  };

  const calculatePrevNextVisibility = () => {
    const carousel = carouselRef.current as HTMLDivElement | null | any;
    if (!carousel) return;
    setIsAtStart(carousel.scrollLeft === 0);
    setIsAtEnd(
      carousel.scrollWidth - carousel.offsetWidth <= carousel.scrollLeft,
    );
  };

  const handleScroll = () => {
    calculatePrevNextVisibility();
  };

  const handleNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const carousel = carouselRef.current;
    if (!carousel) return;
    carousel.scrollLeft += carousel.offsetWidth;
    calculatePrevNextVisibility();
  };

  const handlePrev = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const carousel = carouselRef.current;
    if (!carousel) return;
    carousel.scrollLeft -= carousel.offsetWidth;
    calculatePrevNextVisibility();
  };

  const getPointerEvents = (zephyr: Zephyr) => {
    if (selectedView && selectedView === 'comparisonView') {
      if (comparisonViewZephyrs.length === 4) {
        return comparisonViewZephyrs.find((z: Zephyr) => z.name === zephyr.name) ? 'initial' : 'none';
      }
      return 'initial';
    }
    return undefined; // Or any default value you prefer
  };

  const getIcon = (zephyr: any) => {
    if (comparisonViewZephyrs.find((z: Zephyr) => z.id === zephyr.id)) {
      return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M12 4L4 12M4 4L12 12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    }
    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M7.99998 3.33331V12.6666M3.33331 7.99998H12.6666" stroke="#2D3748" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  };

  const handleComparisonZIPDownload = () => {
    const zipName = generateCSVName(unitHistories, 'test', selectedWeatherZephyr, selectedView);
    getComparisonViewCSV().then((content: any) => {
      saveAs(content, zipName);
    })
  };

  if (!selectedZephyrs?.length) return null;
  return (
    <div className="d-flex w-100 align-items-center justify-content-between bg-grey-100">
      <div className="w-100 filter-item2 d-flex align-items-center justify-content-between">
        <div
          className={`w-100 nav-bar-carousel gap-4 ${
            isAtStart ? 'at-start' : ''
          } ${isAtEnd ? 'at-end' : ''}`}
          onScroll={handleScroll}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
        >
          
          <SelectChartMode />
          {/* <select
            name="view"
            defaultValue={selectedView}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setSelectedView(e.target.value)
            }
            className="view-selector no-style"
          >
            <option value="deviceView">Device view</option>
            <option value="networkView">Network view</option>
            <option value="comparisonView">Comparison view</option>
          </select> */}
          {!isAtStart && (
            <div className="button-wrap prev">
              <button
                className={!isAtStart ? 'displayed' : 'hidden'}
                onClick={handlePrev}
                onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) =>
                  e.stopPropagation()
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M15 18L9 12L15 6"
                    stroke="#1A202C"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          )}
          <div className="carousel" data-tour="step-3" ref={carouselRef}>
            {['deviceView', 'comparisonView'].includes(selectedView) ? (
              selectedZephyrs.map((zephyr: Zephyr, i: number) => (
                <div
                  title={zephyr.name}
                  className={`${
                    selectedView === 'comparisonView' &&
                    +zephyr.id === errorZephyr
                      ? 'error-zephyr'
                      : ''
                  } zephyr-item selector ${
                    (selectedView === 'comparisonView' &&
                      comparisonViewZephyrs.find(
                        (z: Zephyr) => z.id === zephyr.id,
                      )) ||
                    (selectedView === 'deviceView' &&
                      zephyr?.id === selectedWeatherZephyr?.id)
                      ? 'selected'
                      : ''
                  }`}
                  key={i}
                  onClick={() => {
                    if (selectedView === 'comparisonView') {
                      if (
                        comparisonViewZephyrs.length === 4 &&
                        !comparisonViewZephyrs.find(
                          (z: Zephyr) => z.id === zephyr.id,
                        )
                      ) {
                        !isWarningTooltipOpened &&
                          setIsWarningTooltipOpened(true);
                        if (+zephyr.id !== errorZephyr)
                          setErrorZephyr(+zephyr.id);
                        return;
                      }
                      isWarningTooltipOpened &&
                        setIsWarningTooltipOpened(false);
                      typeof errorZephyr === 'number' && setErrorZephyr(null);
                    }
                    handleFilterBarUpdates(selectedView, zephyr);
                  }}
                >
                  <span>{zephyr.name}</span>
                  {selectedView === 'comparisonView' && getIcon(zephyr)}
                </div>
              ))
            ) : (
              <DeviceCounts {...zephyrsDataCounts} />
            )}
            {selectedView === 'comparisonView' && isWarningTooltipOpened && (
              <ComparisonTooltip
                setIsWarningTooltipOpened={setIsWarningTooltipOpened}
                setErrorZephyr={setErrorZephyr}
              />
            )}
          </div>
          {!isAtEnd && (
            <div className="button-wrap next">
              <button
                className={!isAtEnd ? 'displayed' : 'hidden'}
                onClick={handleNext}
                onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) =>
                  e.stopPropagation()
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9 18L15 12L9 6"
                    stroke="#1A202C"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          )}
          <div className="cta" data-tour="step-7" style={{ flexShrink: 0, marginLeft: "auto" }}>
            <DownloadCSVHistoriesButton
              unitHistories={unitHistories}
              getCsvData={getCsvData}
              selectedWeatherZephyr={selectedWeatherZephyr}
              selectedView={selectedView}
              handleComparisonZIPDownload={handleComparisonZIPDownload}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const DownloadCSVHistoriesButton = ({ unitHistories, getCsvData, selectedWeatherZephyr, selectedView, handleComparisonZIPDownload }: any) => {
  if (!unitHistories) return null;

  return selectedWeatherZephyr && (
    <div>
      {selectedView !== 'comparisonView' ? <CSVLink
        data={getCsvData()}
        filename={generateCSVName(unitHistories, 'test', selectedWeatherZephyr, selectedView)}
      >
        <button
          className="prompt-button download-csv-button h-40 button-icon-left d-flex align-items-center"
          type="button"
        >
          <img src={DownloadIcon} />
          Download CSV
        </button>
      </CSVLink> : <button
        className="prompt-button download-csv-button h-40 button-icon-left d-flex align-items-center"
        type="button"
        onClick={handleComparisonZIPDownload}
      >
        <img src={DownloadIcon} />
        Download CSV
      </button>}
    </div>
  );
};

const DeviceCounts = ({
  zephyrsCount,
  virtualZephyrsCount,
  aurnsCount,
}: {
  zephyrsCount: number;
  virtualZephyrsCount: number;
  aurnsCount: number;
}) => {
  const pluralize = (count: number, singular: string) => {
    return `${count} ${singular}${count === 0 ? '' : 's'}`;
  };

  return (
    <div className="d-flex justify-content-between align-items-center device-count-network-view">
      <div>{pluralize(zephyrsCount, 'Zephyr')} | </div>
      <div>{pluralize(virtualZephyrsCount, 'Virtual Zephyr')} | </div>
      <div>{pluralize(aurnsCount, 'AURN')}</div>
    </div>
  );
};

function SelectChartMode() {
  const dispatch = useDispatch();
  const isOpenFromRedux = useSelector((state: ReduxState) => state.selectChartModeOpen);
  const [localOpen, setLocalOpen] = useState(false);
  
  const handleOpen = () => {
    setLocalOpen(true);
    dispatch({ type: "select-chart-mode-toggle" });
  };
  
  const handleClose = () => {
    setLocalOpen(false);
    dispatch({ type: "select-chart-mode-toggle" });
  };
  
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const newView = event.target.value as ChartViewMode
    dispatch(setChartViewMode(newView));
  }
  
  const selectedView = useSelector((state: ReduxState) => state.selectedChartViewMode)
  
  return (
    <CustomSelect
      labelId="select-chart-mode"
      id="select-chart-mode"
      value={selectedView}
      onChange={handleChange}
      open={isOpenFromRedux || localOpen}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <CustomMenuItem value={"deviceView"}>Device view</CustomMenuItem>
      <CustomMenuItem value={"networkView"}>Network view</CustomMenuItem>
      <CustomMenuItem value={"comparisonView"}>Comparison view</CustomMenuItem>
    </CustomSelect>
  )
}

const CustomSelect = styled(Select)`
  font-size: 14px;
  background-color: #e2e8f0;
  min-width: 160px;
  border-radius: 2px;

  & [role="combobox"] {
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 600;
  }

 & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`

const CustomMenuItem = styled(MenuItem)`
  font-size: 14px;
`