// Type Imports
import { Dispatch } from 'redux';
import {
  CHANGE_OVERLAY_LAYER,
  CHANGE_OVERLAY_OPACITY,
  SET_MAPPAIR_POINT_MODE_OFF,
  SET_MAPPAIR_POINT_MODE_ON,
  SET_ANNUAL_AVERAGE,
  SHOW_AQMA_LAYER,
  SHOW_OVERLAY_LAYER,
  SHOW_SATELLITE_LAYER,
  SHOW_SCHOOLS_LAYER,
  SHOW_AA_LAYER,
  SHOW_SMOKE_CONTROL_ZONES,
} from './types';

// Action Creators
export const changeOverlayLayer = (overlay: string) => (dispatch: Dispatch) => {
  dispatch({
    type: CHANGE_OVERLAY_LAYER,
    payload: overlay,
  });
};

export const changeOverlayOpacity = (opacity: number) => (
  dispatch: Dispatch,
) => {
  dispatch({
    type: CHANGE_OVERLAY_OPACITY,
    payload: opacity,
  });
};

export const setMappairPointModeOff = () => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MAPPAIR_POINT_MODE_OFF,
  });
};

export const setMappairPointModeOn = () => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MAPPAIR_POINT_MODE_ON,
  });
};

export const showAQMALayer = () => (dispatch: Dispatch) => {
  dispatch({
    type: SHOW_AQMA_LAYER,
    loading: true,
  });
};

export const showSchoolsLayer = () => (dispatch: Dispatch) => {
  dispatch({
    type: SHOW_SCHOOLS_LAYER,
    loading: true,
  });
};

export const showSmokeControlZones = () => (dispatch: Dispatch) => {
  dispatch({
    type: SHOW_SMOKE_CONTROL_ZONES,
    loading: true,
  });
};

export const showOverlayLayer = (isLayerOn?: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SHOW_OVERLAY_LAYER,
    payload: isLayerOn
  });
};

export const showSatelliteLayer = () => (dispatch: Dispatch) => {
  dispatch({
    type: SHOW_SATELLITE_LAYER,
  });
};

export const showAALayer = (isAALayerOn?: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SHOW_AA_LAYER,
    payload: isAALayerOn
  });
};

export const setAnnualAverage = (annualAvg: number) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_ANNUAL_AVERAGE,
    payload: annualAvg
  })
}