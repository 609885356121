// Package Imports
import React, { useState, useEffect, useRef, useContext } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// Hooks
import { useLocation } from 'react-router-dom';

// Components
import Modal from '../shared_components/Modal';
import NetworkCredentialsModal from './NetworkCredentialsModal';

// Action Imports
import {
  setDraggedUnit,
  setZephyrInEditMode,
  setZephyrs,
} from '../../actions/zephyrs';

// Util Imports
import utilsRequest from '../../utils/request';
import analyticsEventFirer from '../../utils/functions/analyticsEventFirer';
import {
  convertUnitTimeToLocalTime,
  getCurrentTimeZoneAbbreviation,
  getTimeOld,
} from '../../utils/functions/dateFinder';
import { getUnitTypeCountTxt } from '../../utils/functions/vZephyr';

// Const Imports
import { gaseousSlots, gtmEventIdentifiers } from '../../utils/consts';

// Asset Imports
import dragIcon from '../../assets/images/icon-drag.svg';

// Type Imports
import {
  Zephyr,
  ReduxState,
  VZephyrsConfig,
  ZephyrTypes,
  SlotsInfo,
  ZephyrCoverages,
} from '../../utils/interface';
import { zephyrHistory } from '../../utils/testConsts';
import { setZephyrCoveragesList } from '../../actions/dataAnalytics';
import Spinner from '../shared_components/Spinner';

// Component Interfaces
interface InitState {
  editMode: boolean;
  editNetwork: Zephyr | null;
  expanded: boolean;
  nameInput: string;
  optionsMenuOpen: boolean;
  subscribed: boolean;
}

interface AsideUnitProps {
  unitList: Zephyr[];
  dataLoading: boolean;
  mappairPointMode: boolean;
  prevUnit: Zephyr | null;
  selectUnit: Function;
  selectedUnit: number;
  setColour: Function;
  setDraggedUnit: Function;
  setZephyrInEditMode: Function;
  setSelectedVZephyrToDelete: Function;
  unSubSelectedVZephyr: Function;
  setZephyrs: Function;
  unit: Zephyr;
  vZephyrsConfig: VZephyrsConfig;
  bearerToken: string | null;
  selectedUnitIdPod: number;
  setFilteredZephyrs: Function;
  filteredZephyrs: Zephyr[];
  zephyrCoveragesList: ZephyrCoverages[];
  setZephyrCoveragesList: Function;
  isDataAvailabilityChartLoading: boolean;
}

// Component
const AsideUnit = ({
  unitList,
  dataLoading,
  mappairPointMode,
  prevUnit,
  selectUnit,
  selectedUnit,
  setColour,
  setDraggedUnit,
  setZephyrInEditMode,
  setZephyrs,
  unit,
  vZephyrsConfig,
  setSelectedVZephyrToDelete,
  unSubSelectedVZephyr,
  bearerToken,
  selectedUnitIdPod,
  setFilteredZephyrs,
  filteredZephyrs,
  zephyrCoveragesList,
  setZephyrCoveragesList,
  isDataAvailabilityChartLoading,
}: AsideUnitProps) => {
  // State
  const initState: InitState = {
    editMode: false,
    editNetwork: null,
    expanded: false,
    nameInput: unit.name,
    optionsMenuOpen: false,
    subscribed: true,
  };
  const { t: translate } = useTranslation();

  const [subscribed, setSubscribed] = useState(initState.subscribed);
  const [editMode, setEditMode] = useState(initState.editMode);
  const [editNetwork, setEditNetwork] = useState(initState.editNetwork);
  const [expanded, setExpanded] = useState(initState.expanded);
  const [nameInput, setNameInput] = useState(initState.nameInput);
  const [optionsMenuOpen, setOptionsMenuOpenMenuOpen] = useState(
    initState.optionsMenuOpen,
  );

  // Effects
  useEffect(() => {
    if (selectedUnit !== unit.zNumber) {
      handleNameChangeReset();
    }
  }, [selectedUnit]);

  useEffect(() => {
    if (unit.name) {
      setEditMode(false);
    }
  }, [unit.name]);

  useEffect(() => {
    if (editMode) {
      unitInputRef.current!.focus();
    }
  }, [editMode]);

  // Consts
  const location = useLocation();
  const unitInputRef = useRef<HTMLInputElement | null>(null);
  const dragImage: HTMLImageElement = new Image();
  dragImage.src = dragIcon;

  // Functions
  const handleNameChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    setNameInput(target.value);
  };

  const handleNameChangeReset = () => {
    setEditMode(initState.editMode);
    setNameInput(initState.nameInput);
  };

  const handleNameSubmit = async (unitType: number) => {
    if (nameInput && nameInput !== unit.name) {
      // Format name input to have capitalize the start of every word
      const formattedNameInput = nameInput.replace(
        /(^\w{1})|(\s+\w{1})/g,
        (match) => match.toUpperCase(),
      );

      let res = null;
      if (unitType === ZephyrTypes.virtual) {
        res = await utilsRequest.editVZephyr(
          unit.zNumber,
          nameInput,
          bearerToken,
        );
      } else {
        res = await utilsRequest.createZephyrAlias(
          unit.zNumber,
          formattedNameInput,
          bearerToken,
        );
      }

      if (res) {
        const newZephyr = { ...unit, name: formattedNameInput };
        const newZephyrList = unitList.map((listUnit) => {
          if (listUnit.zNumber === unit.zNumber) {
            const ammendedUnit = newZephyr;
            return ammendedUnit;
          }
          return listUnit;
        });
        if (newZephyrList.length === unitList.length) {
          setZephyrInEditMode(newZephyr);
          setZephyrs(newZephyrList);
          setNameInput(formattedNameInput);
        }
      } else {
        handleNameChangeReset();
        setEditMode(false);
      }
    } else {
      handleNameChangeReset();
      setEditMode(false);
    }
  };

  const getCertificate = (zNumber: number) => {
    utilsRequest.downloadCalibrationCertificate(zNumber);
    // Analytics
    analyticsEventFirer(gtmEventIdentifiers.unitCertificateDownload);
  };

  const handleDragStart = (e: React.DragEvent) => {
    // Check for IE as it doesn't support setDragImage
    const isIE = !!(document as any).documentMode;
    if (!isIE) {
      e.dataTransfer.setDragImage(dragImage, 0, 20);
    }
    setDraggedUnit(unit);
  };

  const unitTypeAsString = (unitType: number) => {
    switch (unitType) {
      case 0:
        return 'Zephyrs';
      case 1:
        return 'AURN';
      case 100:
        return 'Virtual Zephyrs';

      default:
        return '';
    }
  };

  const handleEditNetworkCredentials = (unit: Zephyr) => {
    setOptionsMenuOpenMenuOpen(false);
    setEditNetwork(unit);
  };

  const getUnitCartridgeData = async (unit: Zephyr) => {
    if (!unit.slotsInfo) {
      const packagedSlotsInfo: SlotsInfo = {
        slotA: null,
        slotB: null,
      };
      if (!isUnitRetired) {
        for await (const gS of gaseousSlots) {
          const slotSerial = unit[`${gS.label}_SN`];
          if (slotSerial) {
            const slotInfo = await utilsRequest.getCartridge(
              slotSerial,
              bearerToken,
            );
            if (slotInfo) {
              packagedSlotsInfo[gS.label] = slotInfo;
            }
          }
        }
      }
      const copyZephyrsList = [...unitList];
      const newData = copyZephyrsList.find((z) => z.id_pod === unit.id_pod);
      if (newData) newData.slotsInfo = packagedSlotsInfo;
      setZephyrs(copyZephyrsList);
    }
  };

  const changeDateFormat = (date: string) => {
    return (
      date.split(' ')[0].split('-').reverse().join('-') +
      ' ' +
      date.split(' ')[1]
    );
  };

  const addUnitToAnalyticsList = (unit: Zephyr) => {
    setFilteredZephyrs((prevState: Zephyr[]) => {
      const unitToChange = prevState.find(
        (z: Zephyr) => z.zNumber === unit.zNumber,
      );
      if (unitToChange) {
        const unitIdx = prevState.indexOf(unitToChange);
        unitToChange.isSelected = !unitToChange.isSelected;
        prevState[unitIdx] = unitToChange;
      }
      return [...prevState];
    });
    setZephyrCoveragesList(unit);
  };

  const preventComma = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ',') {
      event.preventDefault();
    }
  };

  let isUnitRetired = false;
  if (unit.userEndTimeDate) {
    const timestampDate = new Date(`${unit.userEndTimeDate}Z`);
    const currentDate = new Date();
    isUnitRetired = currentDate > timestampDate;
  }

  return (
    <>
      {(prevUnit && prevUnit.type !== unit.type) || prevUnit?.type == null ? (
        <div className="unit-divider">
          <h4>
            {unitTypeAsString(unit.type)}{' '}
            {getUnitTypeCountTxt(unitList, unit.type, vZephyrsConfig)}
          </h4>
        </div>
      ) : (
        <></>
      )}
      <li
        key={unit.zNumber}
        className={`unit-card unit-card-${unit.zNumber} ${
          selectedUnit === unit.zNumber ? 'active' : ''
        }${subscribed ? '' : 'hidden'}`}
        draggable={
          location.pathname.includes('data') &&
          !dataLoading &&
          selectedUnit !== unit.zNumber
        }
        onDragStart={(e) => handleDragStart(e)}
        data-user-tour={prevUnit === null ? "step-2" : undefined}
      >
        {/* <div
          className="aq-colour-strip"
          style={{
            backgroundColor: `${setColour(unit)}`,
          }}
        /> */}
        <div
          className={
            expanded &&
            (!mappairPointMode || location.pathname.includes('data'))
              ? unit.type === 0 && !isUnitRetired
                ? 'beacon-button active'
                : 'beacon-button aurn-active'
              : unit.type === 0 && !isUnitRetired
              ? 'beacon-button'
              : 'beacon-button no-toggle'
          }
          onClick={() => {
            if (
              !dataLoading &&
              selectedUnitIdPod !== unit.id_pod &&
              !location.pathname.includes('dataAnalytics')
            ) {
              selectUnit(unit);
            }
          }}
        >
          <header>
            {unit.type === 0 || unit.type === ZephyrTypes.virtual ? (
              <>
                <div className="selected-header">
                  {editMode ? (
                    <>
                      <input
                        ref={unitInputRef}
                        value={nameInput}
                        type="text"
                        onChange={(e) => handleNameChange(e)}
                        onBlur={() => handleNameSubmit(unit.type)}
                        onKeyDown={preventComma}
                        id="name-change-input"
                      />
                    </>
                  ) : (
                    <div className="header-wrapper">
                      <VZephyrBox
                        type={unit.type}
                        NO2={unit?.averageOfLastHourOfData?.NO2}
                        style={{
                          backgroundColor: `${setColour(unit)}`,
                        }}
                      />
                      <h4 id={`unit-header ${unit.type}`}>{unit.name}</h4>
                    </div>
                  )}

                  {!location.pathname.includes('analysis') ? (
                    <div className="options-container">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOptionsMenuOpenMenuOpen(!optionsMenuOpen);
                        }}
                      />
                      {optionsMenuOpen ? (
                        <ul
                          className="options-list"
                          onMouseLeave={() => setOptionsMenuOpenMenuOpen(false)}
                        >
                          <li
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setEditMode(true);
                            }}
                          >
                            {translate('AsideUnitEditName')}
                          </li>
                          {unit.type === ZephyrTypes.virtual ? (
                            <li
                              className={
                                vZephyrsConfig.new30Days >=
                                vZephyrsConfig.new30DaysMax
                                  ? 'disabled'
                                  : ''
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (
                                  vZephyrsConfig.new30Days <=
                                  vZephyrsConfig.new30DaysMax
                                ) {
                                  setSelectedVZephyrToDelete(unit);
                                }
                              }}
                            >
                              {translate('VZUnitDeleteName')}
                            </li>
                          ) : (
                            <></>
                          )}

                          {unit.HasCertificate ? (
                            <li
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                getCertificate(unit.zNumber);
                              }}
                            >
                              {translate('AsideUnitCertificateDownloadButton')}
                            </li>
                          ) : (
                            <></>
                          )}
                        </ul>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <>
                      {!isDataAvailabilityChartLoading && (
                        <label htmlFor={unit.name}>
                          <input
                            type="checkbox"
                            id={unit.name}
                            name={unit.name}
                            value={unit.name}
                            defaultChecked={unit.isSelected}
                            onChange={() => {
                              addUnitToAnalyticsList(unit);
                            }}
                          />
                        </label>
                      )}
                      {isDataAvailabilityChartLoading && (
                        <div
                          className="data-analytics-spinner"
                          style={{ position: 'absolute', right: 28 }}
                        >
                          <Spinner
                            additionalClass="header-spinner lds-spinner see-through"
                            on={isDataAvailabilityChartLoading}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
                {!location.pathname.includes('analysis') && (
                  <div className="unit-headline-status">
                    {!isUnitRetired && unit.type !== ZephyrTypes.virtual ? (
                      <>
                        <ul className="unit-symbol-list">
                          <li>
                            <div className="unit-symbol-list-header unit-connection margin-center">
                              <div
                                className={`symbol-container ${
                                  parseFloat(unit.externalVoltage ?? '') >=
                                    16.5 &&
                                  getTimeOld(
                                    unit.lastConnectionDT,
                                    'YYYY-MM-DDZHH:mm:ss',
                                    'hours',
                                  ) <= 24
                                    ? 'mains'
                                    : parseFloat(unit.externalVoltage ?? '') >=
                                      16.5
                                    ? 'mains-active-grey'
                                    : 'mains-grey'
                                }`}
                              >
                                <div className="unit-symbol-voltage" />
                                <div className="symbol-hover">
                                  {parseFloat(unit.externalVoltage ?? '') >=
                                    16.5 &&
                                  getTimeOld(
                                    unit.lastConnectionDT,
                                    'YYYY-MM-DDZHH:mm:ss',
                                    'hours',
                                  ) <= 24
                                    ? 'The Zephyr is running off external power.'
                                    : parseFloat(unit.externalVoltage ?? '') >=
                                      16.5
                                    ? 'Last known status running off external power.'
                                    : 'No External Power.'}
                                </div>
                              </div>
                            </div>
                            {expanded ? (
                              <>
                                <div className="unit-symbol-list-expanded">
                                  <div className="unit-expanded-descriptor">
                                    {'Power'}
                                  </div>
                                  <div className="unit-expanded-value">
                                    {parseFloat(unit.externalVoltage ?? '') >=
                                    17
                                      ? 'Connected'
                                      : 'N/A'}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </li>
                          <li>
                            <div className="unit-symbol-list-header">
                              <div
                                className={`symbol-container margin-center ${translate(
                                  RenderBattery(
                                    parseFloat(unit.batteryVoltage ?? '') ?? 0,
                                    unit.batteryCharge ?? 0,
                                  )[0],
                                )}`}
                              >
                                <div
                                  className={`unit-symbol-battery ${
                                    getTimeOld(
                                      unit.lastConnectionDT,
                                      'YYYY-MM-DDZHH:mm:ss',
                                      'hours',
                                    ) >= 24
                                      ? 'grey'
                                      : ''
                                  }`}
                                />
                                <div className="symbol-hover">
                                  {getTimeOld(
                                    unit.lastConnectionDT,
                                    'YYYY-MM-DDZHH:mm:ss',
                                    'hours',
                                  ) >= 24
                                    ? 'Last known status ' +
                                      translate(
                                        RenderBattery(
                                          parseFloat(
                                            unit.batteryVoltage ?? '',
                                          ) ?? 0,
                                          unit.batteryCharge ?? 0,
                                        )[2],
                                      )
                                    : translate(
                                        RenderBattery(
                                          parseFloat(
                                            unit.batteryVoltage ?? '',
                                          ) ?? 0,
                                          unit.batteryCharge ?? 0,
                                        )[2],
                                      )}
                                </div>
                              </div>
                              <div className="symbol-value"></div>
                            </div>
                            {expanded ? (
                              <>
                                <div className="unit-symbol-list-expanded">
                                  <div className="unit-expanded-descriptor">
                                    {translate('AsideUnitBatteryPower')}
                                  </div>
                                  <div className="unit-expanded-value">
                                    {translate(
                                      RenderBattery(
                                        parseFloat(
                                          unit.externalVoltage ?? '',
                                        ) ?? 0,
                                        unit.batteryCharge ?? 0,
                                      )[1],
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </li>
                          <li>
                            <div className="unit-symbol-list-header unit-sampling margin-center">
                              <div
                                className={`symbol-container ${
                                  unit.lastDataDT === 'None' ? 'warning' : ''
                                }`}
                              >
                                <div
                                  className={`unit-symbol-sampling ${
                                    RenderSampling(unit.SampPeriod)[1]
                                  }`}
                                />
                                <div className="symbol-hover">
                                  {translate(
                                    RenderSampling(unit.SampPeriod)[0],
                                  )}
                                </div>
                              </div>
                            </div>
                            {expanded ? (
                              <>
                                <div className="unit-symbol-list-expanded unit-sampling">
                                  <div>
                                    {RenderSampling(unit.SampPeriod)[2]}
                                  </div>
                                  <div className="unit-expanded-descriptor">
                                    {translate('AsideUnitSamplingData')}
                                  </div>
                                  <div className="unit-expanded-value">
                                    {unit.lastDataDT === 'None'
                                      ? translate('AsideUnitSamplingOff')
                                      : translate('AsideUnitSamplingOn')}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </li>

                          <li>
                            <div className="unit-symbol-list-header unit-connection margin-center">
                              <div
                                className={`symbol-container ${
                                  getTimeOld(
                                    unit.lastConnectionDT,
                                    'YYYY-MM-DDZHH:mm:ss',
                                    'hours',
                                  ) >= 24
                                    ? 'alert'
                                    : getTimeOld(
                                        unit.lastConnectionDT,
                                        'YYYY-MM-DDZHH:mm:ss',
                                        'hours',
                                      ) >= 2
                                    ? 'warning'
                                    : ''
                                }`}
                              >
                                <div className="unit-symbol-connection" />
                                <div className="symbol-hover">
                                  {getTimeOld(
                                    unit.lastConnectionDT,
                                    'YYYY-MM-DDZHH:mm:ss',
                                    'hours',
                                  ) >= 24
                                    ? translate('AsideUnitConnectionAlert')
                                    : getTimeOld(
                                        unit.lastConnectionDT,
                                        'YYYY-MM-DDZHH:mm:ss',
                                        'hours',
                                      ) >= 2
                                    ? translate('AsideUnitConnectionWarning')
                                    : translate('AsideUnitConnectionExplainer')}
                                </div>
                              </div>
                            </div>
                            {expanded ? (
                              <>
                                <div className="unit-symbol-list-expanded">
                                  <div className="unit-expanded-descriptor">
                                    {translate('AsideUnitConnectionNetwork')}
                                  </div>
                                  <div className="unit-expanded-value">
                                    {translate('AsideUnitConnectionGSM')}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </li>
                        </ul>
                        {expanded ? (
                          <>
                            <div className="lat-lon__wrapper">
                              <div className="lat-lon">
                                <dt>Location:&nbsp;</dt>
                                <dd className="lat-lon-value">
                                  <a
                                    href={`https://www.google.com/maps/search/${unit.latitude},${unit.longitude}?sa=X&ved=2ahUKEwi5xfbHvOX7AhUNWcAKHULtAvkQ8gF6BAgIEAE`}
                                    target="_blank"
                                    className="location-lat-lon"
                                  >
                                    {unit.latitude}, {unit.longitude}
                                  </a>
                                </dd>
                              </div>
                            </div>
                            <div className="lat-lon__wrapper">
                              <div className="last-updated-location-title">
                                <dt>
                                  Updated:&nbsp;
                                  <span className="last-updated-location-time">
                                    {unit.latLngAltered
                                      ? changeDateFormat(unit.latLngAltered)
                                      : 'No last update information'}
                                  </span>
                                </dt>
                                {/* <dd className='last-updated-location-time' >{unit.latLngAltered}</dd> */}
                              </div>
                            </div>
                            <ul className="unit-symbol-list-metadata">
                              {gaseousSlots.map((gS) => (
                                <li key={gS.label}>
                                  {unit.slotsInfo ? (
                                    <div className="unit-symbol-list-header">
                                      <div className="symbol-container">
                                        <div
                                          className={`unit-cartridge

                                     ${
                                       unit.slotsInfo[gS.label]
                                         ? unit.slotsInfo[gS.label]!
                                             .CommercialName
                                         : 'test'
                                     }
                                     `}
                                        />

                                        <div className="symbol-hover">
                                          <webview
                                            dangerouslySetInnerHTML={{
                                              __html: translate(
                                                RenderCartridgeText(
                                                  unit.slotsInfo[gS.label]
                                                    ?.CommercialName,
                                                )[0],
                                              ),
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="unit-symbol-list-expanded">
                                        <div className="unit-expanded-descriptor">
                                          {gS.HTMLLabel}
                                        </div>
                                        <div className="unit-expanded-value">
                                          {unit.slotsInfo[gS.label]
                                            ? unit.slotsInfo[gS.label]!
                                                .CommercialName
                                            : translate(
                                                'AsideUnitCartridgeBlank',
                                              )}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div>Loading...</div>
                                  )}
                                </li>
                              ))}
                              {/* <li>
                            <div className="unit-symbol-list-header">
                              <div className="symbol-container">
                                <div className="unit-station met" />
                              </div>
                            </div>
                            <div className="unit-symbol-list-expanded">
                              <div className="unit-expanded-descriptor">
                                Sensors
                              </div>
                              <div className="unit-expanded-value">Met</div>
                            </div>
                          </li> */}
                            </ul>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </>
            ) : location.pathname.includes('analysis') ? (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h4>{unit.name}</h4>
                {!isDataAvailabilityChartLoading && (
                  <label htmlFor={unit.name}>
                    <input
                      type="checkbox"
                      id={unit.name}
                      name={unit.name}
                      value={unit.name}
                      defaultChecked={unit.isSelected}
                      onChange={() => {
                        addUnitToAnalyticsList(unit);
                      }}
                      style={{ marginTop: 0 }}
                    />
                  </label>
                )}
                {isDataAvailabilityChartLoading && (
                  <div
                    className="data-analytics-spinner"
                    style={{ position: 'absolute', right: 28 }}
                  >
                    <Spinner
                      additionalClass="header-spinner lds-spinner see-through"
                      on={isDataAvailabilityChartLoading}
                    />
                  </div>
                )}
              </div>
            ) : (
              <h4>{unit.name}</h4>
            )}
          </header>

          {unit.type === ZephyrTypes.virtual ? (
            <></>
          ) : (
            <div className="unit-updated">
              <div className="unit-updated-info">
                {unit.type === 0 ? (
                  <dl>
                    {isUnitRetired ? (
                      <>
                        <dt>{translate('AsideUnitStatusRetired')}</dt>
                        <dd>
                          {moment(
                            unit.userEndTimeDate,
                            'YYYY-MM-DDZHH:mm:ss',
                          ).format('DD-MM-YYYY HH:mm')}
                        </dd>
                      </>
                    ) : (
                      <>
                        <dt>{translate('AsideUnitUpdatedHeader')}</dt>
                        <dd>
                          {unit.lastConnectionDT
                            ? `${moment(
                                convertUnitTimeToLocalTime(
                                  unit.lastConnectionDT,
                                ),
                                'YYYY-MM-DDZHH:mm:ss',
                              ).format(
                                'DD-MM-YYYY HH:mm',
                              )} ${getCurrentTimeZoneAbbreviation()}`
                            : 'NA'}
                        </dd>
                      </>
                    )}
                  </dl>
                ) : (
                  <></>
                )}
                {unit.type === 0 ? (
                  <div className="unit-updated-separator">·</div>
                ) : (
                  <></>
                )}
                {unit.type === 0 ? (
                  <>
                    <dl style={{ display: 'flex' }}>
                      <dt>{translate('AsideUnitIdHeader')}</dt>
                      <dd>{unit.zNumber}</dd>
                    </dl>
                  </>
                ) : (unit.type as any) === ZephyrTypes.virtual ? (
                  <dl>
                    <dt>{translate('AsideUnitType')}</dt>
                    <dd>{translate('AsideUnitTypeVirtualZephyr')}</dd>
                  </dl>
                ) : (
                  <dl>
                    <dt>{translate('AsideUnitType')}</dt>
                    <dd>{translate('AsideUnitTypeAURN')}</dd>
                  </dl>
                )}
              </div>
              {unit.type === 0 &&
              !isUnitRetired &&
              !location.pathname.includes('analysis') ? (
                <button
                  type="button"
                  className={expanded ? 'expanded' : ''}
                  onClick={async (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (!dataLoading) {
                      setExpanded(!expanded);
                      if (!expanded) {
                        await getUnitCartridgeData(unit);
                      }
                    }
                  }}
                />
              ) : (
                <div className="expand-placeholder" />
              )}
            </div>
          )}
        </div>
      </li>

      <Modal
        on={!!editNetwork}
        modalComponent={
          <NetworkCredentialsModal
            closeModal={() => setEditNetwork(null)}
            confirmSubmit={() => {}}
            unit={editNetwork}
          />
        }
      />
    </>
  );
};

function RenderSampling(samplingPeriod: number | null) {
  // const { t: translate } = useTranslation();

  if (samplingPeriod) {
    if (samplingPeriod >= 7 && samplingPeriod <= 20) {
      //return [translate('AsideUnitSamplingExplainerHigh'), 'High', 'High'];
      return ['AsideUnitSamplingExplainerHigh', 'High', 'High'];
      //return ['Cannot be determined', 'grey', 'N/A'];
    } else if (samplingPeriod >= 50 && samplingPeriod <= 70) {
      //return [translate('AsideUnitSamplingExplainerMed'), 'Normal', 'Normal'];
      return ['AsideUnitSamplingExplainerMed', 'Normal', 'Normal'];
      //return ['Cannot be determined', 'grey', 'N/A'];
    } else {
      return ['Cannot be determined', 'grey', 'N/A'];
      //return [translate('AsideUnitSamplingExplainerMed'), 'Normal', 'Normal'];
    }
  } else {
    return ['Cannot be determined', 'grey', 'N/A'];
    //return [translate('AsideUnitSamplingExplainerMed'), 'Normal', 'Normal'];
  }
}

function RenderCartridgeText(cartridgeLabel: string | undefined) {
  //const { t: translate } = useTranslation();

  if (cartridgeLabel) {
    if (cartridgeLabel === 'Standard') {
      //return translate('AsideUnitCartridgeExplainerStandard');
      return 'AsideUnitCartridgeExplainerStandard';
    } else if (cartridgeLabel === 'Enhanced') {
      //return translate('AsideUnitCartridgeExplainerEnhanced');
      return 'AsideUnitCartridgeExplainerEnhanced';
    } else if (cartridgeLabel === 'Enhanced+') {
      //return translate('AsideUnitCartridgeExplainerEnhancedPlus');
      return 'AsideUnitCartridgeExplainerEnhancedPlus';
    } else if (cartridgeLabel === 'Enhanced++') {
      //return translate('AsideUnitCartridgeExplainerEnhancedPP');
      return 'AsideUnitCartridgeExplainerEnhancedPP';
    }
  }
  return '';
}

const VZephyrBox = ({ type, NO2, style }: any) => {
  if (type !== 100) return null;
  return <div className="vzephyr-color-box" style={style} />;
};

function RenderBattery(batteryVoltage: number, batteryCharge: number) {
  //const { t: translate } = useTranslation();

  if (batteryVoltage) {
    if (batteryVoltage > 17.5) {
      return [
        'charging',
        //translate('AsideUnitBatteryCharging'),
        //translate('AsideUnitBatteryChargingDesc'),
        'AsideUnitBatteryCharging',
        'AsideUnitBatteryChargingDesc',
      ];
    } else if (batteryCharge < 5 || batteryVoltage == 0) {
      return [
        'alert',
        //translate('AsideUnitBatteryFlat'),
        //translate('AsideUnitBatteryFlatDesc'),
        'AsideUnitBatteryFlat',
        'AsideUnitBatteryFlatDesc',
      ];
    } else if (batteryCharge < 30) {
      return [
        'warning',
        //translate('AsideUnitBatteryDischarging'),
        //translate('AsideUnitBatteryFlatDesc'),
        'AsideUnitBatteryDischarging',
        'AsideUnitBatteryFlatDesc',
      ];
    } else {
      return [
        'charging',
        //translate('AsideUnitBatteryDischarging'),
        //translate('AsideUnitBatteryDischargingDesc'),
        'AsideUnitBatteryDischarging',
        'AsideUnitBatteryDischargingDesc',
      ];
    }
  } else {
    return [
      'alert',
      //translate('AsideUnitBatteryFlat'),
      //translate('AsideUnitBatteryFlatDesc'),
      'AsideUnitBatteryFlat',
      'AsideUnitBatteryFlatDesc',
    ];
  }
}

// Redux
const mapStateToProps = (state: ReduxState) => ({
  unitList: state.getZephyrs.zephyrs,
  bearerToken: state.auth.bearerToken,
  zephyrCoveragesList: state.unitHistoriesOptions.zephyrCoveragesList,
  isDataAvailabilityChartLoading:
    state.unitHistoriesOptions.isDataAvailabilityChartLoading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setDraggedUnit,
      setZephyrInEditMode,
      setZephyrs,
      setZephyrCoveragesList,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AsideUnit);
