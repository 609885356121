// Types Imports
import { LocationCoordinates, MappairSpecies } from '../interface';

// Util Imports
import utilsRequest from '../request';
import { formatGoogleAddress } from './googleFormatter';
import { dateFinder } from './dateFinder';

export const todayDataPackager = async (
  species: MappairSpecies,
  latlon: LocationCoordinates,
  bearerToken: string | null,
) => {
  const endDate = dateFinder(new Date(), 2, false).toISOString();
  const dates = { endDT: endDate };
  const defaultSpecies = 'relhum,temperature,combinedpollen';
  const speciesList = `${species},${defaultSpecies}`;
  const rawTodayData = await utilsRequest.getTodayData(
    speciesList,
    latlon,
    24,
    bearerToken,
    dates,
  );
  const googleLocation = await utilsRequest.getLocation(latlon);
  const locationDesc =
    googleLocation.results && googleLocation.results.length
      ? formatGoogleAddress(googleLocation.results[0].formatted_address)
      : 'Unknown';
  const enhancedTodayData = {
    ...rawTodayData,
    location: { latlon, desc: locationDesc },
  };
  return enhancedTodayData;
};
