// Package Imports
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

// Type Imports
import { Zephyr, VZephyrsConfig, ReduxState } from '../../utils/interface';
import utilsRequest from '../../utils/request';

import { addZephyrs } from '../../actions/zephyrs';
import { getUnitTypeCountTxt } from '../../utils/functions/vZephyr';
import { setContactModalOn } from '../../actions/modal';
// Component Interfaces
interface addZephyrModalProps {
  setZephyrModalOpen: Function;
  closePopup: Function;
  points: any;
  addZephyrs: Function;
  getUserVirtualZephyrs: Function;
  unitList: Zephyr[];
  vZephyrsConfig: VZephyrsConfig;
  setContactModalOn: Function;
  bearerToken: string | null;
}

export interface AddZephyrForm {
  name: string;
}

// Component
const AddZephyrModal = ({
  setZephyrModalOpen,
  closePopup,
  points,
  addZephyrs,
  unitList,
  getUserVirtualZephyrs,
  vZephyrsConfig,
  setContactModalOn,
  bearerToken,
}: addZephyrModalProps) => {
  const { t: translate } = useTranslation();

  const [name, setName] = useState('');
  const [invalidZephyrName, setInvalidZephyrName] = useState(false);
  const limitReached =
    vZephyrsConfig.active >= vZephyrsConfig.max &&
    vZephyrsConfig.new30Days >= vZephyrsConfig.new30DaysMax;
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!name) {
      setInvalidZephyrName(true);
      return;
    }
    if (name) {
      const { coordinates } = points;
      const res = await utilsRequest.addVZephyr(
        name,
        name,
        coordinates[1],
        coordinates[0],
        bearerToken,
      );

      if (res) {
        const virtualZephyrs = await getUserVirtualZephyrs(
          localStorage.getItem('isPublic'),
        );
        const vZephyrToAdd = virtualZephyrs.find(
          (vz: Zephyr) =>
            vz.name === name &&
            vz.latitude === coordinates[1] &&
            vz.longitude === coordinates[0],
        );

        if (virtualZephyrs && vZephyrToAdd) {
          addZephyrs([vZephyrToAdd]);
        }
        setZephyrModalOpen(false);
        closePopup(true);
      }
    }
  };

  const hdmsSplitter = (hdms: string) => {
    if (hdms) {
      const hdmsWithDelimiter = hdms.replace(/N/g, 'N,');
      const hdmsArr = hdmsWithDelimiter.split(',');
      return hdmsArr;
    }
    return '';
  };

  const vZephyrsTitle = () => {
    let title = '';
    if (limitReached) {
      title = `${translate('maxReachPrefix')} ${vZephyrsConfig.max} ${translate(
        'totalPostfix',
      )}`;
      return (
        <>
          <p className="text-mustard">{title}</p>
          <p>{translate('limitReachedSubtitle')}</p>
        </>
      );
    }

    title = `${translate('totalPrefix')} ${getUnitTypeCountTxt(
      unitList,
      100,
      vZephyrsConfig,
    )} ${translate('totalPostfix')}`;
    return <p>{title}</p>;
  };

  return (
    <div className="modal-container zephyr-modal-container d-flex flex-column">
      <button
        className="button close close-modal"
        onClick={() => setZephyrModalOpen(false)}
        type="button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 24 24"
          fill="#000"
        >
          <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
        </svg>
      </button>
      <h4>{translate('headerText')}</h4>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="form-content-container pb-2">
          <div className="row pb-5">{vZephyrsTitle()}</div>
          {limitReached ? null : (
            <>
              <div className="row pb-4">
                <div className="col-12 px-0">
                  <label className="" htmlFor="zephyrName">
                    {translate('zephyrNameLabel')}
                    <input
                      className={`${invalidZephyrName ? 'error' : ''}`}
                      type="text"
                      name="zephyrName"
                      id="zephyrName"
                      placeholder={
                        invalidZephyrName ? 'Zephyr Name Required' : ''
                      }
                      required
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        if (e.target.value) {
                          setInvalidZephyrName(false);
                        } else {
                          setInvalidZephyrName(true);
                        }
                      }}
                    />
                  </label>
                </div>
              </div>

              <div className="row pb-4">
                <div className="col-12 px-0">
                  <div>{hdmsSplitter(points?.hdms)[0]}</div>
                  <div>{hdmsSplitter(points?.hdms)[1]}</div>
                </div>
              </div>
            </>
          )}

          <div className="row d-flex justify-content-between">
            <button
              type="button"
              className="button tertiary"
              onClick={() => setZephyrModalOpen(false)}
            >
              {translate('secondaryCta')}
            </button>

            {limitReached ? (
              <button
                type="submit"
                className="button primary"
                value="Submit"
                onClick={(e) => {
                  setZephyrModalOpen(false);
                  closePopup(true);
                  setContactModalOn();
                }}
              >
                {translate('contactUsCtaTxt')}
              </button>
            ) : (
              <button
                disabled={name.length === 0}
                type="submit"
                className="button primary"
                value="Submit"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                {translate('primaryCta1')}
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

// Redux

const mapStateToProps = (state: ReduxState) => ({
  bearerToken: state.auth.bearerToken,
});


const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addZephyrs,
      setContactModalOn,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddZephyrModal);
