// Types Imports
import {
  CHANGE_OVERLAY_LAYER,
  CHANGE_OVERLAY_OPACITY,
  SET_MAPPAIR_POINT_MODE_ON,
  SET_MAPPAIR_POINT_MODE_OFF,
  SET_ANNUAL_AVERAGE,
  SHOW_AQMA_LAYER,
  SHOW_OVERLAY_LAYER,
  SHOW_SATELLITE_LAYER,
  SHOW_SCHOOLS_LAYER,
  SHOW_AA_LAYER,
  SHOW_SMOKE_CONTROL_ZONES,
} from '../actions/types';

// Type Imports
import { ShowAQMALayer } from '../utils/interface';

// Type Safety
interface showAQMALayer {
  type:
  | typeof CHANGE_OVERLAY_LAYER
  | typeof CHANGE_OVERLAY_OPACITY
  | typeof SET_MAPPAIR_POINT_MODE_OFF
  | typeof SET_MAPPAIR_POINT_MODE_ON
  | typeof SHOW_AQMA_LAYER
  | typeof SHOW_OVERLAY_LAYER
  | typeof SHOW_SATELLITE_LAYER
  | typeof SHOW_SCHOOLS_LAYER
  | typeof SHOW_SMOKE_CONTROL_ZONES
  | typeof SHOW_AA_LAYER
  | typeof SET_ANNUAL_AVERAGE;
  payload: string | number | any;
}

// State
const initialState: ShowAQMALayer = {
  opacity: 80,
  overlay: '',
  mappairPointModeOn: false,
  showAQMALayer: false,
  showSchoolsLayer: false,
  showSmokeControlZones: false,
  showOverlayLayer: false,
  showSatelliteLayer: false,
  showAALayer: false,
  annualAverage: null,
};

// Reducer
export default function (state = initialState, action: showAQMALayer) {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_OVERLAY_OPACITY:
      return {
        ...state,
        opacity: payload,
      };

    case CHANGE_OVERLAY_LAYER:
      return {
        ...state,
        overlay: payload,
      };

    case SET_MAPPAIR_POINT_MODE_ON:
      return {
        ...state,
        mappairPointModeOn: true,
      };

    case SET_MAPPAIR_POINT_MODE_OFF:
      return {
        ...state,
        mappairPointModeOn: false,
      };

    case SHOW_AQMA_LAYER:
      return {
        ...state,
        showAQMALayer: !state.showAQMALayer,
      };

    case SHOW_SCHOOLS_LAYER:
      return {
        ...state,
        showSchoolsLayer: !state.showSchoolsLayer,
      };

    case SHOW_SMOKE_CONTROL_ZONES:
      return {
        ...state,
        showSmokeControlZones: !state.showSmokeControlZones,
      };

    case SHOW_OVERLAY_LAYER:
      return {
        ...state,
        showOverlayLayer: payload ? payload : !state.showOverlayLayer,
      };

    case SHOW_SATELLITE_LAYER:
      return {
        ...state,
        showSatelliteLayer: !state.showSatelliteLayer,
      };

    case SHOW_AA_LAYER:
      return {
        ...state,
        showAALayer: payload ? payload : !state.showAALayer,
      };

    case SET_ANNUAL_AVERAGE:
      return {
        ...state,
        annualAverage: payload,
      };

    default:
      return state;
  }
}
