// Package Imports
import React from 'react';
import { useTranslation } from 'react-i18next';

// Const Imports
import {
  mapboxCRLink,
  maxarCRLink,
  mapboxImproveLink,
  naeiCRLink,
  osmCRLink,
  publicCRLink,
} from '../../utils/consts';

// Asset Imports
import mapboxLogo from '../../assets/images/mapbox-logo-white.png';

// Type Imports
import {
  Overlay as OverlayType,
  ShowAQMALayer,
  UserInfo,
} from '../../utils/interface';

// Component Interfaces
interface LocationLincensingProps {
  asideOn: boolean;
  curOverlayName: string;
  mappairOptions: ShowAQMALayer;
  overlays: OverlayType[];
  userInfo: UserInfo;
}

const LocationLicensing = ({
  asideOn,
  curOverlayName,
  mappairOptions,
  overlays,
  userInfo,
}: LocationLincensingProps) => {
  const { t: translate } = useTranslation();

  return (
    <>
      {!userInfo.isPublic ? (
        <div className={`map-licensing-container ${asideOn ? '' : 'expand'}`}>
          <div className="map-licensing">
            {osmCRLink.precedingContent}{' '}
            <a href={osmCRLink.url} target={osmCRLink.target}>
              {translate('osmCRLink')}
            </a>
            {mappairOptions.showSatelliteLayer ? (
              <>
                , {mapboxCRLink.precedingContent}{' '}
                <a href={mapboxCRLink.url} target={mapboxCRLink.target}>
                  {translate('mapboxCRLink')}
                </a>
                ,{maxarCRLink.precedingContent}{' '}
                <a href={maxarCRLink.url} target={maxarCRLink.target}>
                  {translate('mapboxCRLink')}
                </a>
                ,{mapboxImproveLink.precedingContent}{' '}
                <a
                  href={mapboxImproveLink.url}
                  target={mapboxImproveLink.target}
                >
                  {translate('mapboxImproveLink')}
                </a>
              </>
            ) : (
              <></>
            )}
            {overlays.filter((ol) => ol.name === curOverlayName).length &&
            overlays.filter((ol) => ol.name === curOverlayName)[0]
              .isSAOverlay ? (
              <>
                , {naeiCRLink.precedingContent}{' '}
                <a href={naeiCRLink.url} target={naeiCRLink.target}>
                  {translate('naeiCRLink')}
                </a>
              </>
            ) : (
              <></>
            )}
            {mappairOptions.showOverlayLayer ? (
              <>
                ,{' '}
                <a href={publicCRLink.url} target={publicCRLink.target}>
                  {publicCRLink.content}
                </a>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      {mappairOptions.showSatelliteLayer ? (
        <img
          className={`map-licensing-mark ${asideOn ? '' : 'aside-collapsed'}`}
          src={mapboxLogo}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default LocationLicensing;
